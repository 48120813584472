import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from '../container/SubscriptionContainer.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import lodashGet from 'lodash/get';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { characterToUpperCase, getCpDetailFromConfig } from '../../../utilities/CommonUtil';
import { getCurrentUrl } from '../../../utilities/WindowUtil';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { pageLoadEvent, seeMoreAlacarteEvent, seePlansClacarteClickedEvent} from '@airtel-tv/analytics/FunctionalEvents';
import { LocationUtil } from '@airtel-tv/utils';
import RoutingUtil from '../../../utilities/RoutingUtil';
import LazyIconFatory, { ICON_MAP } from './iconBuilder/iconFactory';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { ARIA_LABEL } from '@airtel-tv/constants/GlobalConst';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics/AnalyticsLinkComponent';

class AlaCartePartnerListing extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isCursorOnRail: false,
            indexOfRail: 0
        };
        this.pathName = window.location.pathname;
        this.carouselElementRef = React.createRef();
    }

    next=(listLength) => {
        let { indexOfRail } = this.state;
        if (indexOfRail >= (listLength - 4)) return;
        if (this.carouselElementRef.current) {
            let images = this.carouselElementRef.current.children;
            if (images && images.length > 0) {
                this.carouselElementRef.current.scrollLeft += (images[0].offsetWidth + 8);
                this.setState({ indexOfRail: ++indexOfRail});
            }
        }
    }

    previous =() => {
        let { indexOfRail } = this.state;
        if (indexOfRail <= 0) return;
        if (this.carouselElementRef.current) {
            const images = this.carouselElementRef.current.children;
            if (images && images.length > 0) {
                this.carouselElementRef.current.scrollLeft -= (images[0].offsetWidth + 10);
                this.setState({ indexOfRail: --indexOfRail})
            }
        }
    }

    getSourceName=() => {
        const { location } = this.props;
        const pathname = lodashGet(location, 'pathname', '');
        let source;
        if (pathname === '/subscribe/my-account') {
            source = ANALYTICS_ASSETS.MY_PLANS_PAGE;
        }
        else {
            source = ANALYTICS_ASSETS.EXPLORE_PLANS_PAGE;
        }
        return source;
    }

    componentDidMount() {
        const { isAuthenticated, history} = this.props;
        if (!isAuthenticated) {
            history.push({
                pathname: RoutingUtil.getHomePage(),
                search: LocationUtil.handleMobilityRedirection(),
            });
            return;
        }

        this.pageinitEvent();
        if (this.carouselElementRef && this.carouselElementRef.current) {
            const images = this.carouselElementRef.current.children;
            this.carouselElementRef.current.addEventListener('scroll', (e) => {
                let { indexOfRail } = this.state;
                indexOfRail = Math.ceil(this.carouselElementRef.current.scrollLeft / (images[0].offsetWidth + 8))
                this.setState({ indexOfRail })
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { isAuthenticated, history } = this.props;
        if (prevProps.isAuthenticated !== isAuthenticated) {
            history.push({
                pathname: RoutingUtil.getHomePage(),
                search: LocationUtil.handleMobilityRedirection(),
            });
        }
    }

    pageinitEvent() {
        const { circle, sid,userConfig,uid } = this.props;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            customer_type:userConfig.customerType,
            active_tab: ANALYTICS_ASSETS.ALA_CARTE_LISTING_PAGE,
            source: this.getSourceName(),
            ingressIntent: this.getSourceName(),
            pageName: ANALYTICS_ASSETS.ALA_CARTE_LISTING_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            uid
        };
        pageLoadEvent(pageLoadAnalyticsMeta, true);
    }

    redirect = (e) => {
        e.preventDefault();
        const {
            history, sid, circle, userConfig,uid
        } = this.props;
        history.push({
            pathname: ROUTE_PATHS.PARTNER_LISTING,
            search: lodashGet(history,'location.search',''),
        });


        const offerSelectionAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            customer_type:userConfig.customerType,
            active_tab: ANALYTICS_ASSETS.SUBSCRIBED_PACKS,
            source: this.getSourceName(),
            ingressIntent: this.getSourceName(),
            pageName: ANALYTICS_ASSETS.PLANS_AND_OFFERS,
            service: ANALYTICS_ASSETS.AIRTELTV,
            uid
        };
        seeMoreAlacarteEvent(offerSelectionAnalyticsMeta);
    }

    onSeePlansButtonClick = (cpId) => {
        const {
            history, browsePlans, sid, circle,userConfig,uid, navigate
        } = this.props;
        const cpData = browsePlans && browsePlans.filter(cp => cp.cpId.includes(cpId))[0];
        const id = lodashGet(cpData, 'id', '');


        const offerSelectionAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            offerId: id,
            offerTitle: cpId,
            customer_type:userConfig.customerType,
            active_tab: ANALYTICS_ASSETS.SUBSCRIBED_PACKS,
            source: this.getSourceName(),
            ingressIntent: this.getSourceName(),
            pageName: ANALYTICS_ASSETS.PLANS_AND_OFFERS,
            service: ANALYTICS_ASSETS.AIRTELTV,
            uid
        };
        seePlansClacarteClickedEvent(offerSelectionAnalyticsMeta);

        navigate(`${generatePath(ROUTE_PATHS.CP_PLAN_DETAIL_PAGE,{cpId})}?${lodashGet(history,'location.search','')}`,
            {
                state: {
                    origin: this.getSourceName(),
                    cpId,
                },
            }
        );
    };

    goBack = () => {
        const { history } = this.props;
        history.go(-1);
    }

    render() {
        const { allPlans, cpDetails, cpImages,cpDetailsById } = this.props;
        const { indexOfRail } = this.state;
        const plansList = allPlans.browsePlans || [];
        const { GO_TO_PREV_PAGE = "Go to previous page", SCROLL_RAIL_LEFT = "Scroll rail left", SCROLL_RAIL_RIGHT = 'Sroll rail right' } = ARIA_LABEL;

        return (
            <section className={this.pathName !== ROUTE_PATHS.PARTNER_LISTING ? 'alacarte-channel-section' : 'alacarte-channel-section alacarte-grid-view'}>
                <div className="see-all-wrapper">
                    <AnalyticsButtonComponent
                        className="no-subscribe-back"
                        onClick={this.goBack}
                        aria-label={GO_TO_PREV_PAGE}
                    >
                        <LazyIconFatory iconName = {ICON_MAP.CARET_DOWN} />
                    </AnalyticsButtonComponent>
                    <p className="alacarte-heading">{this.pathName !== ROUTE_PATHS.PARTNER_LISTING ? 'CHANNELS' : 'Channels'}</p>
                    {
                        this.pathName !== ROUTE_PATHS.PARTNER_LISTING
                                && (
                                    <AnalyticsLinkComponent
                                        className="see-all-button"
                                        onClick={this.redirect}
                                    >
                                        See all
                                    </AnalyticsLinkComponent>
                                )
                    }
                </div>

                {
                    this.pathName !== ROUTE_PATHS.PARTNER_LISTING
                        && <p className="alacarte-title">Buy Just the ones you want</p>
                }


                <div
                    className="alacarte-rail-holder"
                    onMouseOver={() => this.setState({ isCursorOnRail: true })}
                    onMouseLeave={() => this.setState({ isCursorOnRail: false })}
                    
                >

                    <AnalyticsButtonComponent
                        className="slide-btn left"
                        onClick={this.previous}
                        aria-label={SCROLL_RAIL_LEFT}
                    >
                        { indexOfRail > 0 && <div className="chev-background">
                            <ThumborImage
                                src="/static/left-chev.svg"
                            />
                        </div> }
                    </AnalyticsButtonComponent>

                    <div
                        ref={this.carouselElementRef}
                        className={this.pathName !== ROUTE_PATHS.PARTNER_LISTING ? 'rail-slider' : 'grid-slider'}
                    >
                        {
                            plansList && plansList.length > 0 && plansList.map((partner, index) => {
                                let lowestPlan = partner.plans.reduce((prev, curr) => (prev.price < curr.price ? prev : curr));
                                lowestPlan = `Starting @ \u20B9${lowestPlan.price}`;
                                if (partner.meta.offerType !== 'ALACARTE') {
                                    return null;
                                }
                                const cpDetail = cpDetailsById[partner.cpId] || {};
                                const partnerContentImages = cpImages[partner.cpId];

                                return (
                                    <div
                                        key={`${partner.id}${index}`}
                                        className="alacarte-card"
                                        onClick={() => this.onSeePlansButtonClick(partner.cpId)}
                                    >
                                        <ThumborImage
                                            className="alacarte-channel-img"
                                            src={partnerContentImages || '/static/alacarte.png'}
                                        />
                                        <div className="alacarte-overlay">
                                            <div className="logo-overlay-container">
                                                <ThumborImage
                                                    src={cpDetail?.roundLogoUrl}
                                                    className="alacarte-logo"
                                                />
                                                <p className="channel-name">{characterToUpperCase(partner.title)}</p>
                                                <p className="alacarte-price">{lowestPlan}</p>
                                            </div>
                                            <AnalyticsButtonComponent
                                                className="see-alacarte-plan"
                                                aria-label="See plans"
                                            >
                                                See Plans
                                            </AnalyticsButtonComponent>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>

                    <AnalyticsButtonComponent
                        className="slide-btn right"
                        onClick={() => this.next(plansList.length)}
                        aria-label={SCROLL_RAIL_RIGHT}
                    >
                        { indexOfRail < ( plansList.length - 4) && <div className="chev-background">
                            <ThumborImage
                                src="/static/left-chev.svg"
                            />
                        </div>}

                    </AnalyticsButtonComponent> 

                </div>
            </section>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        appConfig,
        planConfig,
        userConfig,
        authConfig: { isAuthenticated },
    } = state;
    const cpDetails = lodashGet(appConfig, 'cpDetails', {});
    const browsePlans = lodashGet(planConfig, 'browsePlans', []);
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const uid = lodashGet(userConfig, 'userInfo.uid', '');
    const cpImages = lodashGet(appConfig, 'CPIMAGES', {});
    const cpDetailsById= lodashGet(appConfig, 'cpDetailsById', {});
    return {
        cpDetails,
        userConfig,
        browsePlans,
        sid,
        circle,
        allPlans: planConfig,
        cpImages,
        uid,
        isAuthenticated,
        cpDetailsById
    };
};
AlaCartePartnerListing.propTypes = {
    channelpartner: PropTypes.string,
};
export default connect(mapStateToProps, null)(withDeviceUtil(withRouter(withStyles(styles)(AlaCartePartnerListing))));
