import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from '../container/SubscriptionContainer.scss';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { getCurrentUrl } from '../../../utilities/WindowUtil';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { pageLoadEvent } from '@airtel-tv/analytics/FunctionalEvents';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import RoutingUtil from '../../../utilities/RoutingUtil';
import LazyIconFatory, { ICON_MAP } from './iconBuilder/iconFactory';

class NoPlansAndOffers extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    pageinitEvent(){
        const {circle,sid,userConfig,uid} = this.props;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            customer_type:userConfig.customerType,
            active_tab: ANALYTICS_ASSETS.NO_PLANS_AND_OFFERS_PAGE,
            source: ANALYTICS_ASSETS.NO_PLANS_AND_OFFERS_PAGE,
            ingressIntent: ANALYTICS_ASSETS.NO_PLANS_AND_OFFERS_PAGE,
            pageName: ANALYTICS_ASSETS.NO_PLANS_AND_OFFERS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            uid
        };
        pageLoadEvent(pageLoadAnalyticsMeta, true);
    }

    componentDidMount(){
        this.pageinitEvent()
    }
    goBack = () => {
        const { history } = this.props;
        history.go(-1);
    }

    goToHome = () => {
        const { history } = this.props;
        history.push({
            pathname: RoutingUtil.getHomePage(),
            search: lodashGet(history,'location.search',''),
        });
    }

    render() {
        
        return (
            <div className="no-subscription-wrapper yay-page-bg">
                <button
                    className="no-subscribe-back"
                    onClick={this.goBack}
                >
                   <LazyIconFatory iconName = {ICON_MAP.CARET_DOWN} />
                </button>
                <p className="heading">Plans & Offers</p>
                <div className="page-icon-holder">
                    <LazyIconFatory iconName = {ICON_MAP.DAB_ICON} />
                    <p className="information-yay">Yay!</p>
                    <p className="description">
                    You’re Already on
                    </p>
                    <ThumborImage
                        className="yay-logo"
                        src="/static/xstream-premium.svg"
                    />
                    <button
                        onClick={this.goToHome}
                        className="no-subscription-explore-btn common-white-btn"
                    >
                    Back to Home
                    </button>
                </div>


            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        planConfig,
        userConfig
    } = state;
    const uid = lodashGet(userConfig, 'userInfo.uid', '');
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    return {
        sid,
        circle,
        userConfig,
        uid
    };
};

export default connect(mapStateToProps, {
})(withDeviceUtil(withRouter(withStyles(styles)(NoPlansAndOffers))));
