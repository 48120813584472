/* eslint-disable react/button-has-type */
import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import { POPULAR } from '../../../constants/HTMLConst';
import LanguageProvider from '../../../providers/LanguageProvider';

class MyButton extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            style: 'active',
            count: 0,

        };
        this.LANGUAGE = LanguageProvider();
    }

    handleClick(planId, bestValue) {
        const { id, selectButton, autoPayEnabled } = this.props;
        selectButton({
            arg: planId,
            planId,
            bestValue,
            autoPayEnabled,
        });
    }

    render() {
        const {
            // eslint-disable-next-line no-unused-vars
            // eslint-disable-next-line react/prop-types
            discount, validityUnit, total, id, planId, activeId, bestValue, selectButton,
            isActive,
        } = this.props;
        const btnclass = 'price-card';
        const btnActiveclass = 'price-card active';
        const borderActive = isActive ? 'price-card-border active' : 'price-card-border';
        const classactive = isActive ? btnActiveclass : btnclass;
        const { count } = this.state;
        if (bestValue && count < 1) {
            selectButton(id, planId, bestValue);
            this.setState({
                count: 1,
            });
        }
        const POPULAR_LABEL = this.LANGUAGE.POPULAR_LABEL ? this.LANGUAGE.POPULAR_LABEL : 'Popular';
        return (
            <div className={borderActive}>
                <button
                    className={classactive}
                    id={id}
                    onClick={() => this.handleClick(planId, bestValue)}
                >
                    {bestValue && (
                        <div className="discount-tag">
                            {POPULAR_LABEL}
                        </div>
                    )}
                    <div className="selector">
                        <svg
                            className="premium-tick"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0Z"
                                fill="white"
                            />
                            <path
                                d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0Z"
                                fill="url(#paint0_linear_2009_21999)"
                            />
                            <path
                                d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0Z"
                                fill="#E8EAED"
                            />
                            <path
                                d="M12.4733 4.80657C12.4114 4.74409 12.3376 4.69449 12.2564 4.66065C12.1752 4.6268 12.088 4.60938 12 4.60938C11.912 4.60938 11.8249 4.6268 11.7436 4.66065C11.6624 4.69449 11.5886 4.74409 11.5267 4.80657L6.56001 9.77991L4.47334 7.68657C4.40899 7.62441 4.33303 7.57554 4.2498 7.54274C4.16656 7.50993 4.07768 7.49385 3.98822 7.49539C3.89877 7.49694 3.8105 7.51609 3.72844 7.55176C3.64639 7.58742 3.57217 7.63889 3.51001 7.70324C3.44785 7.76759 3.39897 7.84355 3.36617 7.92679C3.33337 8.01002 3.31728 8.09891 3.31883 8.18836C3.32038 8.27781 3.33953 8.36609 3.37519 8.44814C3.41085 8.53019 3.46233 8.60441 3.52667 8.66657L6.08667 11.2266C6.14865 11.2891 6.22238 11.3387 6.30362 11.3725C6.38486 11.4063 6.472 11.4238 6.56001 11.4238C6.64802 11.4238 6.73515 11.4063 6.81639 11.3725C6.89763 11.3387 6.97137 11.2891 7.03334 11.2266L12.4733 5.78657C12.541 5.72415 12.595 5.64838 12.632 5.56404C12.6689 5.47971 12.688 5.38864 12.688 5.29657C12.688 5.20451 12.6689 5.11344 12.632 5.0291C12.595 4.94477 12.541 4.869 12.4733 4.80657Z"
                                fill="#0C0F12"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear_2009_21999"
                                    x1="8"
                                    y1="0"
                                    x2="11.9529"
                                    y2="11.5765"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stopColor="#4BCA9C" />
                                    <stop
                                        offset="1"
                                        stopColor="#3EAE93"
                                    />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <p
                        className="duration"
                        title={validityUnit}
                    >
                        {validityUnit}
                    </p>
                    <p className="price">
                        <span className="rupee-symbol">₹</span>
                        {total}


                    </p>
                </button>

            </div>
        );
    }
}
MyButton.propTypes = {
    // origin: PropTypes.string.isRequired,
    discount: PropTypes.string,
    total: PropTypes.string,
    validityUnit: PropTypes.string

    , // eslint-disable-line react/forbid-prop-types
};
export default MyButton;
