import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { PropTypes } from 'prop-types';
import RoutingUtil from '../../../utilities/RoutingUtil';
import SubsciptionPageContainer from '../component/SubscriptionPageContainer';
import PlanDetailComponent from '../component/PlanDetailComponent';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './SubscriptionContainer.scss';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { SUBSCRIPTION_SOURCE } from '../../../constants/EventsConst';
import {
    pageLoadEvent,
} from '@airtel-tv/analytics/FunctionalEvents';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { getCurrentUrl } from '../../../utilities/WindowUtil';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { LocationUtil } from '@airtel-tv/utils';
import SubscriptionLoaderComponent from '../../shared/components/loader/SubscriptionLoaderComponent';
import { filterSubscribedPlans, getXStreamDetail } from '../../../utilities/CommonUtil';
import SubscriptionUtil  from '@airtel-tv/utils/SubscriptionUtil';


class SubscriptionContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;
        this.state = {
            clicked: false,
            count: 0,
        };
    }

    componentDidMount() {
        const {
            isAuthenticated, history, sid, circle, browsePlans, location, userConfig, uid,
        } = this.props;
        if (!isAuthenticated) {
            history.push({
                pathname: RoutingUtil.getHomePage(),
                search: LocationUtil.handleMobilityRedirection(),
            });
        }
        let premiumCpId = browsePlans.find(browsePlan => browsePlan.meta && browsePlan.meta.offerType === 'PREMIUM');
        if (premiumCpId) {
            premiumCpId = premiumCpId.cpId;
        }
        const cpId = lodashGet(location, 'state.cpId', '');
        const cpIdFromQuery = browserStore.get(LOCAL_STORE_KEYS.CHANNEL_PARTNER_ID);
        let origin = lodashGet(location, 'state.origin', '');
        if (cpIdFromQuery && cpIdFromQuery !== premiumCpId) {
            origin = SUBSCRIPTION_SOURCE.CHANNEL_PARTNER_PAGE;
        }
        const xpData = browsePlans && browsePlans.filter(cp => cp.cpId === premiumCpId);
        const cpData = browsePlans && browsePlans.filter(cp => cp.cpId.includes(this.getChannelPartnerId(cpId, cpIdFromQuery)));
        const channelData = (origin === SUBSCRIPTION_SOURCE.PLAN_DETAIL_PAGE) || (origin === 'plans_and_offer_page') ? cpData : xpData;
        const planId = SubscriptionUtil.getBestValuePlanId(channelData);

        if (!origin) {
            origin = SUBSCRIPTION_SOURCE.HOME_PAGE;
        }
        this.redirectToMyaccount();
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: ANALYTICS_ASSETS.SUBSCRIBED_PACKS,
            source: origin,
            pre_selected_plan_id: planId,
            cp_name: channelData && channelData.length > 0 && channelData[0].cpId,
            ingressIntent: origin,
            pageName: ANALYTICS_ASSETS.SUBSCRIPTION_DETAILS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type: userConfig.customerType,
            uid,
        };
        pageLoadEvent(pageLoadAnalyticsMeta, true);
    }

    componentDidUpdate(prevProps) {
        const { isAuthenticated, history } = this.props;
        if (prevProps.isAuthenticated !== isAuthenticated) {
            history.push({
                pathname: RoutingUtil.getHomePage(),
                search: LocationUtil.handleMobilityRedirection(),
            });
        }
        this.redirectToMyaccount();
    }

    redirectToMyaccount = ()=>{
        const { history, subscribedPlans, browsePlans,location } = this.props;
        if (subscribedPlans && subscribedPlans.length) {
            if((lodashGet(location,'search', '').includes('from=trailerCta')) ){
                let premiumCpId = browsePlans.find(browsePlan => browsePlan.meta && browsePlan.meta.offerType === 'PREMIUM');
                if (premiumCpId) {
                    premiumCpId = premiumCpId.cpId;
                }
                const xppData = browsePlans && browsePlans.filter(cp => cp.cpId === premiumCpId);
                const filteredSubscribedPlans = filterSubscribedPlans(subscribedPlans);
                const isPremium = getXStreamDetail(filteredSubscribedPlans);
                if(!xppData.length || (isPremium && isPremium.renewPlanId && isPremium.renewPlanId !== 0 && isPremium.preReminder )){
                    history.replace({
                        pathname: 'my-account',
                    });
                }
            }
            
        }
    }
    handleClick=(arg) => {
        const { count } = this.state;

        this.setState({
            clicked: arg,
            count: count + 1,
        });
    }

    getChannelPartnerId = (cpId, cpIdFromQuery) => cpId || cpIdFromQuery || ''

    render() {
        const {
            browsePlans, ChannelPartnerImageData, location,
        } = this.props;
        let premiumCpId = browsePlans.find(browsePlan => browsePlan.meta && browsePlan.meta.offerType === 'PREMIUM');
        if (premiumCpId) {
            premiumCpId = premiumCpId.cpId;
        }
        const appId = browserStore.get(LOCAL_STORE_KEYS.APP_ID);
        const cpId = this.getChannelPartnerId(lodashGet(location, 'state.cpId', ''), browserStore.get(LOCAL_STORE_KEYS.CHANNEL_PARTNER_ID));
        const origin = lodashGet(location, 'state.origin', '');
        const contentUrl = lodashGet(location, 'state.contentUrl', '');
        let channelData = null;
        let channelPartner = null;
        if (!cpId || cpId !== premiumCpId) {
            channelData = browsePlans && browsePlans.filter(cp => cp.cpId === premiumCpId);
            channelPartner = premiumCpId;
        }
        else {
            channelData = browsePlans && browsePlans.filter(cp => cp.cpId.includes(cpId));
            channelPartner = cpId;
        }
        const cpData = browsePlans && browsePlans.filter(cp => cp?.cpId.includes(cpId));

        if (!channelPartner) {
            return <SubscriptionLoaderComponent />;
        }


        return (
            <div
                className={appId === 'MOBILITY' ? 'xstream-premium-wrapper xstream-wrapper-secondary mobility-bg-full' : 'xstream-premium-wrapper xstream-wrapper-secondary hide-all-ui'}
                key={channelPartner}
            >
                <SubsciptionPageContainer
                    origin={origin}
                    channelpartner={channelPartner}
                    cpId={cpId}
                    channelData={channelData}
                    handleClick={this.handleClick}
                    contentUrl={contentUrl}
                />
                <>
                    {(this.state.clicked === true || this.state.count === 1) && cpId && channelPartner === premiumCpId && cpData && cpData.length > 0
                        ? (
                            <PlanDetailComponent
                                key={channelPartner}
                                channelpartner={cpId}
                                movieName="TestMovie"
                                ChannelPartnerImageData={ChannelPartnerImageData}
                                browsePlans={browsePlans}
                                origin={SUBSCRIPTION_SOURCE.PLAN_DETAIL_PAGE}
                                contentUrl={contentUrl}
                            />
                        ) : ''}
                </>

            </div>
        );
    }
}
const mapStateToProps = (state, props) => {
    const {
        planConfig,
        authConfig: { isAuthenticated },
        appConfig,
        userConfig,
    } = state;
    let browsePlans = lodashGet(planConfig, 'browsePlans', []);
    browsePlans = browsePlans.filter( plan => plan?.meta?.payment?.mode !== "AIRTEL_PARTNER"); //filter out recharge plans
    const subscribedPlans = lodashGet(planConfig, 'subscribedPlans', []);
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const ChannelPartnerImageData = lodashGet(appConfig, 'xstreamPremiumData.partnersList', {});
    const uid = lodashGet(userConfig, 'userInfo.uid', '');

    return {
        browsePlans,
        subscribedPlans,
        isAuthenticated,
        sid,
        circle,
        ChannelPartnerImageData,
        userConfig,
        uid,
    };
};
SubscriptionContainer.propTypes = {
    channelpartner: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    browsePlans: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types,
    // eslint-disable-next-line react/forbid-prop-types
    ChannelPartnerImageData: PropTypes.array.isRequired,
};
export default connect(mapStateToProps, null)(withDeviceUtil(withRouter(withStyles(styles)(SubscriptionContainer))));
