import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { PropTypes } from 'prop-types';
import CPDetailComponent from './CPDetailComponent';
import SubscriptionHeader from './SubscriptionHeader';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';

class SubscriptionPageContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        let {
            // eslint-disable-next-line no-unused-vars
            contentUrl, origin, channelpartner, browsePlans, handleClick, channelData, xstreamPremiumData, redirectUrl,cpId
        } = this.props;
        return (
            <div
                className={channelpartner === 'XSTREAMPREMIUM' ? 'xstream-premium-wrapper' : 'xstream-premium-wrapper premium-gredient'}
                key={channelpartner}
            >
                <SubscriptionHeader
                    xstreamPremiumData={xstreamPremiumData}
                    channelpartner={channelpartner}
                    channelData={channelData}
                    origin={origin}
                />
                <CPDetailComponent
                    origin={origin}
                    redirectUrl={redirectUrl}
                    cpId = {cpId}
                    channelpartner={channelpartner}
                    browsePlans={browsePlans}
                    handleClick={handleClick}
                    contentUrl={contentUrl}
                    channelData={channelData}
                />
            </div>
        );
    }
}
const mapStateToProps = (state, props) => {
    const {
        appConfig,
        planConfig,
    } = state;
    let browsePlans = lodashGet(planConfig, 'browsePlans', []);
    browsePlans = browsePlans.filter( plan => plan?.meta?.payment?.mode !== "AIRTEL_PARTNER"); //filter out recharge plans
    const xstreamPremiumData = lodashGet(appConfig, 'subscriptionData.xstreamPremium', {});
    const redirectUrl = lodashGet(planConfig, 'redirectUrl', '');
    return {
        browsePlans,
        redirectUrl,
        xstreamPremiumData,
    };
};
SubscriptionPageContainer.propTypes = {
    origin: PropTypes.string.isRequired,
    redirectUrl: PropTypes.string.isRequired,
    channelpartner: PropTypes.string,
    channelData: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    browsePlans: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types,
    xstreamPremiumData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types


    // managePlanRequestDispatch: PropTypes.func.isRequired,
    // sharableContent: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    // planInitDispatch: PropTypes.func.isRequired,
    // history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    // isAuthenticated: PropTypes.bool.isRequired,
    // themeConfig: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    // triggerLoginDispatch: PropTypes.func.isRequired,
    // xstreamPremiumData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    // subscribedPlans: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    // deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};
export default connect(mapStateToProps, null)(withDeviceUtil(withRouter(SubscriptionPageContainer)));
