import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { PropTypes } from 'prop-types';
import RoutingUtil from '../../../utilities/RoutingUtil';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './SubscriptionContainer.scss';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import XmpChannelCardComponent from '../component/XmpChannelCardComponent';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { LocationUtil } from '@airtel-tv/utils';
import { getCurrentUrl } from '../../../utilities/WindowUtil';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { changeCpClicked, claimNowClickEvent, onCancelClickXmpPage, pageLoadEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { updateUserConfig } from '../../user/UserAction';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { characterToUpperCase } from '../../../utilities/CommonUtil';
import LanguageProvider from '../../../providers/LanguageProvider';
import env from '../../../config';

class XmpSingleClaimContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            planId: null,
            planPopupId: null,
            preferredPartnerOfferPlanRefId: null,
            selectedCp: '',
            selectedCpTitle: '',
            selectedCpLogo: '',
            channelproductId: null,
        };
        this.LANGUAGE = LanguageProvider();
    }

    pageinitEvent() {
        const { circle, sid ,userConfig,uid} = this.props;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            source: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            pageName: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            ingressIntent: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type:userConfig.customerType,
            uid
        };
        pageLoadEvent(pageLoadAnalyticsMeta, true);
    }

    componentDidMount() {
        const { updateUserConfig } = this.props;
        updateUserConfig();
        let preferredPartnerOfferPlanRefId = this.getPreferredPartnerOfferPlanRefId();
        const preferredPartnerPlans = this.getPreferredPartnerPlans(preferredPartnerOfferPlanRefId);

        const planId = lodashGet(preferredPartnerPlans, 'planId', null);
        const planPopupId = lodashGet(preferredPartnerPlans, 'planPopupId', null);
        preferredPartnerOfferPlanRefId = lodashGet(preferredPartnerPlans, 'refId', null);
        this.setState({
            planId,
            planPopupId,
            preferredPartnerOfferPlanRefId,
        });

        this.pageinitEvent();
    }

    getPreferredPartnerOfferPlanRefId = () => {
        let { preferredPartnerOfferPlanRefId } = this.state;
        if (!preferredPartnerOfferPlanRefId) {
            preferredPartnerOfferPlanRefId = this.props.preferredPartnerOfferPlanRefId;
        }
        if (!preferredPartnerOfferPlanRefId) {
            const { preferredPartnerPlans } = this.props;
            preferredPartnerOfferPlanRefId = preferredPartnerPlans[0].preferredPartnerPlans;
        }
        return preferredPartnerOfferPlanRefId;
    }


    getPreferredPartnerPlans = (refId) => {
        const { preferredPartnerPlans } = this.props;
        const preferredPartnerPlanSelected = preferredPartnerPlans.length > 1 ? preferredPartnerPlans.filter(item => item.refId === refId) : preferredPartnerPlans[0];

        return Array.isArray(preferredPartnerPlanSelected) && preferredPartnerPlanSelected.length > 0 ? preferredPartnerPlanSelected[0] : preferredPartnerPlanSelected;
    }

    offerSelection = (key, selectedCpLogo, selectedCp) => {
        const { submitSelectedPreferredPartners } = this.props;
        const preferredPartnerOfferPlanRefId = this.getPreferredPartnerOfferPlanRefId();
        const preferredPartnerPlanSelected = this.getPreferredPartnerPlans(preferredPartnerOfferPlanRefId);
        this.setState({
            channelproductId: key,
        });
        if (preferredPartnerPlanSelected) {
            const preferredPartnerPlanSelectedOffer = preferredPartnerPlanSelected.offers.filter(item => item.productId === key);
            submitSelectedPreferredPartners(preferredPartnerPlanSelectedOffer, selectedCp, selectedCpLogo);
        }
    }

    setNullValues = () => {
        const { submitSelectedPreferredPartners } = this.props;
        submitSelectedPreferredPartners(null);
        this.setState({
            channelproductId: null,
            channelproductIdMweb: null,
        });
        this.checked = '';
    }

    selectChannel = (key, logo, cp) => {
        const { channelproductId } = this.state;
        const { circle, sid,userConfig,uid } = this.props;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            source: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            ingressIntent: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            pageName: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type:userConfig.customerType,
            uid
        };
        claimNowClickEvent(pageLoadAnalyticsMeta, true);
        if (channelproductId !== key) {
            this.setState({
                selectedCp: cp,
                selectedCpLogo: logo
            });
            this.offerSelection(key, logo, cp);
        }
        else {
            this.setNullValues();
        }
    };

    selectChannelMweb = (id, logo, cp, title) => {
        const { selectedCp, selectedCpTitle,uid } = this.state;
        const { circle, sid ,userConfig} = this.props;
        const cpId = selectedCp !== cp ? cp : '';
        const cpTitle = selectedCpTitle !== title ? title : '';
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            source: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            ingressIntent: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            pageName: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            packageName:cp,
            customer_type:userConfig.customerType,
            uid
        };
        changeCpClicked(pageLoadAnalyticsMeta, true);
        this.setState({
            channelproductIdMweb: id,
            selectedCp: cpId,
            selectedCpTitle: cpTitle,
            selectedCpLogo: logo,
        });
        this.checked = id;
    }

    onSubscribe =() => {
        const { channelproductIdMweb, selectedCp, selectedCpLogo } = this.state;
        if (channelproductIdMweb) {
            this.offerSelection(channelproductIdMweb, selectedCpLogo, selectedCp);
        }
        else {
            this.setNullValues();
        }
    }

    redirectToHome = () => {
        const { location, circle, sid,userConfig,uid,history } = this.props;
        const contentUrl = lodashGet(location, 'state.contentUrl', '');
        const isMobilityOS = browserStore
        .get(LOCAL_STORE_KEYS.MOBILITYOS);
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            source: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            ingressIntent: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            pageName: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type:userConfig.customerType,
            uid
        };
        onCancelClickXmpPage(pageLoadAnalyticsMeta, true);
        const appId = LocationUtil.getKeyFromURL(window.location.search,'appId') || browserStore.get(LOCAL_STORE_KEYS.APP_ID) || env.appId;
        const isMobility = appId === 'MOBILITY';
    
        if(isMobility || isMobilityOS){
            LocationUtil.handleCloseAppstate();
            return;
        }
        if (contentUrl) {
            history.push({
                pathname: contentUrl,
                search: lodashGet(history,'location.search',''),
            });
        }
        else {
            history.push({
                pathname: RoutingUtil.getHomePage(),
                search: lodashGet(history,'location.search',''),
            });
        }
    }


    render() {
        const {
            preferredPartnerPlans, popups, showCloseButtonOnXmp
        } = this.props;
        const {XMPCLAIM : {SINGLE_CLAIM_TITLE = '', SINGLE_CLAIM_DESCRIPTION = '', SINGLE_CTA_TEXT = '', SINGLE_CLAIM_BUTTON_LABEL = ''}} = this.LANGUAGE;
        const preferredPartnerOfferPlanRefId = this.getPreferredPartnerOfferPlanRefId();
        const preferredPartnerPlanSelected = preferredPartnerPlans.length > 1 ? preferredPartnerPlans.filter(item => item.refId === preferredPartnerOfferPlanRefId) : preferredPartnerPlans;
        let preferredPartnerSelectedOfferArray = lodashGet(preferredPartnerPlanSelected, '[0].offers', []);
        const duration = lodashGet(preferredPartnerPlanSelected, '[0].duration', '');
        const appId = browserStore.get(LOCAL_STORE_KEYS.APP_ID);

        const preferredPartnerPlanSelectedTiles = preferredPartnerSelectedOfferArray.map((preferredPartnerChannelItem) => {
            const { preSelected = false } = preferredPartnerChannelItem;

            if (preSelected) {
                // preSelectedChannelList.push(cp);
            }

            return (
                <XmpChannelCardComponent
                    preferredPartnerChannelItem={preferredPartnerChannelItem}
                    selectChannel={this.selectChannel}
                    selectChannelMweb={this.selectChannelMweb}
                    checked={this.checked}
                    popups={popups}
                    buttonLabel={SINGLE_CLAIM_BUTTON_LABEL}
                />
            );
        });
        return (
            <div className="xmp-claim-wrapper">
                <div className="xmp-claim-container">
                    <div className="xmp-gredient-top-layer" />
                    <div className="top-header-wrapper-scroll">
                        { showCloseButtonOnXmp === true && <button
                            className="claim-cross"
                            onClick={() => {
                                this.redirectToHome();
                            }}
                        >
                            <ThumborImage
                                src="/static/close.svg"
                            />
                        </button> }
                        <p className="cong-text multi-channel-cong-text stars-aligner">{SINGLE_CLAIM_TITLE}</p>
                        <p className="free-channel-text">{SINGLE_CLAIM_DESCRIPTION}</p>
                        <p className="validity-area">
                            <span>{SINGLE_CTA_TEXT}</span>
                            <span>{`Validity: ${duration} days`}</span>
                        </p>
                    </div>
                    {console.log('appId', appId === 'MOBILITY' ? 'xmp-scroll no-footer-claim' : 'xmp-scroll')}
                    <div className={appId === 'MOBILITY' ? 'xmp-scroll no-footer-claim' : 'xmp-scroll'}>
                        {preferredPartnerPlanSelectedTiles}


                    </div>
                    <div className={appId === 'MOBILITY' ? 'claim-mobile no-footer-scroll' : 'claim-mobile'}>
                        <button
                            className={this.state.selectedCp ? 'claim-mobile-btn' : 'claim-mobile-btn button-disabled disable-button-universal'}
                            onClick={() => {
                                this.onSubscribe();
                            }}
                        >
                            Subscribe
                            {' '}
                            {characterToUpperCase(this.state.selectedCpTitle)}
                            {' '}
                        </button>
                    </div>

                </div>
            </div>

        );
    }
}
const mapStateToProps = (state, props) => {
    const {
        allPlans,
        authConfig: { isAuthenticated },
        appConfig,
        userConfig,
        popupConfig,
        planConfig,
    } = state;
    const browsePlans = lodashGet(allPlans, 'browsePlans', []);
    const ChannelPartnerImageData = lodashGet(appConfig, 'xstreamPremiumData.partnersList', {});
    const preferredPartnerPlans = lodashGet(userConfig, 'preferredPartnerPlans', []);
    const popups = lodashGet(popupConfig, 'allPopUps', null);
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const uid = lodashGet(userConfig, 'userInfo.uid', '');
    const showCloseButtonOnXmp = appConfig?.showCloseButtonOnXmp || true;

    return {
        browsePlans,
        isAuthenticated,
        ChannelPartnerImageData,
        preferredPartnerPlans,
        popups,
        sid,
        circle,
        userConfig,
        uid,
        showCloseButtonOnXmp
    };
};
XmpSingleClaimContainer.propTypes = {
    channelpartner: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    browsePlans: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types,
    // eslint-disable-next-line react/forbid-prop-types
    ChannelPartnerImageData: PropTypes.array.isRequired,
};
export default connect(mapStateToProps, { updateUserConfig })(withDeviceUtil(withRouter(withStyles(styles)(XmpSingleClaimContainer))));
