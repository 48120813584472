import React from 'react';

const YellowWarning = ({iconProps = {}}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="none"
            className='svg-default'
            {...iconProps}
        >
            <path
            d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
            stroke="#F2CB90"
            strokeWidth={1.5}
            strokeMiterlimit={10}
            />
            <path
            d="M12 7.5v5.25"
            stroke="#F2CB90"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <path
            d="M12 17.25A1.125 1.125 0 1 0 12 15a1.125 1.125 0 0 0 0 2.25Z"
            fill="#F2CB90"
            />
        </svg>
    )
}

export default YellowWarning;