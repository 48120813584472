import React from 'react';

const CaretDown = ({iconProps = {}}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={28}
            height={28}
            fill="none"
            className="svg-default"
            {...iconProps}
        >
            <path
                d="M17.5 22.75 8.75 14l8.75-8.75"
                stroke="#A3A7AE"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
)


}

export default CaretDown;