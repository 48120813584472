import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import withStyles from 'isomorphic-style-loader/withStyles';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import AuthenticationUtil from '@airtel-tv/utils/AuthenticationUtil';
import { LocationUtil } from '@airtel-tv/utils';
import {
    continueToPayError,
    failureRetryClickEvent, popUpVisibleEvent, subscriptionCancelClick, subscriptionSuccessOkClick,
} from '@airtel-tv/analytics/FunctionalEvents';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import SubscriptionUtil from '@airtel-tv/utils/SubscriptionUtil';
import RoutingUtil from '../../../utilities/RoutingUtil';
import { SUBSCRIPTION_SOURCE } from '../../../constants/EventsConst';
import { getCurrentUrl, getWindowLocation } from '../../../utilities/WindowUtil';
import { purchaseSubscriptionInit } from '../action/SubscriptionAction';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { getSid } from '../../../utilities/CommonUtil';
import { EVENT_NAME } from '../../../constants/SubscriptionConst';
import styles from '../container/SubscriptionContainer.scss';
import env from '../../../config';

class SubscriptionSuccessFailurePopUp extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isEventFired: false,
        };
        this.ISMOBILITY = browserStore.get(LOCAL_STORE_KEYS.MOBILITYOS);
    }

    pageinitEvent(cpName) {
        const pathname = lodashGet(this.props, 'location.pathname', '');
        const pageName = ROUTE_PATHS.SUBSCRIPTION_SUCCESS === pathname ? ANALYTICS_ASSETS.SUBSCRIPTION_ACTIVATED : ANALYTICS_ASSETS.SUBSCRIPTION_FAILURE;
        const {
            circle, sid, userConfig, uid,
        } = this.props;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: pageName,
            source: pageName,
            pageName,
            ingressIntent: pageName,
            cp_name: cpName,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type: userConfig.customerType,
            uid,
        };
        popUpVisibleEvent(pageLoadAnalyticsMeta, true);
    }


    componentDidMount() {
        const pathname = lodashGet(this.props, 'location.pathname', '');
        const status = pathname === ROUTE_PATHS.SUBSCRIPTION_SUCCESS ? 'success' : 'failure';
        const appId = LocationUtil.getKeyFromURL(window.location.search, 'appId') || AuthenticationUtil.getAppId() || env.appId;

        if (appId === 'MOBILITY') {
            // LocationUtil.handleAppstate(status);
            return;
        }

        const {
            location, history,
        } = this.props;
        const params = lodashGet(location, 'search', '');
        const arr1 = params && params.split('cpId=');
        const arr = arr1 && arr1.length && arr1[1] && arr1[1].split('&');
        const cpName = arr && arr.length && arr[0];
        if (!cpName || !this.props.isAuthenticated) {
            history.push({
                pathname: RoutingUtil.getHomePage(),
                search: lodashGet(history, 'location.search', ''),
            });
        }
        this.pageinitEvent(cpName);
    }


    retryEvent(sid, redirectPageUrl, isError, response) {
        const {
            circle, planId, customer_type, uid,
        } = this;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid: getSid(sid, response),
            planId,
            active_tab: ANALYTICS_ASSETS.SUBSCRIPTION_FAILURE,
            source: ANALYTICS_ASSETS.SUBSCRIPTION_FAILURE,
            ingressIntent: ANALYTICS_ASSETS.SUBSCRIPTION_FAILURE,
            pageName: ANALYTICS_ASSETS.SUBSCRIPTION_FAILURE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type,
            uid,
        };

        if (isError) {
            continueToPayError({
                ...pageLoadAnalyticsMeta,
                pageUrl: redirectPageUrl,
                response: {
                    pageLoadAnalyticsMeta,
                    response,
                },
            });
        }
        else {
            failureRetryClickEvent({
                ...pageLoadAnalyticsMeta,
                response: {
                    pageLoadAnalyticsMeta,
                    response,
                },
            });
        }
    }

    // onSubscribeClick = () => {
    //     const {
    //         purchaseSubscriptionInitDispatch,
    //         circle,
    //         userConfig,
    //         uid
    //     } = this.props;
    //     const subscriptionWindowLocation = getWindowLocation();
    //     const params = new URLSearchParams(window.location.search);
    //     const planId = params.get('planID');
    //     const cpId = params.get('cpId');
    //     const contentUrl = params.get('contentUrl');
    //     const skuId = params.get('skuId');
    //     const appId = LocationUtil.getKeyFromURL(window.location.search,'appId') || browserStore.get(LOCAL_STORE_KEYS.APP_ID) || env.appId;
    //     let successUrl = `${subscriptionWindowLocation.origin + RoutingUtil.getSubscriptionSuccessPage()}?planID=${planId}&cpId=${cpId}&contentUrl=${contentUrl}&appId=${appId}`;
    //     let failureUrl = `${subscriptionWindowLocation.origin + RoutingUtil.getSubscriptionFailurePage()}?planID=${planId}&cpId=${cpId}&contentUrl=${contentUrl}&skuId=${skuId}&journeyStart=${params.get('journeyStart')}&appId=${appId}`;

    //     if (appId === 'MOBILITY') {
    //         successUrl += '&viewType=webview';
    //         failureUrl += '&viewType=webview';
    //     }

    //     purchaseSubscriptionInitDispatch({
    //         successUrl,
    //         failureUrl,
    //         pendingUrl: failureUrl,
    //         unknownUrl: failureUrl,
    //         planId,
    //         skuId,
    //         eventMethod: this.retryEvent.bind({
    //             circle,
    //             planId,
    //             customer_type:userConfig.customerType,
    //             uid
    //         })
    //     });
    // }

    onSubscribeClick = () => {
        const {
            purchaseSubscriptionInitDispatch,
            circle,
            userConfig,
            uid,
        } = this.props;
        const params = new URLSearchParams(window.location.search);
        const planId = params.get('planID');
        const skuId = params.get('skuId');
        const autoPayEnabled = !!params.get('autoPayEnabled');
        const { successUrl, failureUrl } = SubscriptionUtil.getSubscriptionUrls({
            plan: {},
            successPage: RoutingUtil.getSubscriptionSuccessPage(),
            failurePage: RoutingUtil.getSubscriptionFailurePage(),
            isMobility: this.ISMOBILITY,
        });

        purchaseSubscriptionInitDispatch({
            successUrl,
            failureUrl,
            pendingUrl: failureUrl,
            unknownUrl: failureUrl,
            planId,
            skuId,
            disableAutoRenew: !autoPayEnabled,
            eventMethod: this.retryEvent.bind({
                circle,
                planId,
                customer_type: userConfig.customerType,
                uid,
            }),
        });
    };

    redirectFailure(contentUrl, cpId, paramObject) {
        const { history } = this.props;

        history.replace({
            // pathname: cpId!== 'XSTREAMPREMIUM' ? generatePath(ROUTE_PATHS.CP_PLAN_DETAIL_PAGE,{cpId}) : RoutingUtil.getSubscriptionPage(),
            pathname: paramObject.journeyStart,
            search: lodashGet(history, 'location.search', ''),
            state: {
                origin: SUBSCRIPTION_SOURCE.PLAN_DETAIL_PAGE,
                cpId,
                failurePop: true,
            },
        });
    }

    successButtonEvent(cpName) {
        const pageName = ROUTE_PATHS.SUBSCRIPTION_SUCCESS ? ANALYTICS_ASSETS.SUBSCRIPTION_ACTIVATED : ANALYTICS_ASSETS.SUBSCRIPTION_FAILURE;
        const {
            circle, sid, userConfig, uid,
        } = this.props;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: pageName,
            source: pageName,
            ingressIntent: pageName,
            pageName,
            cp_name: cpName,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type: userConfig.customerType,
            uid,
        };
        subscriptionSuccessOkClick(pageLoadAnalyticsMeta, true);
    }

    redirectToHome = (button) => {
        const {
            cpDetailsById, circle, sid, userConfig, uid, Hoichoi_Channel_Native_Link, HoichoiChannnelLanding,
        } = this.props;
        const pageName = ROUTE_PATHS.SUBSCRIPTION_SUCCESS ? ANALYTICS_ASSETS.SUBSCRIPTION_ACTIVATED : ANALYTICS_ASSETS.SUBSCRIPTION_FAILURE;
        const { history, location } = this.props;
        const params = lodashGet(location, 'search', '');
        const parseParams = LocationUtil.parse(params);
        const { contentUrl } = parseParams;
        const cp = parseParams.cpId;
        const title = lodashGet(cpDetailsById[cp], 'title', '');
        const urlParams = new URLSearchParams(params);
        const entries = urlParams.entries(); // returns an iterator of decoded [key,value] tuples
        const paramObject = this.paramsToObject(entries);

        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: pageName,
            source: pageName,
            ingressIntent: pageName,
            pageName,
            packageName: cp,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type: userConfig.customerType,
            uid,
        };
        subscriptionCancelClick(pageLoadAnalyticsMeta, true);

        if (button !== 'cancel') {
            this.successButtonEvent(title);
        }


        const appId = LocationUtil.getKeyFromURL(window.location.search, 'appId') || AuthenticationUtil.getAppId() || env.appId;
        const pathname = lodashGet(this.props, 'location.pathname', '');
        const status = pathname === ROUTE_PATHS.SUBSCRIPTION_SUCCESS ? 'SUCCESS' : 'FAILURE';
        if (appId !== 'MOBILITY' && !this.ISMOBILITY) {
            if (status === 'FAILURE') {
                this.redirectFailure(contentUrl, cp, paramObject);
                return;
            }
            if (contentUrl) {
                history.push({
                    pathname: contentUrl,
                    search: lodashGet(history, 'location.search', ''),
                });
            }
            else {
                if (cp === 'XSTREAMPREMIUM') {
                    const pathname = button === 'gotit' && HoichoiChannnelLanding ? HoichoiChannnelLanding : RoutingUtil.getHomePage();
                    const search = lodashGet(history, 'location.search', '');
                    const path = {
                        pathname,
                        search,
                    };
                    history.push(path);
                    return;
                }
                const pageLink = lodashGet(cpDetailsById[cp], 'pageLink', '');

                const pathname = RoutingUtil.getChannelPartnerPageUrl({
                    channelPartnerName: title,
                    pageLink,
                });
                history.push({
                    pathname,
                    search: lodashGet(history, 'location.search', ''),
                });
            }
        }
        else if (button === 'cancel' && status === 'FAILURE') {
            this.redirectFailure(contentUrl, cp, paramObject);
        }
        else if (cp === 'XSTREAMPREMIUM' && button === 'gotit' && Hoichoi_Channel_Native_Link) {
            LocationUtil.handleOpenIntent(Hoichoi_Channel_Native_Link);
        }
        else {
            LocationUtil.handleCloseAppstate();
        }
    };

    paramsToObject = (entries) => {
        const result = {};
        for (const [
            key,
            value,
        ] of entries) { // each 'entry' is a [key, value] tupple
            result[key] = value;
        }
        return result;
    };

    sendHandleAppState() {
        const { subscribedPlans, location } = this.props;
        const params = lodashGet(location, 'search', '');
        const parseParams = LocationUtil.parse(params);
        const { planID } = parseParams;
        const subscribedCpId = parseParams.cpId;
        if (subscribedPlans.length > 0 && !this.state.isEventFired) {
            const pathname = lodashGet(this.props, 'location.pathname', '');
            const status = pathname === ROUTE_PATHS.SUBSCRIPTION_SUCCESS ? 'success' : 'failure';
            const isEventFired = SubscriptionUtil.sendHandleAppState(subscribedPlans, subscribedCpId, status, planID, false, EVENT_NAME.SUBSCRIPTION_COMPLETE);
            this.setState({
                isEventFired,
            });
        }
    }

    render() {
        const {
            subscriptionOrigin, subscriptionPlanPrice, cpDetailsById, subscriptionPopUp,
        } = this.props;
        const pathname = lodashGet(this.props, 'location.pathname', '');
        const params = lodashGet(this.props, 'location.search', '');
        const successTitle = lodashGet(subscriptionPopUp, 'successTitle', '');
        const successSubtext = lodashGet(subscriptionPopUp, 'successSubtext', '');
        const successButtonText = lodashGet(subscriptionPopUp, 'successButtonText', '');
        const successSubtext_XSTREAMPREMIUM = lodashGet(subscriptionPopUp, 'successSubtext_XSTREAMPREMIUM', '');
        const parseParams = LocationUtil.parse(params);
        const cpName = parseParams.cpId;
        let cpIcon = lodashGet(cpDetailsById[cpName], 'logoUrl', '');
        cpIcon = cpIcon || lodashGet(cpDetailsById[cpName], 'logoUrl', '');
        const xpLogo = '/static/xstream-premium.svg';
        const logo = cpName && cpName !== 'XSTREAMPREMIUM' ? cpIcon : xpLogo;
        const text = subscriptionOrigin === SUBSCRIPTION_SOURCE.PLAN_DETAIL_PAGE ? `Payment of ₹${subscriptionPlanPrice} Failed!` : 'Payment failed!';
        this.sendHandleAppState();
        return (
            <div className="subscription-popup-wrapper">
                <div className={pathname === '/subscription/success' ? 'popup-body' : 'popup-body pay-fail'}>
                    {/* use class pay-fail along with popup-body class in case of payment fail popup */}
                    <button
                        className="popup-close"
                        onClick={() => this.redirectToHome('cancel')}
                    >
                        <ThumborImage
                            src="/static/xmp-cross-img.svg"
                        />
                    </button>
                    <ThumborImage
                        className={`${logo === xpLogo ? 'popup-logo' : 'cp-popup-logo'}`}
                        // src="/static/xstream-premium.svg"
                        // src="/static/error-premium.svg"
                        src={pathname === '/subscription/success' ? logo : '/static/error-premium.svg'}

                    />
                    {pathname === '/subscription/success'
                        ? <p className="title">{successTitle}</p>
                        : <p className="title">{text}</p>
                    }
                    {pathname === '/subscription/success'
                        ? (
                            <p className="information">
                                {cpName && cpName === 'XSTREAMPREMIUM' ? successSubtext_XSTREAMPREMIUM : successSubtext}
                            </p>
                        )
                        : <p className="information information-padding">Any amount deducted will be credited back to the payment source in 5 working days</p>
                    }
                    {
                        pathname === '/subscription/success'
                       && (
                           <button
                               className="action-btn"
                               onClick={() => this.redirectToHome('gotit')}
                           >
                               { successButtonText}
                           </button>
                       )
                    }
                    {
                        pathname === '/subscription/failure'
                       && (
                           <button
                               className="action-btn"
                               onClick={() => this.onSubscribeClick()}
                           >
                               Try Again
                           </button>
                       )
                    }

                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    const {
        planConfig,
        allPlans,
        appConfig,
        userConfig,
        authConfig: {
            isAuthenticated,
        },
    } = state;
    const subscribedPlans = lodashGet(planConfig, 'subscribedPlans', []);
    const subscriptionPlanPrice = lodashGet(allPlans, 'subscriptionPlanPrice', '');
    const subscriptionOrigin = lodashGet(allPlans, 'subscriptionOrigin', '');
    const cpDetailsById = lodashGet(appConfig, 'cpDetailsById', {});
    const subscriptionPopUp = lodashGet(appConfig, 'subscriptionPopUp', {});
    const uid = lodashGet(userConfig, 'userInfo.uid', '');
    const Hoichoi_Channel_Native_Link = lodashGet(appConfig, 'Hoichoi_Campaign.Hoichoi_Channel_Native_Link', '');
    const HoichoiChannnelLanding = lodashGet(appConfig, 'Hoichoi_Campaign.HoichoiChannnelLanding', '');
    return {
        subscribedPlans,
        subscriptionPlanPrice,
        subscriptionOrigin,
        cpDetailsById,
        subscriptionPopUp,
        userConfig,
        uid,
        Hoichoi_Channel_Native_Link,
        HoichoiChannnelLanding,
        isAuthenticated,
    };
};
SubscriptionSuccessFailurePopUp.propTypes = {
    subscribedPlans: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types,
};
export default connect(mapStateToProps, { purchaseSubscriptionInitDispatch: purchaseSubscriptionInit })(withDeviceUtil(withRouter(withStyles(styles)(SubscriptionSuccessFailurePopUp))));
