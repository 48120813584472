import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import { PropTypes } from 'prop-types';
import { generatePath } from 'react-router';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { connect } from 'react-redux';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import RoutingUtil from '../../../utilities/RoutingUtil';
import { SUBSCRIPTION_SOURCE } from '../../../constants/EventsConst';
import { showNonAirtelUserAction } from '../../user/UserAction';
//import { LocationUtil } from '@airtel-tv/utils';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { getCurrentUrl, getWindowLocation } from '../../../utilities/WindowUtil';
import { continueToPayError, rechargeNowClickEvent, seePlansClickEvent, subscribeClickEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { paginatedSubscriptionLayoutFetchAction } from '@airtel-feature/layout/actions/LayoutActions';
import { purchaseSubscriptionInit } from '../action/SubscriptionAction';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { characterToUpperCase, getSid } from '../../../utilities/CommonUtil';
//import env from '../../../config';
import SubscriptionUtil  from '@airtel-tv/utils/SubscriptionUtil';


class PlanDetailComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;
        this.state = {
            isCursorOnRail: false,
            indexOfRail: 0,
        };
        this.carouselElementRef = React.createRef();
        this.scrollViewRef = React.createRef();
    }

    componentDidMount() {
        this.init();
        if (this.carouselElementRef && this.carouselElementRef.current) {
            const images = this.carouselElementRef.current.children;
            this.carouselElementRef.current.addEventListener('scroll', (e) => {
                let { indexOfRail } = this.state;
                indexOfRail = Math.ceil(this.carouselElementRef.current.scrollLeft / (images[0].offsetWidth + 8));
                this.setState({ indexOfRail });
            });
        }
    }

    init = () => {
        const { layoutFetchActionDispatch, channelpartner } = this.props;
        window.scrollTo({
            top: this.scrollViewRef.current.scrollTop + 500,
            behavior: 'smooth',
        });
        layoutFetchActionDispatch({
            pageId: 'purchaseSubscriptionBanner',
            cpId: channelpartner,
        });
    }

    onSeePlansButtonClick = () => {
        const {
            history, channelpartner, contentUrl, sid, circle, location, browsePlans,
            userConfig, uid,
        } = this.props;
        const origin = lodashGet(location, 'state.origin', '');
        const cpData = browsePlans && browsePlans.filter(cp => cp.cpId.includes(channelpartner))[0];
        const id = lodashGet(cpData, 'id', '');
        const seeOtherPlansAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            offerId: id,
            offerTitle: channelpartner,
            active_tab: ANALYTICS_ASSETS.SUBSCRIBED_PACKS,
            source: origin,
            ingressIntent: origin,
            pageName: ANALYTICS_ASSETS.SUBSCRIPTION_DETAILS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type: userConfig.customerType,
            uid,
        };
        seePlansClickEvent(seeOtherPlansAnalyticsMeta, true);
        // const { history, channelpartner, contentUrl } = this.props;
        const isMobility = browserStore.get(LOCAL_STORE_KEYS.APP_ID);


        history.push({
            pathname: generatePath(ROUTE_PATHS.CP_PLAN_DETAIL_PAGE, { cpId: channelpartner }),
            search: lodashGet(history,'location.search',''),
            state: {
                origin: SUBSCRIPTION_SOURCE.PLAN_DETAIL_PAGE,
                cpId: channelpartner,
                contentUrl,
                isMobility: isMobility === 'MOBILITY',
            },
        });
        // window.location.reload();
    };

    openRechargeUrl = (_) => {
        const {
            userConfig, circle, sid, location, uid,
        } = this.props;
        const url = 'https://api.airtel.tv/v1/user/recharge/progress/xclusive?uid=e35H8PqTNeu7Yz0Mr0';
        const origin = lodashGet(location, 'state.origin', '');
        const offerSelectionAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            customer_type: userConfig.customerType,
            active_tab: ANALYTICS_ASSETS.PLANS_AND_OFFERS,
            source: origin,
            ingressIntent: origin,
            pageName: ANALYTICS_ASSETS.PLANS_AND_OFFERS,
            service: ANALYTICS_ASSETS.AIRTELTV,
            uid,
        };
        rechargeNowClickEvent(offerSelectionAnalyticsMeta);
        if (userConfig.customerType === 'NON_AIRTEL') {
            // showNonAirtelUserAction({cpId:channelpartner});
        }
        else {
            window.open(url, '_blank');
        }
    }

    continueToPayEvent(sid,redirectPageUrl,isError,response){
        const {
            circle,
            customer_type,
            uid,
            planId,
            cpData,
        } = this;
        const subscribeClickAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid:getSid(sid,response),
            planId,
            packageName: cpData && cpData.length && cpData[0].cpId,
            source: ANALYTICS_ASSETS.SUBSCRIPTION_DETAILS_PAGE,
            ingressIntent: ANALYTICS_ASSETS.SUBSCRIPTION_DETAILS_PAGE,
            pageName: ANALYTICS_ASSETS.SUBSCRIPTION_DETAILS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type,
            uid
        };
        
        if(isError){
            continueToPayError({
                ...subscribeClickAnalyticsMeta,
                pageUrl:redirectPageUrl,
                response:{
                    subscribeClickAnalyticsMeta,
                    response
                }
            })
        }else{
            subscribeClickEvent({...subscribeClickAnalyticsMeta,
                response:{
                    subscribeClickAnalyticsMeta,
                    response
            }});
        }
    }

    onSubscribeClick = () => {
        const {
            browsePlans,
            channelpartner,
            purchaseSubscriptionInitDispatch,
            contentUrl,
            userConfig,
            circle,
            uid,
        } = this.props;

        let cpData = (browsePlans || []).filter(plan => plan.cpId.includes(channelpartner) || plan.cpId.startsWith(channelpartner) || channelpartner.startsWith(plan.cpId));     
        cpData = cpData.length ? cpData[0]: {plans : []};
        const selectedPlan = cpData.plans[0];
        const {planId, sku} = selectedPlan || {};
        const skuId = (sku || {}).itunes
        const {successUrl, failureUrl } =  SubscriptionUtil.getSubscriptionUrls(
            {plan : {planId, cpId: channelpartner, contentUrl, skuId: (sku || {}).itunes},
            successPage:  RoutingUtil.getSubscriptionSuccessPage(),
            failurePage:  RoutingUtil.getSubscriptionFailurePage()
        });

        purchaseSubscriptionInitDispatch({
            successUrl,
            failureUrl,
            pendingUrl: failureUrl,
            unknownUrl: failureUrl,
            planId,
            skuId,
            eventMethod: this.continueToPayEvent.bind({
                planId,
                cpData,
                customer_type: userConfig.customerType,
                circle,
                uid,
            }),
        });
    }

    // onSubscribeClick = () => {
    //     const {
    //         browsePlans,
    //         channelpartner,
    //         purchaseSubscriptionInitDispatch,
    //         contentUrl,
    //         userConfig,
    //         circle,
    //         uid,
    //     } = this.props;
    //     const cpData = browsePlans && browsePlans.filter(cp => cp.cpId === channelpartner);
    //     const plans = cpData && cpData.length && cpData[0].plans;
    //     const planId = plans && plans.length && plans[0].planId;
    //     const skuId = plans && plans.length === 1 && plans[0].sku.itunes;
    //     const subscriptionWindowLocation = getWindowLocation();
    //     const appId = LocationUtil.getKeyFromURL(window.location.search,'appId') || browserStore.get(LOCAL_STORE_KEYS.APP_ID) || env.appId;


    //     let successUrl = `${subscriptionWindowLocation.origin + RoutingUtil.getSubscriptionSuccessPage()}?planID=${planId}&cpId=${channelpartner}&contentUrl=${contentUrl}&appId=${appId}`;
    //     let failureUrl = `${subscriptionWindowLocation.origin + RoutingUtil.getSubscriptionFailurePage()}?planID=${planId}&cpId=${channelpartner}&contentUrl=${contentUrl}&skuId=${skuId}&journeyStart=${subscriptionWindowLocation.pathname}&appId=${appId}`;

    //     if (appId === 'MOBILITY') {
    //         successUrl += '&viewType=webview';
    //         failureUrl += '&viewType=webview';
    //     }


    //     purchaseSubscriptionInitDispatch({
    //         successUrl,
    //         failureUrl,
    //         pendingUrl: failureUrl,
    //         unknownUrl: failureUrl,
    //         planId,
    //         skuId,
    //         eventMethod: this.continueToPayEvent.bind({
    //             planId,
    //             cpData,
    //             customer_type: userConfig.customerType,
    //             circle,
    //             uid,
    //         }),
    //     });
    // }

    next=(imagesLength) => {
        let { indexOfRail } = this.state;
        if (indexOfRail >= imagesLength - 3) {
            return;
        }
        if (this.carouselElementRef.current) {
            const images = this.carouselElementRef.current.children;
            if (images && images.length > 0) {
                this.carouselElementRef.current.scrollLeft += images[0].offsetWidth;
            }
            this.setState({ indexOfRail: ++indexOfRail });
        }
    }

    previous =() => {
        let { indexOfRail } = this.state;
        if (indexOfRail <= 0) {
            return;
        }
        if (this.carouselElementRef.current) {
            const images = this.carouselElementRef.current.children;
            if (images && images.length > 0) {
                this.carouselElementRef.current.scrollLeft -= images[0].offsetWidth;
            }
        }
        this.setState({ indexOfRail: --indexOfRail });
    }

    render() {
        const {
            browsePlans, location, purchaseSubscriptionBanner, cpDetailsById,
        } = this.props;
        const { channelpartner } = this.props;
        const { indexOfRail } = this.state;
        const movieName = location && location.state && location.state && location.state.playableTitle;
        const cpData = browsePlans && browsePlans.filter(cp => cp.cpId.includes(channelpartner));

        const plans = cpData && cpData.length && cpData[0].plans;
        const lowestPricePlanArray = Array.from(plans);
        const lowestPlan = `Starting @ \u20B9${lowestPricePlanArray.sort((a, b) => a.price - b.price)[0].price}`;

        const channelDetails = browsePlans.length > 0 ? browsePlans.filter(a => a.cpId.includes(channelpartner))[0] : {};
        const cpIcon = lodashGet(cpDetailsById[channelpartner], 'roundLogoUrl', '');
        let layoutData = lodashGet(purchaseSubscriptionBanner, `purchaseSubscriptionBanner:${channelpartner}.layoutPackages[0].contents[0].details.content`, []);
        const tagLine = lodashGet(cpDetailsById[channelpartner], 'tagLine', '');
        const images = [];
        layoutData = layoutData.forEach((banner) => {
            const img = banner.images.PORTRAIT_HD || banner.images.PORTRAIT;
            if (img) {
                images.push(img);
            }
        });
        return (
            <div className="more-plan-wrapper">

                <p
                    className="title"
                    ref={this.scrollViewRef}
                >
                    Buy
                    {' '}
                    {characterToUpperCase(channelDetails && channelDetails.title)}
                    {' '}
                    & watch
                    {' '}
                    {movieName}
                </p>

                <div className="view-plans-wrapper-base">
                    <div className="collection-wrapper">
                        <div className="left-sec">
                            <ThumborImage
                                className="icon"
                                src={cpIcon}
                            // src={channelDetails && channelDetails.partnerIcon}
                            />
                            <p className="text">
                                {tagLine}
                            </p>
                            <div className="see-plan-bottom-aligner">
                                {plans && plans.length && plans.length === 1 ? (
                                    <button
                                        className="plan-btn"
                                        onClick={this.onSubscribeClick}
                                    >
                                        Subscribe
                                    </button>
                                ) : (
                                    <button
                                        className="plan-btn"
                                        onClick={this.onSeePlansButtonClick}
                                    >
                                        See Plans
                                    </button>
                                )}
                                <p className="plan-pricing">{lowestPlan}</p>

                            </div>

                        </div>
                        <div
                            className="right-sec"
                            onMouseOver={() => this.setState({ isCursorOnRail: true })}
                            onMouseLeave={() => this.setState({ isCursorOnRail: false })}
                        >
                            <div className="for-mobile-gredient-left" />

                            <button
                                className="slider-btn"
                                onClick={this.previous}
                            >
                                { indexOfRail > 0 && (
                                    <ThumborImage
                                        src="/static/left-chev.svg"
                                    />
                                )}
                            </button>

                            <div
                                className="image-wrapper"
                                ref={this.carouselElementRef}
                            >
                                {
                                    images.map((img, index) => (
                                        <ThumborImage
                                            key={index}
                                            className="collection-images"
                                            src={img}
                                        />
                                    ))
                                }


                            </div>
                            <button
                                className="slider-btn rotate"
                                onClick={() => this.next(images.length)}
                            >
                                { indexOfRail < images.length - 3 && (
                                    <ThumborImage
                                        src="/static/left-chev.svg"
                                    />
                                ) }
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

PlanDetailComponent.propTypes = {
    origin: PropTypes.string.isRequired,
    channelpartner: PropTypes.string.isRequired,
    ChannelPartnerImageData: PropTypes.object,
    browsePlans: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    movieName: PropTypes.string,
    history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types

};

function mapStateToProps(state) {
    const {
        appConfig,
        userConfig,
        planConfig,
        layoutDetails,
    } = state;
    const redirectUrl = lodashGet(planConfig, 'redirectUrl', '');
    const planStartingPrice = lodashGet(appConfig, 'subscriptionData.planStartingPrice', {});
    const cpDetailsById = lodashGet(appConfig, 'cpDetailsById', {});
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const uid = lodashGet(userConfig, 'userInfo.uid', '');
    return {
        planStartingPrice,
        userConfig,
        redirectUrl,
        cpDetailsById,
        purchaseSubscriptionBanner: layoutDetails,
        sid,
        circle,
        uid,

    };
}

export default connect(mapStateToProps, {
    showNonAirtelUserAction,
    layoutFetchActionDispatch: paginatedSubscriptionLayoutFetchAction,
    purchaseSubscriptionInitDispatch: purchaseSubscriptionInit,
})(withDeviceUtil(withRouter(PlanDetailComponent)));
