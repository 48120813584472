import React, { lazy,Suspense } from 'react';
const AsyncCaretDown = lazy(() => import('./components/CaretDown'));
const AsyncHelpIcon = lazy(() => import('./components/HelpIcon'));
const AsyncYellowWarning = lazy(() => import('./components/YellowWarning'));
const AsyncDabIcon = lazy(() => import('./components/DabIcon'));


export const ICON_MAP  = {
    CARET_DOWN: 'CARET_DOWN',
    HELP_ICON: 'HELP_ICONs',
    YELLOW_WARNING: 'YELLOW_WARNING',
    DAB_ICON: 'DAB_ICON'
};

const LazyIconFatory = ({iconName, iconProps = {}}) => {
        switch(iconName) {
        case ICON_MAP.CARET_DOWN:
            return (
                <Suspense>
                    <AsyncCaretDown {...iconProps}/>
                </Suspense>
            )
        case ICON_MAP.HELP_ICON:
            return (
                <Suspense>
                    <AsyncHelpIcon {...iconProps}/>
                </Suspense>
            )
        case ICON_MAP.YELLOW_WARNING:
            return (
                <Suspense>
                    <AsyncYellowWarning {...iconProps}/>
                </Suspense>
            )
        case ICON_MAP.DAB_ICON:
            return (
                <Suspense>
                    <AsyncDabIcon {...iconProps}/>
                </Suspense>
            )
        default:
            return null
    }
}

export default LazyIconFatory