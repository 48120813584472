/* eslint-disable react/button-has-type */
import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { DateTime } from '@airtel-tv/utils';
import BranchioUtil from '../../../utilities/BranchioUtil';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';


class XmpPlanCardComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.deviceUtil = props.deviceUtil;
    }

    render() {
        const {
            popups, preferredPartnerPlans, key, preferredPartnerPlansOfferItemPlanId, gotoChannelSelectionPage, preferredPartnerPlansOfferItemPlanPopupId, preferredPartnerPlansOfferItemRefId, buttonLabel = 'Subscribe'
        } = this.props;

        const preferredPartnerPlanOpted = preferredPartnerPlans.filter(plan => plan.refId === preferredPartnerPlansOfferItemRefId);
        const yourOffersData = popups ? popups[`${preferredPartnerPlansOfferItemPlanPopupId}`] : null;
        const channelPartnerNames = lodashGet(preferredPartnerPlanOpted[0], 'offers', []).map(channelPartnerData => channelPartnerData.cp);

        let imageDataMap;
        if (yourOffersData) {
            imageDataMap = lodashGet(yourOffersData, 'cpData', []).filter(el => channelPartnerNames.indexOf(el.cpName) !== -1).map(cpLogoData => (
                <ThumborImage
                    className="multi-channel-logo"
                    src={cpLogoData.img_link}
                />
            ));
        }

        const footerTextValue = lodashGet(yourOffersData, 'footerText', null);
        let validTillText;
        if (footerTextValue) {
            validTillText = footerTextValue.split('%s');
        }

        const preferredPartnerPlanOffer = preferredPartnerPlans.filter(planRefId => planRefId.refId === preferredPartnerPlansOfferItemRefId);
        const offerValidityMilliseconds = lodashGet(preferredPartnerPlanOffer[0], 'offers[0].validity', null);
        const offerValidityDate = new DateTime(offerValidityMilliseconds).format('DDO-MMMM-YYYY');
        return (
            <div className="xmp-multi-claim-card-wrapper">
                <div className="xmp-multi-claim-top">
                    <div className="xmp-gredient-top-img" />
                    <ThumborImage
                        // src="/static/show_banner_image.svg"

                        // src="/static/show_banner_image_mobile.svg"
                        src={this.deviceUtil.isMobile() ? '/static/show_banner_image_mobile.svg' : '/static/show_banner_image.svg'}
                        className="xmp-background"
                    />

                    <div className="xmp-multi-info-area">
                        <p className="multi-text">1 free channel with Airtel recharge</p>
                        <div className="multi-logo-area">
                            {imageDataMap}
                        </div>
                    </div>
                </div>
                <div className="xmp-multi-claim-bottom">
                    <p className="xmp-multi-validity">{yourOffersData ? `${validTillText[0]} ${offerValidityDate}` : null}</p>
                    <button
                        className="xmp-multi-subscribe"
                        onClick={() => {
                            gotoChannelSelectionPage(preferredPartnerPlansOfferItemPlanId, preferredPartnerPlansOfferItemPlanPopupId, preferredPartnerPlansOfferItemRefId);
                            BranchioUtil.sendCustomDataToBranch(ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE, {
                                source: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
                                source_name: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
                                asset_name: ANALYTICS_ASSETS.UNLOCK,
                            });
                        }}
                    >
                        {buttonLabel}
                    </button>
                </div>
            </div>
        );
    }
}

export default withDeviceUtil(XmpPlanCardComponent);
