import React, { PureComponent } from 'react';

import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './SubscriptionContainer.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import PlansAndOffersContainer from './PlansAndOffersContainer';
import { LocationUtil } from '@airtel-tv/utils';
import { getCurrentUrl } from '../../../utilities/WindowUtil';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { pageLoadEvent } from '@airtel-tv/analytics/FunctionalEvents';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { ROUTE_PATHS_SETTINGS, WEBVIEW_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import LazyIconFatory , {ICON_MAP} from '../component/iconBuilder/iconFactory';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { ARIA_LABEL } from '@airtel-tv/constants/GlobalConst';

class NoPlansContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.os = browserStore.get(LOCAL_STORE_KEYS.OS);
    }


    componentDidMount() {
        const { circle, sid ,userConfig,uid} = this.props;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: ANALYTICS_ASSETS.ACTIVE_PLANS,
            source: ANALYTICS_ASSETS.ACTIVE_PLANS,
            pageName: ANALYTICS_ASSETS.ACTIVE_PLANS,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type:userConfig.customerType,
            uid
        };
        pageLoadEvent(pageLoadAnalyticsMeta, true);
    }

    navigateToHelp = (_) => {
        const appId = browserStore.get(LOCAL_STORE_KEYS.APP_ID);
        if (appId === 'MOBILITY') {
            window.open(WEBVIEW_PATHS.FEEDBACK, '_self');
        }
        else {
            this.props.history.push({
                pathname: ROUTE_PATHS_SETTINGS.SETTINGS_HELP_FAQ_SECTION,
                search: LocationUtil.handleMobilityRedirection(),
            });
        }
    }

    goBack = (_) => {
        const { history, origin } = this.props;
        const appId = browserStore.get(LOCAL_STORE_KEYS.APP_ID);
        if (appId === 'MOBILITY') {
            if (!origin) {
                
                LocationUtil.handleCloseAppstate();
                return;
            }
            
            history.go(-1);
            return;
        }
        history.go(-1);
    }

    render() {
        const { GO_TO_PREV_PAGE = "Go to previous page"} = ARIA_LABEL;
        return (
            <section className="active-plan-container">
                <div className="active-plan-cont-wrap">

                    <div className="active-action-wrapper">
                        <AnalyticsButtonComponent
                            className="active-back-button"
                            onClick={this.goBack}
                            aria-label={GO_TO_PREV_PAGE}
                            meta={{
                                asset_name: GO_TO_PREV_PAGE,
                                action: 'go_back',
                            }}
                        >
                           <LazyIconFatory iconName = {ICON_MAP.CARET_DOWN} />
                        </AnalyticsButtonComponent>
                        <p className="page-heading">My Plans</p>

                        { this.os && this.os.toLowerCase() === 'ios'
                            ? '' : (
                                <AnalyticsButtonComponent
                                    className="active-help-cta"
                                    onClick={this.navigateToHelp}
                                    aria-label="help"
                                >
                            Help
                                <LazyIconFatory iconName = {ICON_MAP.HELP_ICON} />
                                </AnalyticsButtonComponent>
                            )
                        }
                    </div>
                    <div className="noplans-notice-wrapper">
                        <LazyIconFatory iconName = {ICON_MAP.YELLOW_WARNING} />
                        <p className="noplans-notice">You are not a subscribed user</p>

                    </div>

                    
                </div>


                <PlansAndOffersContainer />

            </section>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        userConfig,
        userConfig : { userInfo },
        planConfig: { sid = '', userDetails}
    } = state;
    const { circle = '' } = userDetails || {};
    const { uid = ''} = userInfo || {};
    return {
        userConfig,
        sid,
        circle,
        uid
    };
};
NoPlansContainer.propTypes = {
    sid: PropTypes.string,
    circle: PropTypes.string,
    uid: PropTypes.string,
    userConfig: PropTypes.object
};
export default connect(mapStateToProps, null)(withDeviceUtil(withRouter(withStyles(styles)(NoPlansContainer))));
