import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import MyButton from './MyButton';
import {AnalyticsLinkComponent} from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { SUBSCRIPTION_SOURCE } from '../../../constants/EventsConst';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { getCurrentUrl, getWindowLocation } from '../../../utilities/WindowUtil';
import { showNonAirtelUserAction } from '../../user/UserAction';
import RoutingUtil from '../../../utilities/RoutingUtil';
import { getSubscriptionOrigin, getSubscriptionPlanValue, purchaseSubscriptionInit } from '../action/SubscriptionAction';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { continueToPayError, planSelectionEvent, seeOtherPlansClickEvent, subscribeClickEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { NOT_AVAILABLE_FOR_AIRTEL } from '../constant/SubscriptionConstant';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
//import { LocationUtil } from '@airtel-tv/utils';//import env from '../../../config';
import { getSid } from '@airtel-tv/utils/GlobalUtil';
import SubscriptionUtil  from '@airtel-tv/utils/SubscriptionUtil';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { LanguageProviderUtil } from '@airtel-tv/utils';

class CPDetailComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;
        this.state = {
            isClicked: false,
            selectedPlanId: '',
            id: '',
            autoPayEnabled: false,
        };
        this.scrollElement = React.createRef();
        this.LANGUAGE = LanguageProviderUtil.getLanguage();
    }

    componentDidMount() { 
        const {
            browsePlans, channelpartner
        } = this.props;
        const cpObject = browsePlans.length > 0 && browsePlans.filter(a => a.cpId.includes(channelpartner))[0];
        const plans = cpObject && cpObject.plans;
        if(plans.length) {
            const plan = plans.find((plan) => plan.bestValue);
            const { planId ,bestValue, autoPayEnabled } = plan || plans[0];
            planId ? this.selectButton({arg: planId, planId, bestValue, autoPayEnabled}) : undefined;
        }
    }

    continueToPayEvent(sid,redirectPageUrl,isError,response){
        const {cpObject,selectedPlanId,circle,customer_type,origin,uid} = this;
        const subscribeClickAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            packageName: cpObject && cpObject.cpId,
            sid:getSid(sid,response),
            customer_type,
            planId: selectedPlanId,
            source: origin || SUBSCRIPTION_SOURCE.HOME_PAGE,
            ingressIntent: origin || SUBSCRIPTION_SOURCE.HOME_PAGE,
            pageName: ANALYTICS_ASSETS.SUBSCRIPTION_DETAILS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            uid
        };
        
        if(isError){
            continueToPayError({
                ...subscribeClickAnalyticsMeta,
                pageUrl:redirectPageUrl,
                response:{response,subscribeClickAnalyticsMeta},
            })
        }else{
            subscribeClickEvent({...subscribeClickAnalyticsMeta,response:{response,subscribeClickAnalyticsMeta}});
        }
    }

    // onSubscribeClick = () => {
    //     const {
    //         channelpartner, contentUrl, userConfig, purchaseSubscriptionInitDispatch, browsePlans,
    //         getSubscriptionOrigin, getSubscriptionPlanValue, origin, showNonAirtelUserAction,
    //         uid, circle,history
    //     } = this.props;
    //     const { selectedPlanId } = this.state;
    //     const subscriptionWindowLocation = getWindowLocation();
    //     const cpObject = browsePlans.length > 0 && browsePlans.filter(a => a.cpId.includes(channelpartner))[0];
    //     const plans = cpObject && cpObject.plans;
    //     const selectedPlan = plans.filter(plan => plan.planId === selectedPlanId);
    //     const price = selectedPlan && selectedPlan.length && selectedPlan[0].price;
    //     const skuId = selectedPlan && selectedPlan.length && selectedPlan[0].sku.itunes;
    //     getSubscriptionPlanValue({ price });
    //     getSubscriptionOrigin({ origin });
    //     const isCPisPremium = browsePlans.find(browsePlan => browsePlan.cpId.startsWith(channelpartner) || channelpartner.startsWith(browsePlan.cpId));
    //     if (userConfig.customerType === 'NON_AIRTEL' && (isCPisPremium.meta && isCPisPremium.meta.offerType === 'PREMIUM' || NOT_AVAILABLE_FOR_AIRTEL.includes(cpObject.cpId))) {
    //         showNonAirtelUserAction({ cpId: channelpartner });
    //         return;
    //     }
    //     const appId = LocationUtil.getKeyFromURL(window.location.search,'appId') || browserStore.get(LOCAL_STORE_KEYS.APP_ID) || env.appId;
    //     let successUrl = `${subscriptionWindowLocation.origin + RoutingUtil.getSubscriptionSuccessPage()}?planID=${selectedPlanId}&cpId=${channelpartner}&contentUrl=${contentUrl}&appId=${appId}`;
    //     let failureUrl = `${subscriptionWindowLocation.origin + RoutingUtil.getSubscriptionFailurePage()}?planID=${selectedPlanId}&cpId=${channelpartner}&contentUrl=${contentUrl}&skuId=${skuId}&origin=${origin}&journeyStart=${subscriptionWindowLocation.pathname}&appId=${appId}`;
        
    //     if (appId === 'MOBILITY') {
    //         successUrl += '&viewType=webview';
    //         failureUrl += '&viewType=webview';
    //     }

    //     console.log(successUrl, failureUrl);

    //     debugger;
    //     return;

    //     purchaseSubscriptionInitDispatch({
    //         successUrl,
    //         failureUrl,
    //         pendingUrl: failureUrl,
    //         unknownUrl: failureUrl,
    //         planId: selectedPlanId,
    //         skuId,
    //         eventMethod: this.continueToPayEvent.bind({
    //             cpObject,
    //             selectedPlanId,
    //             circle,
    //             customer_type: userConfig.customerType,
    //             origin,
    //             uid,
    //         }),
    //     });
    // }

    onSubscribeClick = () => {
        const {
            channelpartner, contentUrl, userConfig, purchaseSubscriptionInitDispatch, browsePlans,
            getSubscriptionOrigin, getSubscriptionPlanValue, origin, showNonAirtelUserAction,
            uid, circle
        } = this.props;
        const { selectedPlanId, autoPayEnabled } = this.state;
        if(!selectedPlanId){
            return;
        }
        let cpData = (browsePlans || []).filter(plan => plan.cpId.includes(channelpartner) || plan.cpId.startsWith(channelpartner) || channelpartner.startsWith(plan.cpId));     
        cpData = cpData.length ? cpData[0]: {plans : []};
        const selectedPlan = cpData.plans.filter(plan => plan.planId === selectedPlanId);
        const {price, sku} = selectedPlan.length ? selectedPlan[0] : {};
        const skuId = (sku || {}).itunes
        getSubscriptionPlanValue({ price });
        getSubscriptionOrigin({ origin });
        
        if (userConfig.customerType === 'NON_AIRTEL' && (cpData.meta && cpData.meta.offerType === 'PREMIUM' || NOT_AVAILABLE_FOR_AIRTEL.includes(cpData.cpId))) {
            showNonAirtelUserAction({ cpId: channelpartner });
            return;
        }

        const {successUrl, failureUrl } =  SubscriptionUtil.getSubscriptionUrls(
            {plan : {planId: selectedPlanId, cpId: channelpartner, contentUrl, origin, skuId, autoPayEnabled},
            successPage:  RoutingUtil.getSubscriptionSuccessPage(),
            failurePage:  RoutingUtil.getSubscriptionFailurePage()
        });

        purchaseSubscriptionInitDispatch({
            successUrl,
            failureUrl,
            pendingUrl: failureUrl,
            unknownUrl: failureUrl,
            planId: selectedPlanId,
            skuId,
            autoPayEnabled,
            eventMethod: this.continueToPayEvent.bind({
                cpObject: cpData,
                selectedPlanId,
                circle,
                customer_type: userConfig.customerType,
                origin,
                uid,
            }),
        });
    }

    selectButton=({arg, planId, bestValue, autoPayEnabled}) => {
        // const { selectedPlanId } = this.state;
        const {
            sid, circle, channelData, browsePlans, channelpartner, origin, userConfig, uid,
        } = this.props;
        const cpObject = browsePlans.length > 0 && browsePlans.filter(a => a.cpId.includes(channelpartner))[0];
        const preSelectedPlanID = SubscriptionUtil.getBestValuePlanId(cpObject); 
        const id = channelData && channelData.length && channelData[0].id;
        const cpId = channelData && channelData.length && channelData[0].cpId;

        this.setState({
            selectedPlanId: planId,
            id: arg,
            autoPayEnabled
        });

        const planSelectionAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            offerId: id,
            planId,
            packageName: cpId,
            is_default_plan: bestValue,
            pre_selected_plan_id: preSelectedPlanID,
            pre_selected_offer_id: id,
            source: origin || SUBSCRIPTION_SOURCE.HOME_PAGE,
            ingressIntent: origin || SUBSCRIPTION_SOURCE.HOME_PAGE,
            pageName: ANALYTICS_ASSETS.SUBSCRIPTION_DETAILS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type: userConfig.customerType,
            uid,
        };
        planSelectionEvent(planSelectionAnalyticsMeta);
    }

    buttonClicked() {
        let { isClicked } = this.state;
        const {
            handleClick, channelpartner, sid, circle, browsePlans, origin, userConfig,history
        } = this.props;
        isClicked = !isClicked;
        const cpData = browsePlans && browsePlans.filter(cp => cp.cpId.includes(channelpartner))[0];
        const id = lodashGet(cpData, 'id', '');
        if (this.state.isClicked) {
            window.scrollTo({
                top: 50,
                behavior: 'smooth',
            });
        }
        const seeOtherPlansAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            offerId: id,
            offerTitle: channelpartner,
            active_tab: ANALYTICS_ASSETS.SUBSCRIBED_PACKS,
            source: origin,
            ingressIntent: origin,
            pageName: ANALYTICS_ASSETS.SUBSCRIPTION_DETAILS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type: userConfig.customerType,
        };
        seeOtherPlansClickEvent(seeOtherPlansAnalyticsMeta, true);
        this.setState({ isClicked });
        handleClick(isClicked);
    }

    

    render() {
        const {
            // eslint-disable-next-line no-unused-vars
            // eslint-disable-next-line react/prop-types
            channelpartner, browsePlans, cpId,
        } = this.props;
        const { autoPayEnabled ,id } = this.state;
        const btnClass = 'view-chev';
        const btnClassActive = 'view-chev rotate-chev';
        const cpObject = browsePlans.length > 0 && browsePlans.filter(a => a.cpId.includes(channelpartner))[0];
        const plans = cpObject && cpObject.plans;
        const appId = browserStore.get(LOCAL_STORE_KEYS.APP_ID);
        const {
            MY_PLANS_TNC = { ad_tnc: '* Ads will be shown on Sony Sports, Sony Liv Exclusive Content and Reality Shows by Sony LIV.' },
        } = this.LANGUAGE;
        return (
            <section className="plan-section-container">
                <p className="title">
                    Choose a plan
                    {/* <ThumborImage
                        src="/static/WarningCircle.svg"
                        className="icon"
                    /> */}
                </p>
                {autoPayEnabled ? <p className='title subtitle'>Subscription Auto Renews • Cancel Anytime</p>: null}
                <div className="price-card-wrapper price-card-wrapper-flex">
                    {/* <div className="price-card active">
                    </div> */}
                    {/* <div className="price-card"> */}
                    {plans && plans.length && plans.map((plan, index) => {
                        const {discount,price, title, planId, bestValue, autoPayEnabled} = plan;
                        return <MyButton
                            key={index}
                            discount={discount}
                            id={planId}
                            validityUnit={title}
                            total={price}
                            selectButton={this.selectButton}
                            isActive={id === planId}
                            activeId={id}
                            planId={planId}
                            bestValue={bestValue}
                            autoPayEnabled= {autoPayEnabled}
                        />
                    })}


                </div>
                <p className="agreement-line agreement-line-two">All amounts are inclusive of 18% GST</p>
                <AnalyticsButtonComponent
                    className="subscribe-btn shining-button-border"
                    onClick={this.onSubscribeClick}
                    aria-label="Subscribe"
                    // disabled={!this.state.selectedPlanId || false}
                >
                    Subscribe
                </AnalyticsButtonComponent>
                <p className="agreement-line">
                    By subscribing, you agree to Xstream’s
                    {' '}
                    {appId === 'MOBILITY' ? (
                        <a
                            className="links"
                            onClick={() => window.open('http://webview.airtel.tv/terms_of_use.html', '_self')}
                        >
                            {' '}
                            Terms of Uses
                        </a>
                    )
                        : (
                            <AnalyticsLinkComponent
                                id="footer-links"
                                className="links"
                                to={`/more/help/terms${appId === 'MOBILITY' ? '?viewType=webview' : ''}`}
                                meta={{
                                    action: 'click',
                                    asset_name: 'terms_of_use',
                                }}
                                rel="nofollow"
                                title="Terms of Uses"
                            >
                                Terms of Uses
                            </AnalyticsLinkComponent>
                        )
                    }
                    {' '}
                    &
                    {' '}
                    {appId === 'MOBILITY' ? (
                        <a
                            className="links"
                            onClick={() => window.open('http://webview.airtel.tv/privacy_policy.html', '_self')}
                        >
                            {' '}
                            Privacy Policy
                        </a>
                    )
                        : (
                            <AnalyticsLinkComponent
                                id="footer-links"
                                className="links"
                                to={`/more/help/privacy${appId === 'MOBILITY' ? '?viewType=webview' : ''}`}
                                meta={{
                                    action: 'click',
                                    asset_name: 'privacy_policy',
                                }}
                                rel="nofollow"
                                title="Privacy Policy"
                            >
                                Privacy Policy
                            </AnalyticsLinkComponent>
                        )
                    }
                </p>
                <p className="ad_tnc">{MY_PLANS_TNC?.ad_tnc}</p>
                {channelpartner === 'XSTREAMPREMIUM' && cpId && cpId !== 'XSTREAMPREMIUM'
                    && (
                        <AnalyticsButtonComponent
                            className="view-plan-btn"
                            visibility={false}
                            onClick={() => this.buttonClicked()}
                            ref={this.scrollElement}
                            aria-label="See Other Plans"
                            // style={this.state.style}

                        >
                            See Other Plans
                            {/* <ThumborImage
                                className={this.state.isClicked ? btnClassActive : btnClass}
                                src="/static/other-plan-chev.svg"
                            /> */}
                            <svg
                                className={this.state.isClicked ? btnClassActive : btnClass}
                                width="12"
                                height="6"
                                viewBox="0 0 12 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0 0L6 6L12 2.09815e-06L0 0Z"
                                    fill="#E8EAED"
                                />
                            </svg>
                        </AnalyticsButtonComponent>
                    )
                }
            </section>
        );
    }
}
CPDetailComponent.propTypes = {
    // origin: PropTypes.string.isRequired,
    redirectUrl: PropTypes.string.isRequired,
    channelpartner: PropTypes.string,
    // handleClick: PropTypes.function,
    browsePlans: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const mapStateToProps = (state, props) => {
    const {
        userConfig,
        planConfig,
    } = state;

    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const uid = lodashGet(userConfig, 'userInfo.uid', '');
    return {
        userConfig,
        sid,
        circle,
        uid,
    };
};

export default connect(mapStateToProps, {
    showNonAirtelUserAction,
    getSubscriptionPlanValue,
    getSubscriptionOrigin,
    purchaseSubscriptionInitDispatch: purchaseSubscriptionInit,
})(withDeviceUtil(withRouter(CPDetailComponent)));
