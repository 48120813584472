/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { PropTypes } from 'prop-types';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { characterToUpperCase } from '../../../utilities/CommonUtil';
import LanguageCodesProvider from '../../../providers/LanguageCodesProvider';
const LANGUAGES_CODES = LanguageCodesProvider();
const value = 1;
let val = 0;
class XmpChannelCardComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedCheckboxId: null,
            isCursorOnRail: false,
            indexOfRail: 0
        };
        this.carouselElementRef = React.createRef();
    }

    componentDidMount() {
        this.init();
        if (this.carouselElementRef && this.carouselElementRef.current) {
            const images = this.carouselElementRef.current.children;
            this.carouselElementRef.current.addEventListener('scroll', (e) => {
                let { indexOfRail } = this.state;
                indexOfRail = Math.ceil(this.carouselElementRef.current.scrollLeft / (images[0].offsetWidth + 8))
                this.setState({ indexOfRail })
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { popups } = this.props;
        if (prevProps.popups !== popups) {
            this.init();
        }
    }

    componentWillUnmount() {
        val = 0;
    }

    init = () => {
        const {cpDetailsById} = this.props;
        if (val < value) {
            const {
                 preferredPartnerChannelItem, popups,  selectChannelMweb,
            } = this.props;
            if (!popups) {
                return;
            }
            const preferredPartnerChannelItemPopId = lodashGet(popups, `${preferredPartnerChannelItem.popUpId}`, null);
            const x = document.getElementsByClassName('PlanSubscriptionXmpcheckbox');

            x[0].checked = true;
            const title = lodashGet(cpDetailsById[preferredPartnerChannelItem.cp], 'title', '');
            const cpId = lodashGet(cpDetailsById[preferredPartnerChannelItem.cp], 'cpId', '');
            selectChannelMweb(preferredPartnerChannelItem.productId, preferredPartnerChannelItemPopId.iconUrl, cpId, title);
            val++;
        }
    }

    next=() => {
        let { indexOfRail } = this.state;
        if (this.carouselElementRef.current) {
            let images = this.carouselElementRef.current.children;
            if (images && images.length > 0) {
                this.carouselElementRef.current.scrollLeft += images[0].offsetWidth;
                this.setState({ indexOfRail: ++indexOfRail })
            }
        }
    }

    previous =() => {
        let { indexOfRail } = this.state;
        if (indexOfRail <= 0) return;
        if (this.carouselElementRef.current) {
            const images = this.carouselElementRef.current.children;
            if (images && images.length > 0) {
                this.carouselElementRef.current.scrollLeft -= images[0].offsetWidth;
                this.setState({ indexOfRail: --indexOfRail })
            }
        }
    }

     onPlanSelect = (id, e, cp, logo) => {
         const {
             // eslint-disable-next-line no-unused-vars
             selectChannelMweb,
             cpDetailsById
         } = this.props;
         const x = document.getElementsByClassName('PlanSubscriptionXmpcheckbox');
         this.setState({
             selectedCheckboxId: e.currentTarget.id,
         });
         const title = lodashGet(cpDetailsById[cp], 'title', '');
         const cpId = lodashGet(cpDetailsById[cp], 'cpId', '');
         const cpIcon = lodashGet(cpDetailsById[cp], 'cpIconURL', '');
         for (let i = 0; i < x.length; i++) {
             if (x[i].id == id && x[i].checked === false) {
                 this.setState({
                     selectedCheckboxId: '',
                 });
                 cp = '';
             }
         }
         this.unCheck(e.currentTarget.id);
         selectChannelMweb(id, cpIcon, cpId, title);
     };

      unCheck =(id) => {
          const x = document.getElementsByClassName('PlanSubscriptionXmpcheckbox');
          for (let i = 0; i < x.length; i++) {
              if (x[i].id !== id) {
                  x[i].checked = false;
              }
          }
      }

    getImages = data => {
        if (!data || data === null) return [];
        return data.imageUrls;
    }

      render() {
          const {
              // eslint-disable-next-line no-unused-vars
              preferredPartnerChannelItem, popups, selectChannel, cpDetailsById, buttonLabel = 'Claim Now'
          } = this.props;
          let  { isCursorOnRail, indexOfRail } = this.state;
          const preferredPartnerChannelItemPopId = lodashGet(popups, `${preferredPartnerChannelItem.popUpId}`, null);
          const allImages = this.getImages(preferredPartnerChannelItemPopId);
          const cpName = lodashGet(preferredPartnerChannelItem, 'cp', '');
          const title = lodashGet(cpDetailsById[cpName], 'title', '');
          const cpIcon = lodashGet(cpDetailsById[cpName], 'roundLogoUrl', '');
          const PreferredPartnerChannelImages = allImages ? allImages.map((imageUrl, index) => (
              (
                  <ThumborImage
                      key={index}
                      src={imageUrl}
                      className="xmp-rail-img"
                  />
              ))) : null;
          //   PreferredPartnerChannelImages && PreferredPartnerChannelImages.push(...PreferredPartnerChannelImages);
          return (
              <div
                  className="xmp-card"

              >
                  <div
                      className="xmp-rail"
                      onMouseOver={() => this.setState({ isCursorOnRail: true })} onMouseLeave={() => this.setState({ isCursorOnRail: false })}
                  >
                      
                        <div className="slider-btn left">
                        { (isCursorOnRail && indexOfRail > 0) && <button
                                className="btn"
                                onClick={() => {
                                    this.previous();
                                }}
                            >
                                <ThumborImage
                                    src="/static/xmp-lleft-cchev.svg"
                                />
                            </button> }
                        </div>
                        <div
                            className="xmp-rail-main"
                            ref={this.carouselElementRef}
                        >
                            {PreferredPartnerChannelImages}

                        </div>
                        <div className="slider-btn right">
                        { (isCursorOnRail && indexOfRail < (allImages.length - 5)) &&<button
                                className="btn"
                                onClick={() => {
                                    this.next(true);
                                }}
                            >
                                <ThumborImage
                                    src="/static/xmp-righht-chev.svg"
                                />
                            </button>}
                        </div>

                  </div>
                  <div className="xmp-bottom">
                      <div className="logo-container">
                          <ThumborImage
                              src={cpIcon}
                              //   src={preferredPartnerChannelItemPopId ? preferredPartnerChannelItemPopId.iconUrl : null}
                          />
                          <p className="title-area">
                              <span className="heading">{characterToUpperCase(title)}</span>
                              <span className="text">{LANGUAGES_CODES[lodashGet(preferredPartnerChannelItem, 'language', null)]}</span>
                          </p>
                      </div>
                      <button
                          className="xmp-claim-btn"
                          onClick={() => selectChannel(preferredPartnerChannelItem.productId, lodashGet(cpDetailsById[preferredPartnerChannelItem.cp], 'cpIconURL', ''), preferredPartnerChannelItem.cp)}
                      >
                          {buttonLabel}
                      </button>
                      <label className="container">
                          {/* Warning: Do Not Change ClassName , it will lead to Code break */}
                          <input
                              type="checkbox"
                              className="PlanSubscriptionXmpcheckbox"
                              id={preferredPartnerChannelItem.productId}
                              //  checked={this.state && this.state.selectedCheckboxId === checked}
                              // checked={false}
                              onClick={e => this.onPlanSelect(preferredPartnerChannelItem.productId, e, preferredPartnerChannelItem.cp, preferredPartnerChannelItemPopId.iconUrl)}
                          />
                          <span className="checkmark" />
                      </label>
                  </div>
              </div>
          );
      }
}
const mapStateToProps = (state, props) => {
    const {
        allPlans,
        appConfig,
        planConfig,
    } = state;
    const browsePlans = lodashGet(allPlans, 'browsePlans', []);
    const xstreamPremiumData = lodashGet(appConfig, 'subscriptionData.xstreamPremium', {});
    const cpDetailsById = lodashGet(appConfig, 'cpDetailsById', {});
    const redirectUrl = lodashGet(planConfig, 'redirectUrl', '');
    return {
        browsePlans,
        redirectUrl,
        cpDetailsById,
        xstreamPremiumData,
    };
};
XmpChannelCardComponent.propTypes = {
    origin: PropTypes.string.isRequired,
    redirectUrl: PropTypes.string.isRequired,
    channelpartner: PropTypes.string,
    channelData: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    browsePlans: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types,
    xstreamPremiumData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    buttonLabel: PropTypes.object.isRequired
};
export default connect(mapStateToProps, null)(withDeviceUtil(withRouter(XmpChannelCardComponent)));
