import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { PropTypes } from 'prop-types';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import Loadable from 'react-loadable';
import { pageLoadEvent } from '@airtel-tv/analytics/FunctionalEvents';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import NoSubscriptionComponent from '../component/NoSubscriptionComponent';
import ActivePlansContainer from './ActivePlansContainer';
// import NoPlansContainer from './NoPlanContainer';
import { filterSubscribedPlans } from '../../../utilities/CommonUtil';
import RoutingUtil from '../../../utilities/RoutingUtil';
import { getCurrentUrl } from '../../../utilities/WindowUtil';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import SubscriptionLoaderComponent from '../../shared/components/loader/SubscriptionLoaderComponent';
import { removeChannelPartnerId } from '../../channel-partner/ChannelPartnerActions';
import { planConfigInit } from '../../plan/PlanActions';
import RechargePlansWrapper from '../component/RechargePlans/RechargePlansWrapper';
import { LanguageProviderUtil } from '@airtel-tv/utils';
import { filterXtreamPremium } from '@airtel-tv/utils/GlobalUtil';

const NoPlansContainer = Loadable({
    loader: () => import('./NoPlanContainer' /* webpackChunkName: "NoPlans" */),
    loading: () => <div />,
});

class MyPlansContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        };
        this.appId = browserStore.get(LOCAL_STORE_KEYS.APP_ID);
    }

    componentDidMount() {
        const {
            isAuthenticated, history, removeChannelPartnerId, userConfig, uid, planInitDispatch,
        } = this.props;

        if (!isAuthenticated) {
            history.push({
                pathname: RoutingUtil.getHomePage(),
                search: lodashGet(history, 'location.search', ''),
            });
        }
        planInitDispatch();
        removeChannelPartnerId();
        const { circle, sid } = this.props;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: ANALYTICS_ASSETS.MY_PLANS_PAGE,
            source: ANALYTICS_ASSETS.MY_PLANS_PAGE,
            pageName: ANALYTICS_ASSETS.MY_PLANS_PAGE,
            ingressIntent: ANALYTICS_ASSETS.MY_PLANS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type: userConfig.customerType,
            uid,
        };
        pageLoadEvent(pageLoadAnalyticsMeta, true);
    }

    componentDidUpdate(prevProps) {
        const { isAuthenticated, history } = this.props;
        if (prevProps.isAuthenticated !== isAuthenticated) {
            history.push({
                pathname: RoutingUtil.getHomePage(),
                search: lodashGet(history, 'location.search', ''),
            });
        }
        if (prevProps.combinedPlansData !== this.props.combinedPlansData && this.props.combinedPlansData) {
            this.setState({ isLoading: false });
        }
    }

    getPlanPage(combinedPlansData) {
        const { MY_PLANS_TXT = 'Plan And Offers' } = LanguageProviderUtil?.getLanguage();
        const plans = filterXtreamPremium(combinedPlansData);
        const noSubscribedPlans = plans && plans.length === 0;
        return (noSubscribedPlans ? <RechargePlansWrapper type="PLANS_TOGGLE" /> : (
            <>
                <ActivePlansContainer noSubscribedPlans={noSubscribedPlans} />
            </>
        ));
    }

    // getSubscribedPlans = (combinedPlansData) => {
    //     if (!combinedPlansData) {
    //         return;
    //     }
    //     this.setState({ isLoading: true });
    //     const plans = filterSubscribedPlans(combinedPlansData);
    //     this.setState({ isLoading: false });
    //     return plans;
    // }

    render() {
        const { combinedPlansData } = this.props;
        const { isLoading } = this.state;
        // const plans = this.getSubscribedPlans(combinedPlansData);
        return (
            <div>
                {
                    isLoading ? <SubscriptionLoaderComponent /> : this.getPlanPage(combinedPlansData)
                }
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        authConfig: { isAuthenticated },
        userConfig,
        userConfig: { userInfo },
        planConfig: { subscribedPlans: combinedPlansData, sid = '', userDetails },
    } = state;
    const { circle = '' } = userDetails || {};
    const { uid = '' } = userInfo || {};
    return {
        combinedPlansData,
        isAuthenticated,
        sid,
        circle,
        userConfig,
        uid,
    };
};
MyPlansContainer.propTypes = {
    origin: PropTypes.string.isRequired,
    channelpartner: PropTypes.string,
    combinedPlansData: PropTypes.object, // eslint-disable-line react/forbid-prop-type
};
export default connect(mapStateToProps, {
    removeChannelPartnerId,
    planInitDispatch: planConfigInit,
})(withDeviceUtil(withRouter(MyPlansContainer)));
