import React, { useEffect } from 'react';
import PropTypes, { object } from 'prop-types';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import RechargePlans from '@airtel-tv/recharge-plans/RechargePlans';
import RechargePlanDetail from '@airtel-tv/recharge-plans/RechargePlanDetail';
import { CryptoUtil } from '@airtel-tv/utils/CryptoUtil';
import SubscriptionUtil, { airtelRechargeRedirection } from '@airtel-tv/utils/SubscriptionUtil';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { LanguageProviderUtil, LocationUtil } from '@airtel-tv/utils';
import { screenVisibleEvent, AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { showModalComponentAction, hideModalComponentAction } from '../../../modal-popup/ModalPopupActions';
import { MODAL_POPUP } from '../../../../constants/AppConst';
import { checkRechargePaymentStatus, purchaseSubscriptionInit } from '../../action/SubscriptionAction';
import '../../../modal-popup/PopupModal.scss';
import PlansToggleContainer from '@airtel-tv/recharge-plans/PlansToggleContainer';
import { continueToPayError, subscribeClickEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { filterSubscribedPlans, getSid } from '@airtel-tv/utils/GlobalUtil';
import { getCurrentUrl } from '@airtel-tv/utils/WindowUtil';
import { SUBSCRIPTION_SOURCE } from '@airtel-tv/constants/EventsConst';
import { ANALYTICS_ASSETS } from '@airtel-tv/constants/AnalyticsConst';
import { CUSTOMER_TYPE } from '@airtel-tv/constants/GlobalConst.js';
import { useUpdateCustomHook } from '@airtel-tv/lib/hooks';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import ActivePlansContainer from '../../container/ActivePlansContainer';
import { showNonAirtelUserAction } from '../../../user/UserAction';
import { triggerLogin } from '../../../auth/AuthAction';
import RoutingUtil from '../../../../utilities/RoutingUtil';
import styles from './RechargePlan.scss';


const continueToPayEvent = ({
    planId,
    circle,
    customer_type,
    sid,
    redirectionUrl,
    isError,
    response,
    uid,
    isRechargePlan,
}) => {
    const subscribeClickAnalyticsMeta = {
        pageUrl: getCurrentUrl(),
        appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
        circle,
        packageName: 'XSTREAMPREMIUM',
        sid: getSid(sid, response),
        customer_type,
        planId,
        source: origin || SUBSCRIPTION_SOURCE.HOME_PAGE,
        ingressIntent: origin || SUBSCRIPTION_SOURCE.HOME_PAGE,
        pageName: ANALYTICS_ASSETS.SUBSCRIPTION_DETAILS_PAGE,
        service: ANALYTICS_ASSETS.AIRTELTV,
        uid,
        is_recharge_plan: isRechargePlan,
    };

    if (isError) {
        continueToPayError({
            ...subscribeClickAnalyticsMeta,
            pageUrl: redirectionUrl,
            response: {
                response,
                subscribeClickAnalyticsMeta,
            },
        });
    }
    else {
        subscribeClickEvent({
            ...subscribeClickAnalyticsMeta,
            response: {
                response,
                subscribeClickAnalyticsMeta,
            },
        });
    }
};


const subscribe = ({
    plan = {},
    purchaseSubscriptionInitDispatch = () => {},
    circle = '',
    uid = '',
    getSubscriptionUrls = () => {},
    RoutingUtil = {},
    customerType = '',
    showNonAirtelUserActionDispatch = null,
    successUrlOverride,
    isRechargePlan = false,
}) => {
    if (customerType === CUSTOMER_TYPE.NON_AIRTEL) {
        if (showNonAirtelUserActionDispatch && typeof showNonAirtelUserActionDispatch === 'function') {
            showNonAirtelUserActionDispatch();
        }
        return;
    }
    let { successUrl, failureUrl } = getSubscriptionUrls(
        {
            plan: {
                planId: plan.planId,
                cpId: plan.cpId,
                autoPayEnabled: !isRechargePlan,
            },
            successPage: RoutingUtil.getSubscriptionSuccessPage(),
            failurePage: RoutingUtil.getSubscriptionFailurePage(),
        },
    );
    if (successUrlOverride) {
        successUrl = successUrlOverride;
    }
    const { renewPlanId, planId, autoPayEnabled } = plan;
    const planIdToExtend = renewPlanId || planId;
    const { sku, meta = {} } = plan;
    const skuId = (sku || {}).itunes;
    const {
        offerIdentifier: identifier = '',
        keyIdentifier = '',
        nonce = '',
        signature = '',
        timestamp = 0,
    } = meta;
    purchaseSubscriptionInitDispatch({
        successUrl,
        failureUrl,
        pendingUrl: failureUrl,
        unknownUrl: failureUrl,
        planId: planIdToExtend,
        skuId,
        autoPayEnabled,
        identifier,
        keyIdentifier,
        nonce,
        signature,
        timestamp,
        isRechargePlan,
        disableAutoRenew: isRechargePlan,
        eventMethod: (sid, redirectionUrl, isError, response) => {
            continueToPayEvent({
                planId: planIdToExtend,
                circle,
                customer_type: customerType,
                uid,
                sid,
                redirectionUrl,
                isError,
                response,
                isRechargePlan,
            });
        },
    });
};

function RechargePlansWrapper(props) {
    useStyles(styles);
    const {
        showModalComponentActionDispatch,
        checkRechargePaymentStatusDispatch,
        rechargePlans = [],
        xppPlans,
        type,
        userConfig,
        circle,
        uid,
        purchaseSubscriptionInitDispatch,
        isAuthenticated,
        triggerLoginDispatch,
        hideModalComponentActionDispatch,
        history,
        headerBgImage,
        headerDescriptions,
        msisdnNo,
        headerImageObject,
        showNonAirtelUserActionDispatch,
        customerType,
        useNativeRecharge,
        subscribedPlans,
    } = props;
    const plans = filterSubscribedPlans(subscribedPlans);

    const { RECHARGE_STATUS_PAGE = '', RECHARGE_ONLY = '' } = ROUTE_PATHS;
    const currentRoute = typeof window !== 'undefined' ? window.location.href : '';
    const windowObj = typeof window !== 'undefined' ? window : {};
    const os = LocationUtil.getKeyFromURL(windowObj?.location?.search, 'os');
    const price = LocationUtil.getKeyFromURL(windowObj?.location?.search, 'price');
    const redirectAirtelThanks = LocationUtil.getKeyFromURL(windowObj?.location?.search, 'redirectAirtelThanks');
    const isIos = os === 'IOS';
    const { pathname = '' } = windowObj?.location;
    const isAirtelStatusPopUp = pathname === RECHARGE_STATUS_PAGE;
    const LANGUAGE = LanguageProviderUtil?.getLanguage();

    const {
        SEE_ALL_PLANS = 'See All Plans',
    } = LANGUAGE;

    useEffect(() => {
        if (!isAuthenticated) {
            triggerLoginDispatch({
                loginTriggered: true,
            });
        }
        else if (msisdnNo && redirectAirtelThanks && price) {
            const statusUrl = `${RECHARGE_STATUS_PAGE}?planID=0&cpId=XSTREAMPREMIUM&msisdn=${msisdnNo}&amount=${price}`;
            airtelRechargeRedirection({
                selectedPlan: {
                    total: price,
                    title: 'XstreamPremium',
                },
                msisdnNo,
                statusUrl,
            });
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated && msisdnNo && redirectAirtelThanks && price) {
            const statusUrl = `${RECHARGE_STATUS_PAGE}?planID=0&cpId=XSTREAMPREMIUM&msisdn=${msisdnNo}&amount=${price}`;
            airtelRechargeRedirection({
                selectedPlan: {
                    total: price,
                    title: 'XstreamPremium',
                },
                msisdnNo,
                statusUrl,
            });
        }
    }, [
        isAuthenticated,
        msisdnNo,
    ]);

    useUpdateCustomHook(() => {
        if (!isAuthenticated) {
            history.push({
                pathname: RoutingUtil.getHomePage(),
                search: lodashGet(history, 'location.search', ''),
            });
        }
    }, [
        isAuthenticated,
    ]);

    if (!isAuthenticated) {
        return null;
    }
    switch (type) {
        case 'PLANS_LISTING':
            return (
                <RechargePlans
                    {...props}
                    isIos={isIos}
                    rechargePlans={rechargePlans}
                    seeAllPlansText={SEE_ALL_PLANS}
                    modalPopUp={MODAL_POPUP.RECHARGE_PLAN_DETAIL}
                    RECHARGE_STATUS_PAGE={RECHARGE_STATUS_PAGE}
                    showModalComponentActionDispatch={showModalComponentActionDispatch}
                    screenVisibleEvent={screenVisibleEvent}
                    AnalyticsButtonComponent={AnalyticsButtonComponent}
                    useNativeRecharge={useNativeRecharge}
                    subscribeRechargePlan={({ plan, successUrlOverride }) => {
                        subscribe({
                            plan,
                            purchaseSubscriptionInitDispatch,
                            circle,
                            customerType,
                            uid,
                            getSubscriptionUrls: SubscriptionUtil.getSubscriptionUrls,
                            RoutingUtil,
                            showNonAirtelUserActionDispatch,
                            successUrlOverride,
                            isRechargePlan: true,
                        });
                    }}
                />
            );
        case 'PLANS_DETAILS':
            return (
                <RechargePlanDetail
                    {...props}
                    isAirtelStatusPopUp={isAirtelStatusPopUp}
                    RECHARGE_STATUS_PAGE={RECHARGE_STATUS_PAGE}
                    checkRechargePaymentStatusDispatch={checkRechargePaymentStatusDispatch}
                    screenVisibleEvent={screenVisibleEvent}
                    AnalyticsButtonComponent={AnalyticsButtonComponent}
                    closeModal={() => hideModalComponentActionDispatch(false, null, null)}
                />
            );
        case 'PLANS_TOGGLE':
            return (
                <PlansToggleContainer
                    {...props}
                    isIos={isIos}
                    rechargePlans={rechargePlans}
                    modalPopUp={MODAL_POPUP.RECHARGE_PLAN_DETAIL}
                    RECHARGE_STATUS_PAGE={RECHARGE_STATUS_PAGE}
                    showModalComponentActionDispatch={showModalComponentActionDispatch}
                    screenVisibleEvent={screenVisibleEvent}
                    AnalyticsButtonComponent={AnalyticsButtonComponent}
                    xppPlans={xppPlans}
                    purchaseSubscriptionInitDispatch={purchaseSubscriptionInitDispatch}
                    circle={circle}
                    userConfig={userConfig}
                    uid={uid}
                    getSubscriptionUrls={SubscriptionUtil.getSubscriptionUrls}
                    RoutingUtil={RoutingUtil}
                    goBack={() => {
                        history?.goBack();
                    }}
                    headerImageObject={headerImageObject}
                    headerBgImage={headerBgImage}
                    headerDescriptions={headerDescriptions}
                    redirectAirtelThanks={redirectAirtelThanks === 'true'}
                    price={price}
                    showNonAirtelUserActionDispatch={showNonAirtelUserActionDispatch}
                    subscribe={subscribe}
                />
            );
        default:
            if (currentRoute.includes(RECHARGE_ONLY) || currentRoute.includes(ROUTE_PATHS.SUBSCRIPTION_PAGE)) {
                return (
                    <PlansToggleContainer
                        {...props}
                        isIos={isIos}
                        rechargePlans={rechargePlans}
                        modalPopUp={MODAL_POPUP.RECHARGE_PLAN_DETAIL}
                        RECHARGE_STATUS_PAGE={RECHARGE_STATUS_PAGE}
                        showModalComponentActionDispatch={showModalComponentActionDispatch}
                        screenVisibleEvent={screenVisibleEvent}
                        AnalyticsButtonComponent={AnalyticsButtonComponent}
                        xppPlans={xppPlans}
                        purchaseSubscriptionInitDispatch={purchaseSubscriptionInitDispatch}
                        circle={circle}
                        userConfig={userConfig}
                        uid={uid}
                        getSubscriptionUrls={SubscriptionUtil.getSubscriptionUrls}
                        RoutingUtil={RoutingUtil}
                        goBack={() => {
                            history?.goBack();
                        }}
                        headerImageObject={headerImageObject}
                        headerBgImage={headerBgImage}
                        headerDescriptions={headerDescriptions}
                        redirectAirtelThanks={redirectAirtelThanks === 'true'}
                        price={price}
                        showNonAirtelUserActionDispatch={showNonAirtelUserActionDispatch}
                        subscribe={subscribe}
                    />
                );
            }
            if (currentRoute.includes(RECHARGE_STATUS_PAGE)) {
                return (
                    <RechargePlanDetail
                        {...props}
                        isAirtelStatusPopUp={isAirtelStatusPopUp}
                        RECHARGE_STATUS_PAGE={RECHARGE_STATUS_PAGE}
                        checkRechargePaymentStatusDispatch={checkRechargePaymentStatusDispatch}
                        screenVisibleEvent={screenVisibleEvent}
                        AnalyticsButtonComponent={AnalyticsButtonComponent}
                        closeModal={() => hideModalComponentActionDispatch(false, null, null)}
                    />
                );
            }
    }
}

RechargePlansWrapper.propTypes = {
    type: PropTypes.string,
    showModalComponentActionDispatch: PropTypes.func.isRequired,
    checkRechargePaymentStatusDispatch: PropTypes.func.isRequired,
    rechargePlans: PropTypes.arrayOf(object).isRequired,
    NOT_ELIGIBLE_TEXT: PropTypes.string.isRequired,
};

RechargePlansWrapper.defaultProps = {
    type: '',
};
const mapStateToProps = (state) => {
    const {
        planConfig: {
            rechargePlans = [],
            xppPlans = {},
            userDetails: { circle = '' } = {},
        },
        authConfig: {
            token = '',
            isAuthenticated = false,
            uid = '',
        } = {},
        allPlans: {
            rechargePaymentStatus = '', xppBenefitWithPlan = false, validity = '', amount = '', planId = '',
            browsePlans = [], subscribedPlans = [],
            daysTillExpiry = 0,
        },
        allPlans = {},
        userConfig: {
            userInfo: {
                msisdn = '',
                prepaidUser = false,
            } = {},
            userContentProperties = {},
            customerType = '',
        },
        userConfig = {},
        appConfig: {
            rechargePlans: rechargePlansConfig = {}, xstreamCpId = 'XSTREAMPREMIUM', cpDetailsById = {}, RECHARGES_TO_SHOW = 2,
            nativeRechargesUid = [],
        } = {},
        planConfig = {},
        location: {
            navigationObj: {
                prevPath = '',
            },
        },
    } = state;
    let {
        appConfig: {
            useNativeRecharge = true,
        },
    } = state;
    const {
        bgImg = "https://image.airtel.tv/grandslam/content/2023_7_19/Group_1639237.png'",
        paymentMode = "AIRTEL_PARTNER'",
        primaryBtnText = 'Plan Details',
        mockPlan = '',
        secondaryCtaTxt = 'Recharge with ₹{airtelPlan}',
        xppTxt = 'Xstream Play Benefits',
        mobileBenefitsTxt = 'Mobile Benefits',
        XSTREAM_HOME_CTA_TEXT = 'Move to Xstream Play',
        xppIcon = 'https://image.airtel.tv/grandslam/content/2023_7_20/Xstream_logo_(1).png',
        successTitle = 'Recharge Successful!',
        successSubtitle = 'Enjoy Unlimited movies and Shows with mobile benefits',
        rechargeStatus = 'Recharge of {amount} successful',
        successCtaText = 'Ok, got it',
        NOT_ELIGIBLE_TEXT = 'You are not eligible for recharge',
        headerDescriptions = '10000+ Movies & Series|20+ OTT Apps|1 Destination',
    } = rechargePlansConfig || {};
    const headerBgImage = 'https://image.airtel.tv/grandslam/content/2024_6_8/Mask_group.png';
    const headerImageObject = [
        {
            imageUrl: 'https://image.airtel.tv/grandslam/content/2023_9_22/1.png',
            tileType: 'BANNER',
            cpId: 'xpp',
            noRedirection: true,
        },
        {
            imageUrl: 'https://image.airtel.tv/grandslam/content/2023_9_22/2.png',
            tileType: 'BANNER',
            cpId: 'xpp',
            noRedirection: true,
        },
        {
            imageUrl: 'https://image.airtel.tv/grandslam/content/2023_9_22/3.png',
            tileType: 'BANNER',
            cpId: 'xpp',
            noRedirection: true,
        },
    ];
    let msisdnNo = '';
    if (msisdn && token) {
        msisdnNo = CryptoUtil.AESDecryptMsdin({
            key: token,
            value: msisdn,
        });
        msisdnNo = msisdnNo?.replace('+91', '');
    }
    let additiveValidity = daysTillExpiry;
    if (nativeRechargesUid.length > 0 && !nativeRechargesUid.includes(uid)) {
        additiveValidity = 0;
        useNativeRecharge = false;
    }
    return {
        bgImg,
        paymentMode,
        primaryBtnText,
        msisdnNo,
        customerType,
        mockPlan,
        secondaryCtaTxt,
        xppTxt,
        mobileBenefitsTxt,
        XSTREAM_HOME_CTA_TEXT,
        rechargePlans,
        xppIcon,
        successTitle,
        successSubtitle,
        successCtaText,
        rechargePaymentStatus,
        rechargeStatus,
        NOT_ELIGIBLE_TEXT,
        xppPlans,
        userConfig,
        circle,
        uid,
        isAuthenticated,
        headerBgImage,
        headerImageObject,
        headerDescriptions,
        xppBenefitWithPlan,
        validity,
        amount,
        planId,
        useNativeRecharge,
        browsePlans,
        xstreamCpId,
        cpDetailsById,
        subscribedPlans,
        prepaidUser,
        RECHARGES_TO_SHOW,
        allPlans,
        planConfig,
        prevPath,
        userContentProperties,
        additiveValidity,
    };
};

export default connect(
    mapStateToProps,
    {
        checkRechargePaymentStatusDispatch: checkRechargePaymentStatus,
        showModalComponentActionDispatch: showModalComponentAction,
        purchaseSubscriptionInitDispatch: purchaseSubscriptionInit,
        triggerLoginDispatch: triggerLogin,
        hideModalComponentActionDispatch: hideModalComponentAction,
        showNonAirtelUserActionDispatch: showNonAirtelUserAction,
    },
)(withRouter(RechargePlansWrapper));
