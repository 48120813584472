/* eslint-disable react/button-has-type */
import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from '../container/SubscriptionContainer.scss';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import RoutingUtil from '../../../utilities/RoutingUtil';
import { hideModalComponentAction } from '../../modal-popup/ModalPopupActions';
import { LocationUtil } from '@airtel-tv/utils';
import AuthenticationUtil from '@airtel-tv/utils/AuthenticationUtil';
import { getCurrentUrl } from '../../../utilities/WindowUtil';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { okGotItClickEvent, onCancelXmpClickEvent, pageLoadEvent, popUpVisibleEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { planConfigInit } from '../../plan/PlanActions';
import { EVENT_NAME } from '../../../constants/SubscriptionConst';
import SubscriptionUtil  from '@airtel-tv/utils/SubscriptionUtil';
import env from '../../../config';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
class SingleClaimSuccessPopUp extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isEventFired:false
        };
        this.ISMOBILITY = browserStore.get(LOCAL_STORE_KEYS.MOBILITYOS);
    }

    pageinitEvent() {
        const { circle, sid,userConfig,uid } = this.props;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            customer_type:userConfig.customerType,
            active_tab: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            source: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            ingressIntent: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            pageName: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            service: ANALYTICS_ASSETS.AIRTELTV,
            uid
        };
        pageLoadEvent(pageLoadAnalyticsMeta, true);
        popUpVisibleEvent(pageLoadAnalyticsMeta, true);
    }

    componentDidMount() {
        const { planInitDispatch } = this.props;
        planInitDispatch();
        const appId = LocationUtil.getKeyFromURL(window.location.search,'appId') || AuthenticationUtil.getAppId() || env.appId;
        if (appId === 'MOBILITY') {
            // LocationUtil.handleAppstate('success');
        }
        this.pageinitEvent();
    }


    sendHandleAppState(){
        const {subscribedPlans,productId,subscribedCpId} = this.props;
       
        // let filteredPlans = filterSubscribedPlans(subscribedPlans);
        // let plans = filterChannelAndRecharge(filteredPlans).true;
        // let subscribedPlan = findSelectedCP(plans,subscribedCpId);
        // if(subscribedPlan){
        //     let branchEvent = getBranchDetail(subscribedPlan,EVENT_NAME.CLAIM_SUCCESS)
        // }
        if(subscribedPlans.length>0 && !this.state.isEventFired){
            let isEventFired = SubscriptionUtil.sendHandleAppState(subscribedPlans,subscribedCpId,'success',productId,true,EVENT_NAME.CLAIM_SUCCESS)
            this.setState({
                isEventFired
            })
        }
    }

     onCancelClick = () => {
         let {
             history, hideModalComponentActionDispatch, contentUrl,
              originalCpId, subscribedCpId, location, circle, sid,
              userConfig, uid
         } = this.props;
         const appId = LocationUtil.getKeyFromURL(window.location.search,'appId') || AuthenticationUtil.getAppId() || env.appId;
         
         const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
             appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
             circle,
             sid,
             customer_type:userConfig.customerType,
             active_tab: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
             source: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
             pageName: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
             ingressIntent: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
             service: ANALYTICS_ASSETS.AIRTELTV,
             uid
         };
         onCancelXmpClickEvent(pageLoadAnalyticsMeta, true);
         if (appId === 'MOBILITY' || this.ISMOBILITY) {
             LocationUtil.handleCloseAppstate();
         }
         else {
             hideModalComponentActionDispatch(
                 false,
                 null,
                 null,
             );

             if (originalCpId !== subscribedCpId) {
                 this.redirectToChannelPage();
                 return;
             }
             const channelPartnerLocation = lodashGet(location, 'state.contentUrl', '');
             contentUrl = contentUrl || channelPartnerLocation;
             if (contentUrl) {
                 history.push({
                     pathname: contentUrl,
                     search: lodashGet(history,'location.search',''),
                 });
             }
             else {
                 this.redirectToChannelPage();
             }
         }
     }

     redirectToChannelPage = () => {
         const { history, cpDetailsById, cpName } = this.props;
         if (cpName === 'XSTREAMPREMIUM') {
             history.push({
                 pathname: RoutingUtil.getHomePage(),
                 search: lodashGet(history,'location.search',''),
             });
             return;
         }
         const title = lodashGet(cpDetailsById[cpName], 'title', '');
         const pageLink = lodashGet(cpDetailsById[cpName], 'pageLink', '');

         history.push({
             pathname: RoutingUtil.getChannelPartnerPageUrl({
                 channelPartnerName: title,
                 pageLink,
             }),
             search: lodashGet(history,'location.search',''),
         });
     }

    redirectToContentDetailPage = () => {
        let {
            history, location, hideModalComponentActionDispatch, contentUrl,
            originalCpId, subscribedCpId, sid, circle,userConfig,uid,
            cpName,
            Hoichoi_Channel_Native_Link
        } = this.props;
        const status = 'SUCCESS';
        const appId = LocationUtil.getKeyFromURL(window.location.search,'appId') || AuthenticationUtil.getAppId() || env.appId;

        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            customer_type:userConfig.customerType,
            active_tab: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            source: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            pageName: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            ingressIntent: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            service: ANALYTICS_ASSETS.AIRTELTV,
            uid
        };
        okGotItClickEvent(pageLoadAnalyticsMeta, true);

        if (appId === 'MOBILITY' || this.ISMOBILITY) {
            if(cpName === 'HOICHOI'){
                LocationUtil.handleOpenIntent(Hoichoi_Channel_Native_Link)
            }else{
                LocationUtil.handleCloseAppstate(status);
            }
        }
        else {
            
            hideModalComponentActionDispatch(
                false,
                null,
                null,
            );

            if (originalCpId !== subscribedCpId) {
                this.redirectToChannelPage();
                return;
            }
            const channelPartnerLocation = lodashGet(location, 'state.contentUrl', '');
            contentUrl = contentUrl || channelPartnerLocation;
            if (contentUrl) {
                history.push({
                    pathname: contentUrl,
                    search: lodashGet(history,'location.search',''),
                });
            }
            else {
                this.redirectToChannelPage();
            }
        }
        // `${origin === SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE_XMP_MODAL ? 'xmp-success-modal-wrapper' : 'popup-layer'}`
    }

    render() {
        const {
            cpLogo, subscriptionPopUp,
        } = this.props;
        const singleClaimSuccessTitle = lodashGet(subscriptionPopUp, 'singleClaimSuccessTitle', '');
        const singleClaimSuccessSubtext = lodashGet(subscriptionPopUp, 'singleClaimSuccessSubtext', '');
        const singleClaimSuccessBtn = lodashGet(subscriptionPopUp, 'singleClaimSuccessBtn', '');
        this.sendHandleAppState()
        return (
            <div className="xmp-success-modal-wrapper">
                <div className="single-success-popup">
                    <button
                        className="single-success-cross"
                        onClick={() => {
                            this.onCancelClick();
                        }}
                    >
                        <ThumborImage
                            src="/static/close.svg"
                        />
                    </button>
                    <div className="logo-container-single">
                        <ThumborImage
                            className="single-popup-logo"
                            src={cpLogo}
                        />
                    </div>

                    <p className="activation-status">{singleClaimSuccessTitle}</p>
                    <p className="activation-text">{singleClaimSuccessSubtext}</p>
                    <button
                        className="single-success-btn"
                        onClick={() => this.redirectToContentDetailPage()}
                    >
                        {singleClaimSuccessBtn}
                    </button>
                </div>
            </div>

        );
    }
}
const mapStateToProps = (state, props) => {
    const {
        
        allPlans,
        authConfig: { isAuthenticated },
        appConfig,
        userConfig,
        popupConfig,
        notify,
        planConfig,
    } = state;
    const browsePlans = lodashGet(allPlans, 'browsePlans', []);
    const subscribedPlans = lodashGet(planConfig,'subscribedPlans',[])
    const isFetchingDone = lodashGet(notify, 'isFetchingDone', null);
    const ChannelPartnerImageData = lodashGet(appConfig, 'xstreamPremiumData.partnersList', {});
    const preferredPartnerPlans = lodashGet(userConfig, 'preferredPartnerPlans', []);
    const popups = lodashGet(popupConfig, 'allPopUps', null);
    const cpDetailsById = lodashGet(appConfig, 'cpDetailsById', {});
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const subscriptionPopUp = lodashGet(appConfig, 'subscriptionPopUp', {});
    const uid = lodashGet(userConfig, 'userInfo.uid', '');
    const Hoichoi_Channel_Native_Link = lodashGet(appConfig,'Hoichoi_Campaign.Hoichoi_Channel_Native_Link', '');
    return {
        browsePlans,
        isAuthenticated,
        ChannelPartnerImageData,
        preferredPartnerPlans,
        popups,
        isFetchingDone,
        cpDetailsById,
        sid,
        circle,
        subscriptionPopUp,
        userConfig,
        uid,
        subscribedPlans,
        Hoichoi_Channel_Native_Link,
    };
};
SingleClaimSuccessPopUp.propTypes = {
    channelpartner: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    triggerLoginDispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    browsePlans: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types,
    // eslint-disable-next-line react/forbid-prop-types
    ChannelPartnerImageData: PropTypes.array.isRequired,
};
export default connect(mapStateToProps, {
    hideModalComponentActionDispatch: hideModalComponentAction,
    planInitDispatch: planConfigInit,
})(withDeviceUtil(withRouter(withStyles(styles)(SingleClaimSuccessPopUp))));
