import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { PropTypes } from 'prop-types';
import RoutingUtil from '../../../utilities/RoutingUtil';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './SubscriptionContainer.scss';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { LOGIN_SOURCE, SUBSCRIPTION_SOURCE } from '../../../constants/EventsConst';
import XmpMultipleClaimContainer from './XmpMultipleClaimContainer';
import XmpSingleClaimContainer from './XmpSingleClaimContainer';
import { triggerLogin } from '../../auth/AuthAction';
import { updatePreferredPartnerSelections } from '../../user/UserAction';
import { hideModalComponentAction, showModalComponentAction } from '../../modal-popup/ModalPopupActions';
import { MODAL_POPUP } from '../../../constants/AppConst';
import { LocationUtil } from '@airtel-tv/utils';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { criticalClickEvent, unlockNowClickEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { getDayMonth, isObjEmpty } from '../../../utilities/CommonUtil';
import { showToastr } from '../../../utilities/NotifyUtil';
import LanguageProvider from '../../../providers/LanguageProvider';

class XmpClaimContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            preferredPartnerOfferPlanId: null,
            preferredPartnerOfferPlanPopupId: null,
            preferredPartnerOfferPlanRefId: null,
            showYourOffersPage: true,
            cpSubscribedName: '',
            cpSubscribedLogo: '',
            productId:'',
            validity: '',
        };
        this.deviceUtil = props.deviceUtil;
        this.LANGUAGE = LanguageProvider();
    }

    componentDidMount() {
        const {
            preferredPartnerPlans, isAuthenticated, triggerLoginDispatch,
        } = this.props;
        let { preferredPartnerOfferPlanId, preferredPartnerOfferPlanPopupId, preferredPartnerOfferPlanRefId } = this.state;
        if (preferredPartnerPlans.length === 0) {
            window.location.replace('');
        }

        const { history } = this.props;
        if (!this.deviceUtil.isMobile()) {
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
        }
        else {
            document.body.style.overflow = 'scroll';
            document.body.style.position = 'unset';
        }

        if (!isAuthenticated) {
            history.push({
                pathname: RoutingUtil.getHomePage(),
                search: LocationUtil.handleMobilityRedirection(),
            });
        }
        if (!isAuthenticated) {
            triggerLoginDispatch({
                loginTriggered: true,
                sourceOfLoginTrigger: LOGIN_SOURCE.PREFERRED_PARTNER_PAGE,
            });
        }
        if (preferredPartnerPlans.length >= 1) {
            preferredPartnerOfferPlanId = lodashGet(preferredPartnerPlans[0], 'planId', null);
            preferredPartnerOfferPlanPopupId = lodashGet(preferredPartnerPlans[0], 'planPopupId', null);
            preferredPartnerOfferPlanRefId = lodashGet(preferredPartnerPlans[0], 'refId', null);
            this.setState({
                preferredPartnerOfferPlanId,
                preferredPartnerOfferPlanPopupId,
                preferredPartnerOfferPlanRefId,
            });
        }
    }

    getCpId = () => {
        const {
            location,
        } = this.props;
        const cpId = lodashGet(location, 'state.contentDetails.cpId', '');
        const cpId1 = lodashGet(location, 'state.cpId', '');
        const originalCpId = lodashGet(location, 'state.originalCpId', '');
        return cpId || cpId1 || originalCpId;
    }

    componentDidUpdate(prevProps) {
        const {
            preferredPartnerOfferPlanRefId, preferredPartnerOfferPlanId, cpSubscribedName,
        } = this.state;

        const {
            history, preferredPartnerPlans, isFetchingDone, location, isAuthenticated, showModalComponentActionDispatch, cpDetailsById,
        } = this.props;
        const channelPartnerLocation = lodashGet(location, 'state.contentUrl', '');
        const cpLogo = lodashGet(cpDetailsById[cpSubscribedName], 'roundLogoUrl', '');
        if (prevProps.isAuthenticated !== isAuthenticated) {
            history.push({
                pathname: RoutingUtil.getHomePage(),
                search: lodashGet(history,'location.search',''),
            });
        }

        if (isFetchingDone && prevProps.isFetchingDone !== isFetchingDone) {
            const preferredPartnerPlanOpted = preferredPartnerPlans.filter(plan => plan.refId === preferredPartnerOfferPlanRefId);
            // if (preferredPartnerPlans.length < 1) {
                this.closePopUp();
                // showModalComponentActionDispatch({
                //     showModalValue: true,
                //     componentNameValue: MODAL_POPUP.SINGLE_CLAIM_SUCCESS_POPUP,
                //     overrideCrossButtonValue: true,
                //     payload: {
                //         planId: preferredPartnerOfferPlanId,
                //         cpLogo,
                //         cpName: cpSubscribedName,
                //         preferredPartnerPlanOpted,
                //         contentUrl: channelPartnerLocation,
                //         origin: SUBSCRIPTION_SOURCE.PREFERRED_PARTNER_CHANNEL_SELECTION_PAGE,
                //         originalCpId: this.getCpId(),
                //         subscribedCpId: cpSubscribedName,
                //         productId:this.state.productId
                //     },

                // });
            // }
            // if (preferredPartnerPlans.length >= 1) {
            //     this.closePopUp();
                const { pageLink = '', title = '' } = cpDetailsById[cpSubscribedName];
                let MESSAGE = this.LANGUAGE.XMPCLAIM.CLAIM_SUCCESS_MESSAGE || 'Channel Claimed Successfully! Valid till {timestamp}.';
                const { validity } = this.state;
                if (validity) {
                    MESSAGE = MESSAGE.replace('{timestamp}',`${validity}`);
                } else {
                    MESSAGE = 'Channel Claimed Successfully!';
                }
                showToastr(MESSAGE, this.LANGUAGE.BUTTON_CLOSE);
                history.push({
                    pathname: RoutingUtil.getChannelPartnerPageUrl({
                        channelPartnerName: title,
                        pageLink,
                    }),
                    search: lodashGet(history,'location.search',''),
                });
            }
        // }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'scroll';
        document.body.style.position = 'unset';
    }

    closePopUp = () => {
        const {
             hideModalComponentActionDispatch,
        } = this.props;
        hideModalComponentActionDispatch(
            false,
            null,
            null,
        );
    }

    gotoChannelSelectionPage = (planIdValue, planPopupIdValue, planRefIdValue) => {
        const analyticsMeta = {
            source: ANALYTICS_ASSETS.XMP_CLAIM_PAGE,
            source_name: ANALYTICS_ASSETS.XMP_CLAIM_PAGE,
            asset_name: ANALYTICS_ASSETS.SUBSCRIBE,
        };
        criticalClickEvent(analyticsMeta);
        this.setState({
            preferredPartnerOfferPlanId: planIdValue,
            preferredPartnerOfferPlanPopupId: planPopupIdValue,
            preferredPartnerOfferPlanRefId: planRefIdValue,
            showYourOffersPage: false,
        });
        // setPreferredPartnerOfferPlanId(planIdValue);
        // setPreferredPartnerOfferPlanPopupId(planPopupIdValue);
        // setPreferredPartnerOfferPlanRefId(planRefIdValue);
        // setShowYourOffersPage(false);
    };

    submitSelectedPreferredPartners = (preferredPartnerOfferSelected, selectedCp, selectedCpLogo) => {
        // setShowloaderAnimation(true);
        const { preferredPartnerPlans, updatePreferredPartnerSelectionDispatch,userConfig, userConfig: { subscriptions } } = this.props;
        const { preferredPartnerOfferPlanRefId, preferredPartnerOfferPlanId } = this.state;
        // const preferredPartnerPlanOpted = preferredPartnerPlans.filter(plan => plan.refId === preferredPartnerOfferPlanRefId);
        // const referenceId = lodashGet(preferredPartnerPlanOpted[0], 'refId', null);
        let productId = '';
        let validity = '';
        if (Array.isArray(preferredPartnerOfferSelected)) {
            const _preferredPartnerOfferSelected = preferredPartnerOfferSelected[0];
            productId = _preferredPartnerOfferSelected && _preferredPartnerOfferSelected.productId;
            validity = subscriptions.find(subs => +subs.productId === productId)?.expiry;
            if (validity) {
                validity = `${getDayMonth(validity)}`;
            }
        }
        else {
            productId = preferredPartnerOfferSelected && preferredPartnerOfferSelected.productId;
            validity = subscriptions.find(subs => +subs.productId === productId)?.expiry;
            if (validity) {
                validity = `${getDayMonth(validity)}`;
            }
        }
        const preferredPartners = {
            planId: preferredPartnerOfferPlanId,
            products: [
                productId,
            ],
            referenceId: preferredPartnerOfferPlanRefId,
        };
        this.setState({
            cpSubscribedName: selectedCp,
            cpSubscribedLogo: selectedCpLogo,
            productId,
            validity
        });
        updatePreferredPartnerSelectionDispatch({ preferredPartners });
        const continueAndSubscribeButtonAnalyticsMeta = {
            source: ANALYTICS_ASSETS.PREFERRED_PARTNER_CHANNEL_SELECTION_PAGE,
            source_name: ANALYTICS_ASSETS.PREFERRED_PARTNER_CHANNEL_SELECTION_PAGE,
            asset_name: ANALYTICS_ASSETS.UNLOCK,
            channel_name: selectedCp,
            pre_selected: preferredPartnerOfferSelected ? preferredPartnerOfferSelected.preSelected : false,
            is_subscribed: !isObjEmpty(preferredPartnerOfferSelected ? preferredPartnerOfferSelected.expiries : false),
            customer_type:userConfig.customerType,
        };
        unlockNowClickEvent(continueAndSubscribeButtonAnalyticsMeta, true);
    };

    render() {
        const {
            preferredPartnerPlans, popups,
        } = this.props;
        const {
            preferredPartnerOfferPlanRefId, showYourOffersPage, preferredPartnerOfferPlanId, preferredPartnerOfferPlanPopupId,
        } = this.state;

        if (showYourOffersPage && preferredPartnerPlans.length === 0) {
            return null;
        }
        return (
            // showYourOffersPage && preferredPartnerPlans.length > 1
            //     ? (
            //         <XmpMultipleClaimContainer
            //             popups={popups}
            //             preferredPartnerPlans={preferredPartnerPlans}
            //             gotoChannelSelectionPage={this.gotoChannelSelectionPage}
            //             preferredPartnerOfferPlanId={preferredPartnerOfferPlanId}
            //         />
            //     )
            //     : (
                    <XmpSingleClaimContainer
                        preferredPartnerOfferPlanPopupId={preferredPartnerOfferPlanPopupId}
                        popups={popups}
                        submitSelectedPreferredPartners={this.submitSelectedPreferredPartners}
                        preferredPartnerPlans={preferredPartnerPlans}

                        preferredPartnerOfferPlanId={preferredPartnerOfferPlanId}
                        preferredPartnerOfferPlanRefId={preferredPartnerOfferPlanRefId}
                    />
                )
        // );
    }
}
const mapStateToProps = (state, props) => {
    const { match } = props;
    const {
        allPlans,
        authConfig: { isAuthenticated },
        appConfig,
        userConfig,
        popupConfig,
        notify,
    } = state;
    const browsePlans = lodashGet(allPlans, 'browsePlans', []);
    const isFetchingDone = lodashGet(notify, 'isFetchingDone', null);
    const cpDetailsById = lodashGet(appConfig, 'cpDetailsById', {});
    const ChannelPartnerImageData = lodashGet(appConfig, 'xstreamPremiumData.partnersList', {});
    const preferredPartnerPlans = lodashGet(userConfig, 'preferredPartnerPlans', []);
    const popups = lodashGet(popupConfig, 'allPopUps', null);
    
    return {
        browsePlans,
        isAuthenticated,
        ChannelPartnerImageData,
        preferredPartnerPlans,
        cpDetailsById,
        popups,
        isFetchingDone,
        userConfig
    };
};
XmpClaimContainer.propTypes = {
    channelpartner: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    triggerLoginDispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    browsePlans: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types,
    // eslint-disable-next-line react/forbid-prop-types
    ChannelPartnerImageData: PropTypes.array.isRequired,
};
export default connect(mapStateToProps, {
    triggerLoginDispatch: triggerLogin,
    updatePreferredPartnerSelectionDispatch: updatePreferredPartnerSelections,
    hideModalComponentActionDispatch: hideModalComponentAction,
    showModalComponentActionDispatch: showModalComponentAction,
})(withDeviceUtil(withRouter(withStyles(styles)(XmpClaimContainer))));
