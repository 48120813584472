import React, { PureComponent, Suspense } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { ROUTE_PATHS_SETTINGS, WEBVIEW_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';

// import ActiveXppAlaCarteComponent from '../component/ActiveXppAlaCarteComponent';
// import FMFRenewBanner from '@airtel-feature/subscription-offers/components/FMFRenewalBanner';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { LanguageProviderUtil, LocationUtil } from '@airtel-tv/utils';
import { pageLoadEvent } from '@airtel-tv/analytics/FunctionalEvents';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { AnalyticsButtonComponent, AnalyticsLinkComponent } from '@airtel-tv/analytics';
import { ARIA_LABEL, IMAGE_PATHS, LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import ArrowSVGComponent from '@airtel-tv/ui-lib/svg/ArrowSVGComponent';
import InfoIcon from '@airtel-tv/svg/InfoIcon';
import { ANALYTICS_ACTIONS } from '@airtel-tv/constants/AnalyticsConst';
import { filterChannelAndRecharge, filterSubscribedPlans } from '../../../utilities/CommonUtil';
import PlansAndOffersContainer from './PlansAndOffersContainer';
import { getCurrentUrl } from '../../../utilities/WindowUtil';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import LazyIconFatory, { ICON_MAP } from '../component/iconBuilder/iconFactory';
import styles from './SubscriptionContainer.scss';
import ActiveXstreamPlanComponent from '../component/ActiveXstreamPlanComponent';
import { purchaseSubscriptionInit } from '../action/SubscriptionAction';
import { showNonAirtelUserAction } from '../../user/UserAction';


class ActivePlansContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.LANGUAGE = LanguageProviderUtil.getLanguage();
        this.state = {
        };
        this.appId = browserStore.get(LOCAL_STORE_KEYS.APP_ID);
        this.os = browserStore.get(LOCAL_STORE_KEYS.OS);
    }


    componentDidMount() {
        const {
            circle, sid, userConfig, uid,
        } = this.props;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: ANALYTICS_ASSETS.ACTIVE_PLANS,
            ingressIntent: ANALYTICS_ASSETS.ACTIVE_PLANS,
            source: ANALYTICS_ASSETS.ACTIVE_PLANS,
            pageName: ANALYTICS_ASSETS.ACTIVE_PLANS,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type: userConfig.customerType,
            uid,
        };
        pageLoadEvent(pageLoadAnalyticsMeta, true);
    }

    getXStreamDetail = subscribedPlans => subscribedPlans.find(plans => plans.meta && plans.meta.offerType === 'PREMIUM');


    // plan.combo


    navigateToHelp = (_) => {
        if (this.appId === 'MOBILITY') {
            window.open(WEBVIEW_PATHS.FEEDBACK, '_self');
        }
        else {
            this.props.history.push({
                pathname: ROUTE_PATHS_SETTINGS.SETTINGS_HELP_FAQ_SECTION,
                search: LocationUtil.handleMobilityRedirection(),
            });
        }
    };

    goBack = (_) => {
        const { history, origin } = this.props;
        if (this.appId === 'MOBILITY') {
            if (!origin) {
                LocationUtil.handleCloseAppstate();
                return;
            }
            history.go(-1);
            return;
        }
        history.go(-1);
    };

    render() {
        const {
            allPlans, myPlanPageText = {}, userConfig, allPlans: { browsePlans = [], rechargePlans = [], xppPlans: { plans: xppPlans = [] } = {} },
        } = this.props;
        const { recharge = '', channels = '' } = myPlanPageText;
        const { fmfConfig = null } = userConfig || {};
        // const recharge = lodashGet(myPlanPageText, 'recharge', '');
        // const channels = lodashGet(myPlanPageText, 'channels', '');
        const subscribedPlans = filterSubscribedPlans(allPlans && allPlans.subscribedPlans);
        const plans = filterChannelAndRecharge(subscribedPlans);
        const showSeeOtherPlan = rechargePlans.length || xppPlans?.length;
        const rechargePlan = plans.true;
        const alaCartePlans = plans.false;
        const { GO_TO_PREV_PAGE = 'Go to previous page' } = ARIA_LABEL;
        const {
            MY_PLANS_TNC = { ad_tnc: '* Ads will be shown on Sony Sports, Sony Liv Exclusive Content and Reality Shows by Sony LIV.' },
            SEE_OTHER_PLANS = 'See Other Plans',
            HELP = 'Help',
            PLANS_AND_OFFERS_LABEL = 'Plans & Offers',
        } = this.LANGUAGE;
        const xstreamPremium = this.getXStreamDetail(subscribedPlans);
        return (
            <section className="my-plans-container">
                <div className="active-plan-container">
                    <div className="active-plan-cont-wrap">

                        <div className="active-action-wrapper">
                            <AnalyticsButtonComponent
                                className="active-back-button"
                                onClick={this.goBack}
                                aria-label={GO_TO_PREV_PAGE}
                                meta={{
                                    asset_name: ANALYTICS_ACTIONS.BACK_BUTTON_CLICK,
                                    action: ANALYTICS_ACTIONS.BACK_BUTTON_CLICK,
                                }}
                            >
                                <LazyIconFatory iconName={ICON_MAP.CARET_DOWN} />
                            </AnalyticsButtonComponent>

                            { this.os && this.os.toLowerCase() === 'ios' ? '' : (
                                <AnalyticsButtonComponent
                                    className="active-help-cta d-flex align-items-center"
                                    onClick={this.navigateToHelp}
                                    aria-label="Help"
                                >
                                    <span>{HELP}</span>
                                    <InfoIcon />
                                    <LazyIconFatory iconName={ICON_MAP.HELP} />
                                </AnalyticsButtonComponent>
                            )
                            }
                        </div>
                        <p className="page-heading">{PLANS_AND_OFFERS_LABEL}</p>

                        {/* { fmfConfig && (
                            <FMFRenewBanner
                                fmfConfig={fmfConfig}
                                {...this.props}
                            />
                        )} */}
                        {xstreamPremium ? (
                            <ActiveXstreamPlanComponent
                                xStreamPremium={xstreamPremium}
                                showSeeOtherPlan={showSeeOtherPlan}
                            />
                        ) : ''
                        }
                        <p className="ad_tnc">{MY_PLANS_TNC?.ad_tnc}</p>
                    </div>

                    { this.appId && this.appId === 'MOBILITY'
                        ? <PlansAndOffersContainer /> : <div />}

                </div>
            </section>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        planConfig,
        userConfig,
        userConfig: { userInfo },
        appConfig: { myPlanPageText = {}, serverEpoch },
    } = state;
    const { data: combinedPlansData = {}, sid = '', userDetails = {} } = planConfig || {};
    const { circle = '' } = userDetails;
    const { uid = '' } = userInfo || {};
    return {
        combinedPlansData,
        userConfig,
        allPlans: planConfig,
        sid,
        circle,
        myPlanPageText,
        uid,
        serverEpoch,
    };
};
ActivePlansContainer.propTypes = {
    channelpartner: PropTypes.string,
    combinedPlansData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-type
};
export default connect(mapStateToProps,
    {
        purchaseSubscriptionInitDispatch: purchaseSubscriptionInit,
        showNonAirtelUserAction,
    })(withDeviceUtil(withRouter(withStyles(styles)(ActivePlansContainer))));
