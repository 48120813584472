/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import withStyles from 'isomorphic-style-loader/withStyles';
// import { getDate } from '../../../../utilities/CommonUtil';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
// import { FORMAT_TYPE } from '../../../../constants/DateTimeConst';
import { hideModalComponentAction } from '../../../modal-popup/ModalPopupActions';
import { IMAGE_PATHS } from '../../../../constants/AppConst';
import { stopAutoRenewInit } from '../../action/SubscriptionAction';
import styles from './style.scss';
import { Analytics, AnalyticsButtonComponent } from '@airtel-tv/analytics';

function StopAutoRenewPlanComponent(props) {
    const [
        isConfirmed,
        handleConfirm,
    ] = useState(false);
    const { appConfig, plan, hideModalComponentActionDispatch } = props;
    const planId = lodashGet(plan, 'planId', '');
    // const date = lodashGet(plan, 'validTill', '') ? getDate(new Date(plan.validTill), FORMAT_TYPE.DATE_WITH_ORDINAL_FORMAT) : '';
    function stopAutoRenewHandler() {
        const { stopAutoRenewInitDispatch } = props;
        const successText = lodashGet(appConfig, 'web_en.STOP_AUTO_RENEW.SUCCESS_TEXT', 'Auto Renew has been stopped');
        stopAutoRenewInitDispatch({
            planId,
            successText,
        });
    }
    return (
        <div className="stop-auto-renew-wrapper">
            {
                !isConfirmed
                    ? (
                        <>
                            <div className="stop-container">
                                <h1 className="">
                                    {lodashGet(appConfig.web_en, 'STOP_AUTO_RENEW.TITLE', 'Are you sure you want to unsubscribe?')}
                                </h1>
                                {/* <p className="stop-container-desc">
                                    {lodashGet(appConfig.web_en, 'STOP_AUTO_RENEW.AUTO_RENEW_LABEL', 'Auto renew is on')}
                                    {' '}
                                </p>
                                <p>{`${lodashGet(appConfig, 'web_en.STOP_AUTO_RENEW.NEXT_BILLING_LABEL', 'Next Billing')} ${date}`}</p> */}
                            </div>
                            <AnalyticsButtonComponent
                                className="stop-auto-renew-confirmation-wrapper"
                                onClick={() => handleConfirm(true)}
                                meta={{
                                    action: 'cancel_auto_renew',
                                    source_name: 'more',
                                }}
                            >
                                <ThumborImage
                                    src={IMAGE_PATHS.CROSS}
                                    alt="cross"
                                />
                                <span>{lodashGet(appConfig, 'web_en.STOP_AUTO_RENEW.STOP_LABEL', 'Unsubscribe Now')}</span>
                            </AnalyticsButtonComponent>
                        </>
                    )
                    : (
                        <div className="stop-confirmation-container">
                            <h1 className="">
                                {lodashGet(appConfig, 'web_en.STOP_AUTO_RENEW.STOP_LABEL', 'Cancel Auto Renew?')}
                            </h1>
                            <p>{lodashGet(appConfig, 'web_en.STOP_AUTO_RENEW.DESCRIPTION', "Your subscription will not be auto renewed once it expires. You'll have to renew it manually.")}</p>
                            <div className="submit-btns">
                                <AnalyticsButtonComponent
                                    meta={{
                                        action: 'cancel_now',
                                        source_name: 'more',
                                    }}
                                    onClick={stopAutoRenewHandler}
                                >
                                    {lodashGet(appConfig, 'web_en.STOP_AUTO_RENEW.PROCEED_LABEL', 'Cancel Now')}
                                </AnalyticsButtonComponent>
                                <AnalyticsButtonComponent
                                    meta={{
                                        action: 'dont_cancel',
                                        source_name: 'more',
                                    }}
                                    onClick={() => hideModalComponentActionDispatch({
                                        showModalValue: false,
                                        componentNameValue: null,
                                        overrideCrossButtonValue: null,
                                    })}
                                >
                                    {lodashGet(appConfig, 'web_en.STOP_AUTO_RENEW.CANCEL_LABEL', 'Don’t Cancel')}
                                </AnalyticsButtonComponent>
                            </div>
                        </div>
                    )
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    const { appConfig } = state;
    return {
        appConfig,
    };
};

export default connect(mapStateToProps, {
    hideModalComponentActionDispatch: hideModalComponentAction,
    stopAutoRenewInitDispatch: stopAutoRenewInit,
})(withStyles(styles)(StopAutoRenewPlanComponent));
