/* eslint-disable react/button-has-type */
import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import { PropTypes } from 'prop-types';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { connect } from 'react-redux';
import { osVersion } from 'react-device-detect';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { BANNER_INTERVAL } from '@airtel-tv/constants/LayoutConstants';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { CHANNEL_PARTNER } from '../../../constants/SubscriptionConst';
import { IMAGE_PATHS } from '../../../constants/ImagesConst';
import { paginatedSubscriptionLayoutFetchAction } from '@airtel-feature/layout/actions/LayoutActions';
import SubscriptionLoaderComponent from '../../shared/components/loader/SubscriptionLoaderComponent';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { LocationUtil } from '@airtel-tv/utils';
import LazyIconFatory, { ICON_MAP } from './iconBuilder/iconFactory';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import { ARIA_LABEL, LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';

const IMAGE_SETTINGS = {
    FLOATING_LARGE: {
        1000: 1000,
        320: 300,
    },
    CP_IMAGES: {
        1000: 100,
        500: 100,
        320: 100,
    },
};


class SubscriptionHeader extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isCursorOnRail: false,
            widthToAdd: 0,
            dragScrollTimerId: 0,
            scrollIntervalId: 0,
            isRailRendered: false,
        };
        this.deviceUtil = props.deviceUtil;
        this.carouselElementRef = React.createRef();
        this.carouselTimer = setTimeout(this.slideBannerInterval, BANNER_INTERVAL.NEXT_TILE - BANNER_INTERVAL.TRANSITION_DURATION);
        this.appId = browserStore.get(LOCAL_STORE_KEYS.APP_ID);
    }

    checkIfIosMobility = () => {
        const os = browserStore.get(LOCAL_STORE_KEYS.OS);
        if (this.deviceUtil.isIOS() && this.deviceUtil.isMobile() && parseFloat(osVersion).toFixed(2) >= parseFloat(15.4).toFixed(2)) {
            return true;
        } if (this.deviceUtil.isMobile() && os && os.toLowerCase() !== 'android' && parseFloat(osVersion).toFixed(2) >= parseFloat(15.4).toFixed(2)) {
            return true;
        } if (os && os.toLowerCase() === 'ios' && parseFloat(osVersion).toFixed(2) >= parseFloat(15.4).toFixed(2)) {
            return true;
        }
        return false;
    }

    next=(onPress) => {
        this.setState({ isRailRendered: true });
        let { widthToAdd } = this.state;
        const { channelpartner } = this.props;
        if (this.carouselElementRef.current) {
            const { clientWidth } = this.carouselElementRef.current;
            const images = this.carouselElementRef.current.children;
            if (channelpartner && channelpartner !== CHANNEL_PARTNER.XSTREAMPREMIUM) {
                const tileWidth = images[0]?.children?.[0]?.children[0]?.offsetWidth + 10;
                widthToAdd = onPress ? tileWidth + 10 : this.checkIfIosMobility() ? 26 : 2.6;

                this.carouselElementRef.current.scrollLeft += widthToAdd;
                const widthToSub = (tileWidth) * 10;
                let widthForReset = 0;
                for (const image of images[0].children[0].children) {
                    widthForReset += (image.offsetWidth + 10);
                }
                widthForReset -= widthToSub;
                if (this.carouselElementRef.current.scrollLeft >= widthForReset) {
                    this.carouselElementRef.current.style['scroll-behavior'] = 'auto';
                    widthToAdd = this.carouselElementRef.current.scrollLeft - widthForReset;
                    this.carouselElementRef.current.scrollLeft = widthToAdd + (tileWidth) * 5;
                    this.setState({ widthToAdd });
                    this.carouselElementRef.current.style['scroll-behavior'] = 'smooth';
                    if (!this.checkIfIosMobility()) {
                        this.next(onPress);
                    }
                }
            }
            else if (images && images.length > 0) {
                widthToAdd = onPress ? clientWidth / 4 : this.checkIfIosMobility() ? 26 : 2.6;
                this.carouselElementRef.current.scrollLeft += widthToAdd;
                const widthToSub = images?.[images.length - 1]?.offsetWidth + images[images.length - 2]?.offsetWidth + images?.[images.length - 3]?.offsetWidth + 50;
                let widthForReset = 0;
                for (const image of images) {
                    widthForReset += (image?.offsetWidth + 10);
                }
                widthForReset -= widthToSub;
                if (this.carouselElementRef.current.scrollLeft >= widthForReset) {
                    this.carouselElementRef.current.style['scroll-behavior'] = 'auto';
                    widthToAdd = this.carouselElementRef.current.scrollLeft - widthForReset + 20;
                    this.carouselElementRef.current.scrollLeft = widthToAdd;
                    this.setState({ widthToAdd });
                    this.carouselElementRef.current.style['scroll-behavior'] = 'smooth';
                    if (!this.checkIfIosMobility()) {
                        this.next(onPress);
                    }
                }
            }
        }
    }

     goBack = () => {
         const { history, origin } = this.props;
         const appId = browserStore.get(LOCAL_STORE_KEYS.APP_ID);
         const ISMOBILITY = browserStore.get(LOCAL_STORE_KEYS.MOBILITYOS);

         if (appId === 'MOBILITY' || ISMOBILITY) {
             if (!origin) {
                 LocationUtil.handleCloseAppstate();
                 return;
             }
             history.go(-1);
             return;
         }
         history.go(-1);
     }

     init = () => {
         const { layoutFetchActionDispatch, channelpartner, railAutoScrollInterval } = this.props;
        if(!(location.pathname === ROUTE_PATHS.MY_PLANS && this.appId === 'MOBILITY')) {
            layoutFetchActionDispatch({
                pageId: 'purchaseSubscriptionBanner',
                cpId: channelpartner,
            });
        }
         if (this.carouselElementRef.current) {
             setTimeout(() => {
                 const scrollIntervalId = setInterval(() => {
                     if (!this.state.isCursorOnRail) {
                         this.next(false);
                     }
                 }, railAutoScrollInterval);
                 this.setState({ scrollIntervalId });
             }, 1000);
             this.carouselElementRef.current.addEventListener('touchmove', () => {
                 this.setState({ isCursorOnRail: true });
                 window.clearTimeout(this.state.dragScrollTimerId);
             });

             this.carouselElementRef.current.addEventListener('touchend', () => {
                 const dragScrollTimerId = setTimeout(() => {
                     this.setState({ isCursorOnRail: false });
                     this.next(false);
                     this.setState({ dragScrollTimerId });
                 }, 1500);
             });
         }
     }

     componentDidMount() {
         this.init();
     }

     componentWillUnmount() {
         clearInterval(this.state.scrollIntervalId);
         clearTimeout(this.state.dragScrollTimerId);
         if (this.carouselElementRef.current) {
             this.carouselElementRef.current.removeEventListener('touchmove', () => {});
             this.carouselElementRef.current.removeEventListener('touchend', () => {});
         }
     }

    previous = () => {
        let { widthToAdd } = this.state;
        const { channelpartner } = this.props;
        if (this.carouselElementRef.current) {
            const { clientWidth } = this.carouselElementRef.current;
            const images = this.carouselElementRef.current.children;
            const isXSTREAMPREMIUM = channelpartner === CHANNEL_PARTNER.XSTREAMPREMIUM
            if (!isXSTREAMPREMIUM) {
                const tileWidth = images?.[0]?.children?.[0]?.children?.[0]?.offsetWidth + 10;
                this.carouselElementRef.current.scrollLeft -= tileWidth;
                let widthForReset = tileWidth * 7;
                if (this.carouselElementRef.current.scrollLeft <= widthForReset) {
                    const diff = widthForReset - this.carouselElementRef.current.scrollLeft;
                    this.carouselElementRef.current.style['scroll-behavior'] = 'auto';
                    for (const image of images[0].children[0].children) {
                        widthForReset += (image.offsetWidth + 10);
                    }
                    this.carouselElementRef.current.scrollLeft = widthForReset - tileWidth * 7 + diff;
                    this.setState({ widthToAdd });
                    this.carouselElementRef.current.style['scroll-behavior'] = 'smooth';
                    this.previous();
                }
            }
            else if (images && images.length > 0) {
                widthToAdd = clientWidth / 4;
                this.carouselElementRef.current.scrollLeft -= widthToAdd;
                const widthOfFirstImage = images[0].offsetWidth;
                if (this.carouselElementRef.current.scrollLeft <= widthOfFirstImage) {
                    let widthForReset = 0;
                    const widthToSub = widthOfFirstImage - this.carouselElementRef.current.scrollLeft;
                    for (const image of images) {
                        widthForReset += (image.offsetWidth + 10);
                    }
                    const widthOfLastThreeImages = images[images.length - 1].offsetWidth + images[images.length - 2].offsetWidth + 20;
                    this.carouselElementRef.current.style['scroll-behavior'] = 'auto';
                    this.carouselElementRef.current.scrollLeft = widthForReset - widthToSub - widthOfLastThreeImages + 20;
                    this.carouselElementRef.current.style['scroll-behavior'] = 'smooth';
                    this.previous();
                }
            }
        }
    }


      filterbanners = (purchaseSubscriptionBanner) => {
          const _purchaseSubscriptionBanner = [];
          purchaseSubscriptionBanner.forEach((banner) => {
              const img = banner.images.RECTANGULAR || banner.images.PORTRAIT_HD || banner.images.PORTRAIT;
              if (img) {
                  _purchaseSubscriptionBanner.push(img);
              }
          });
          return _purchaseSubscriptionBanner;
      }

      getImageTagsForNonPremium(topSliderImages) {
          const images = {
              top: [],
              bottom: [],
          };
          topSliderImages.forEach((item, index) => {
              if (index % 2 === 0) {
                  images.top.push(<ThumborImage
                      src={item}
                      imageSettings={IMAGE_SETTINGS.CP_IMAGES}
                  />);
              }
              else {
                  images.bottom.push(<ThumborImage
                      src={item}
                      imageSettings={IMAGE_SETTINGS.CP_IMAGES}
                  />);
              }
          });
          if (images.top.length < 7 && images.top.length > 0) {
              while (images.top.length < 7) {
                  const diff = 7 - images.top.length;
                  images.top = [
                      ...images.top,
                      ...images.top.slice(0, diff),
                  ];
              }
          }

          if (images.bottom.length < 7 && images.bottom.length > 0) {
              while (images.bottom.length < 7) {
                  const diff = 7 - images.bottom.length;
                  images.bottom = [
                      ...images.bottom,
                      ...images.bottom.slice(0, diff),
                  ];
              }
          }
          const lastTopImages = images.top.slice().reverse().slice(0, 7).reverse();
          const lastBottomImages = images.bottom.slice().reverse().slice(0, 7).reverse();

          const initialTopImages = images.top.slice(0, 7);
          const initialBottomImages = images.bottom.slice(0, 7);

          images.top = [
              ...lastTopImages,
              ...images.top,
              ...initialTopImages,
          ];
          images.bottom = [
              ...lastBottomImages,
              ...images.bottom,
              ...initialBottomImages,
          ];
          return images;
      }

    railRendered = () => {
        const images = this.carouselElementRef.current.children;
        const { isRailRendered } = this.state;
        const { channelpartner } = this.props;
        if (channelpartner === CHANNEL_PARTNER.XSTREAMPREMIUM && images && images.length > 0 && !isRailRendered) {
            this.carouselElementRef.current.style['scroll-behavior'] = 'auto';
            this.carouselElementRef.current.scrollLeft = images[0].offsetWidth + 10;
            this.carouselElementRef.current.style['scroll-behavior'] = 'smooth';
        }
    }

    render() {
        let {
            channelData, xstreamPremiumData, channelpartner, purchaseSubscriptionBanner, cpDetailsById, location,
        } = this.props;
        const layoutData = lodashGet(purchaseSubscriptionBanner, `purchaseSubscriptionBanner:${channelpartner}.layoutPackages[0].contents[0].details.content`, []);
        purchaseSubscriptionBanner = this.filterbanners(layoutData);
        if (!channelpartner) {
            channelpartner = lodashGet(location, 'state.cpId', '');
        }

        const data = channelData && channelData.length && channelData[0];
        const description = lodashGet(data, 'description', '');
        const descriptions = description && description.split('|');
        const partnerImage = lodashGet(cpDetailsById[channelpartner], 'logoUrl', '');
        let topSliderImages = purchaseSubscriptionBanner || xstreamPremiumData;
        const firstImages = topSliderImages.length > 2 ? topSliderImages.slice(0, 2) : topSliderImages;
        const lastImage = topSliderImages.slice(-1);
        if (channelpartner === CHANNEL_PARTNER.XSTREAMPREMIUM) {
            topSliderImages = [
                ...lastImage,
                ...topSliderImages,
                ...firstImages,
            ];
        }
        else if (topSliderImages.length % 2 !== 0) {
            topSliderImages.pop();
        }
        let images = this.getImageTagsForNonPremium(topSliderImages);
        if (images.length === 0) {
            images = xstreamPremiumData;
        }
        if (!channelpartner) {
            return <SubscriptionLoaderComponent />;
        }
        const { GO_TO_PREV_PAGE = "Go to Previous Page", SCROLL_RAIL_LEFT = "Scroll rail left", SCROLL_RAIL_RIGHT = "Scroll rail right" } = ARIA_LABEL;
        return (
            <section className="header-wrapper back-button-aligner">
                {
                    location.pathname !== ROUTE_PATHS.PLANS_AND_OFFERS && location.pathname !== ROUTE_PATHS.MY_PLANS
                      && (
                          <AnalyticsButtonComponent
                              className="no-subscribe-back"
                              onClick={this.goBack}
                              aria-label={GO_TO_PREV_PAGE}
                          >
                              <LazyIconFatory iconName = {ICON_MAP.CARET_DOWN} />
                          </AnalyticsButtonComponent>
                      )
                }

                {channelpartner === CHANNEL_PARTNER.XSTREAMPREMIUM ? (
                    <img
                        className="xstream-logo"
                        src={IMAGE_PATHS.XSTREAMPREMIUM}
                        alt=""
                        loading="lazy"
                    />
                ) : (
                    <ThumborImage
                        className="non-xstream-logo"
                        src={partnerImage}
                        imageSettings={IMAGE_SETTINGS.FLOATING_LARGE}
                    />
                )}
                {channelpartner !== CHANNEL_PARTNER.XSTREAMPREMIUM ? (
                    <p className="bold-title-xpp">Get Unlimited Access to</p>
                ) : ''}

                <p className="tags tags-margin">
                    {descriptions && descriptions.length && descriptions.map(desc => (
                        <span>{desc}</span>
                    ))}

                </p>
                <div
                    className="intro-rail"
                    onMouseOver={() => this.setState({ isCursorOnRail: true })}
                    onMouseLeave={() => this.setState({ isCursorOnRail: false })}
                >

                    <AnalyticsButtonComponent
                        className="slide-btn left"
                        onClick={this.previous}
                        aria-label={SCROLL_RAIL_LEFT}
                    >
                        <div className="chev-background">
                            <ThumborImage
                                src={IMAGE_PATHS.CHEV_LEFT}
                            />
                        </div>

                    </AnalyticsButtonComponent>
                    <div
                        className="rail-slider"
                        ref={this.carouselElementRef}
                        onLoad={this.railRendered}
                    >
                        {channelpartner === CHANNEL_PARTNER.XSTREAMPREMIUM && (
                            topSliderImages && topSliderImages.length && topSliderImages.map((img,index) => (
                                <ThumborImage
                                    src={img}
                                    imageSettings={IMAGE_SETTINGS.FLOATING_LARGE}
                                />
                            ))
                        )}

                        {
                            channelpartner !== CHANNEL_PARTNER.XSTREAMPREMIUM
                            && (
                                <div className="double-rail-wrapper">
                                    <div className="slider-top">
                                        {images.top}
                                    </div>
                                    <div className="slider-bottom">
                                        {images.bottom}
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    <AnalyticsButtonComponent
                        className="slide-btn right"
                        onClick={this.next}
                        aria-label={SCROLL_RAIL_RIGHT}
                    >
                        <div className="chev-background">
                            <ThumborImage
                                src={IMAGE_PATHS.CHEV_LEFT}
                            />
                        </div>
                    </AnalyticsButtonComponent>
                </div>
            </section>
        );
    }
}
SubscriptionHeader.propTypes = {
    channelData: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    xstreamPremiumData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const mapStateToProps = (state, props) => {
    const {
        layoutDetails, appConfig,
    } = state;
    const cpDetailsById = lodashGet(appConfig, 'cpDetailsById', {});
    const railAutoScrollInterval = lodashGet(appConfig, 'railAutoScrollInterval', 50);

    return {
        purchaseSubscriptionBanner: layoutDetails,
        cpDetailsById,
        railAutoScrollInterval,
    };
};


export default connect(mapStateToProps, {
    layoutFetchActionDispatch: paginatedSubscriptionLayoutFetchAction,
})(withDeviceUtil(withRouter(SubscriptionHeader)));
