import React, { PureComponent } from 'react';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import SubscriptionHeader from './SubscriptionHeader';
import RoutingUtil from '../../../utilities/RoutingUtil';
import { showNonAirtelUserAction } from '../../user/UserAction';
import { getCurrentUrl } from '../../../utilities/WindowUtil';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { seePlansXppEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { LocationUtil } from '@airtel-tv/utils';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';

const tileOffset = 2;
class XStreamPremiumComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dragging: '',
            refresh: false,
            currentIndex: tileOffset,
        };
    }

    onSeeAllPlansClick = () => {
        const { xStreamPremuimPlan, showNonAirtelUserAction,uid } = this.props;
        const {
            history, sid, circle, userConfig, location, navigate
        } = this.props;
        const id = lodashGet(xStreamPremuimPlan, 'id', '');
        const channelpartner = lodashGet(xStreamPremuimPlan, 'cpId', '');
        const pathname = lodashGet(location, 'pathname', '');
        let source;
        if (pathname === '/subscribe/my-account') {
            source = ANALYTICS_ASSETS.MY_PLANS_PAGE;
        }
        else {
            source = ANALYTICS_ASSETS.EXPLORE_PLANS_PAGE;
        }
        const offerSelectionAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            offerId: id,
            offerTitle: channelpartner,
            customer_type: userConfig.customerType,
            active_tab: ANALYTICS_ASSETS.SUBSCRIBED_PACKS,
            source,
            ingressIntent: source,
            pageName: ANALYTICS_ASSETS.PLANS_AND_OFFERS,
            service: ANALYTICS_ASSETS.AIRTELTV,
            uid
        };
        seePlansXppEvent(offerSelectionAnalyticsMeta);

        if (userConfig.customerType === 'NON_AIRTEL') {
            showNonAirtelUserAction({cpId:channelpartner});
        }
        else {
            history.push({
                pathname: RoutingUtil.getSubscriptionPage(),
                search: lodashGet(location, 'search', ''),
                state: {
                    origin: ANALYTICS_ASSETS.PLANS_AND_OFFERS,
                },
            });
        }
    };


    render() {
        const { xStreamPremuimPlan } = this.props;
        let lowestPlan = xStreamPremuimPlan.plans.reduce((prev, curr) => (prev.price < curr.price ? prev : curr));
        lowestPlan = `Plans starting @ \u20B9${lowestPlan.price}`;
        // filterXtreamPremium()
        return (
            <section className="xstream-premium-rail-wrapper">
                <p className="xstream-premium-title">Recommended</p>
                <div className="xstream-premium-wrapper white-glow-shade">


                    {/* image start */}
                    <SubscriptionHeader
                        xstreamPremiumData={this.props.xstreamPremiumData}
                        channelpartner={xStreamPremuimPlan.cpId}
                        channelData={[
                            xStreamPremuimPlan,
                        ]}
                    />

                    {/* image end */}

                    <p className="plan-pricing">{lowestPlan}</p>
                    <AnalyticsButtonComponent
                        className="see-plan-btn common-white-btn"
                        onClick={this.onSeeAllPlansClick}
                        aria-label="See all plans"
                    >
                        See all plans
                    </AnalyticsButtonComponent>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        allPlans,
        appConfig,
        planConfig,
        userConfig,
    } = state;
    const xstreamPremiumData = lodashGet(appConfig, 'subscriptionData.xstreamPremium', {});
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const uid = lodashGet(userConfig, 'userInfo.uid', '');
    return {
        allPlans,
        userConfig,
        xstreamPremiumData,
        sid,
        circle,
        uid
    };
};
export default connect(mapStateToProps, { showNonAirtelUserAction })(withDeviceUtil(withRouter(XStreamPremiumComponent)));
