import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { PropTypes } from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import RoutingUtil from '../../../utilities/RoutingUtil';
import styles from './SubscriptionContainer.scss';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import XmpPlanCardComponent from '../component/XmpPlanCardComponent';
import { LocationUtil } from '@airtel-tv/utils';
import { getCurrentUrl } from '../../../utilities/WindowUtil';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { onCancelClickXmpPage, pageLoadEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import LanguageProvider from '../../../providers/LanguageProvider';
import env from '../../../config';

class XmpMultipleClaimContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            planId: null,
            planPopupId: null,
            preferredPartnerOfferPlanRefId: null,

        };
        this.LANGUAGE = LanguageProvider();
    }

    pageinitEvent() {
        const { circle, sid,userConfig,uid } = this.props;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: ANALYTICS_ASSETS.OFFERS_LISTING_PAGE,
            source: ANALYTICS_ASSETS.OFFERS_LISTING_PAGE,
            pageName: ANALYTICS_ASSETS.OFFERS_LISTING_PAGE,
            ingressIntent: ANALYTICS_ASSETS.OFFERS_LISTING_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type:userConfig.customerType,
            uid
        };
        pageLoadEvent(pageLoadAnalyticsMeta, true);
    }

    componentDidMount() {
        const { preferredPartnerPlans } = this.props;
        let { planId, planPopupId, preferredPartnerOfferPlanRefId } = this.state;
        if (preferredPartnerPlans.length) {
            planId = lodashGet(preferredPartnerPlans[0], 'planId', null);
            planPopupId = lodashGet(preferredPartnerPlans[0], 'planPopupId', null);
            preferredPartnerOfferPlanRefId = lodashGet(preferredPartnerPlans[0], 'refId', null);
            this.setState({
                planId,
                planPopupId,
                preferredPartnerOfferPlanRefId,
            });
            // setPreferredPartnerOfferPlanId(lodashGet(preferredPartnerPlans[0], 'planId', null));
            // setPreferredPartnerOfferPlanPopupId(lodashGet(preferredPartnerPlans[0], 'planPopupId', null));
            // setPreferredPartnerOfferPlanRefId(lodashGet(preferredPartnerPlans[0], 'refId', null));
        }
        this.pageinitEvent();
    }

    redirectToHome = () => {
        const { location, circle, sid ,userConfig,uid,history} = this.props;
        const contentUrl = lodashGet(location, 'state.contentUrl', '');
        const isMobilityOS = browserStore
            .get(LOCAL_STORE_KEYS.MOBILITYOS);

        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            source: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            ingressIntent: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            pageName: ANALYTICS_ASSETS.PREFERRED_PARTNER_OFFERS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type:userConfig.customerType,
            uid
        };
        onCancelClickXmpPage(pageLoadAnalyticsMeta, true);
        const appId = LocationUtil.getKeyFromURL(window.location.search,'appId') || browserStore.get(LOCAL_STORE_KEYS.APP_ID) || env.appId;
        const isMobility = appId === 'MOBILITY';
    
        if(isMobility || isMobilityOS){
            LocationUtil.handleCloseAppstate();
            return;
        }
        if (contentUrl) {
            history.push({
                pathname: contentUrl,
                search: lodashGet(history,'location.search',''),
            });
        }
        else {
            history.push({
                pathname: RoutingUtil.getHomePage(),
                search: lodashGet(history,'location.search',''),
            });
        }
    }

    render() {
        const {
            preferredPartnerPlans, gotoChannelSelectionPage,
        } = this.props;
        const {XMPCLAIM : {MULTIPLE_CLAIM_TITLE = '', MULTIPLE_CLAIM_DESCRIPTION = '', MULTIPLE_CTA_TEXT = '', MULTIPLE_CLAIM_BUTTON_LABEL = ''}} = this.LANGUAGE;

        const isMobility = browserStore
            .get(LOCAL_STORE_KEYS.APP_ID);
        
        const preferredPartnerPlansOfferSelection = preferredPartnerPlans.map((preferredPartnerPlansOfferItem, index) => (
            <XmpPlanCardComponent
                key={`${preferredPartnerPlansOfferItem.planId}-${index}`}
                gotoChannelSelectionPage={gotoChannelSelectionPage}
                preferredPartnerPlansOfferItemPlanId={preferredPartnerPlansOfferItem.planId}
                preferredPartnerPlansOfferItemPlanPopupId={preferredPartnerPlansOfferItem.planPopupId}
                preferredPartnerPlansOfferItemRefId={preferredPartnerPlansOfferItem.refId}
                buttonLabel={MULTIPLE_CLAIM_BUTTON_LABEL}
                {...this.props}
            />
        ));

        return (

            <div className="xmp-claim-wrapper">
                <div className="xmp-claim-container xmp-multi-claim-container">
                    <div className="xmp-gredient-top-layer" />
                    <div className="top-header-wrapper-scroll">
                        <button
                            className="claim-cross"
                            onClick={() => {
                                this.redirectToHome();
                            }}
                        >
                            <ThumborImage
                                src="/static/close.svg"
                            />
                        </button>

                        <p className="cong-text multi-channel-cong-text">{MULTIPLE_CLAIM_TITLE}</p>
                        <p className="free-channel-text">{MULTIPLE_CLAIM_DESCRIPTION}</p>
                        <p className="validity-area">
                            <span>{MULTIPLE_CTA_TEXT}</span>
                        </p>

                    </div>


                    <div className={isMobility === 'MOBILITY' ? 'xmp-scroll xmp-scroll-multi-channel mobilty-scroller' : 'xmp-scroll xmp-scroll-multi-channel'}>

                        {preferredPartnerPlansOfferSelection}
                        {/* <XmpPlanCardComponent />
                        <XmpPlanCardComponent />
                        <XmpPlanCardComponent />
                        <XmpPlanCardComponent />
                        <XmpPlanCardComponent />
                        <XmpPlanCardComponent /> */}

                    </div>


                </div>
            </div>

        );
    }
}
const mapStateToProps = (state, props) => {
    const {
        allPlans,
        authConfig: { isAuthenticated },
        appConfig,
        userConfig,
        popupConfig,
        planConfig,
    } = state;
    const browsePlans = lodashGet(allPlans, 'browsePlans', []);
    const ChannelPartnerImageData = lodashGet(appConfig, 'xstreamPremiumData.partnersList', {});
    const preferredPartnerPlans = lodashGet(userConfig, 'preferredPartnerPlans', []);
    const popups = lodashGet(popupConfig, 'allPopUps', null);
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const uid = lodashGet(userConfig, 'userInfo.uid', '');

    return {
        browsePlans,
        isAuthenticated,
        ChannelPartnerImageData,
        preferredPartnerPlans,
        popups,
        sid,
        circle,
        userConfig,
        uid
    };
};
XmpMultipleClaimContainer.propTypes = {
    channelpartner: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    browsePlans: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types,
    // eslint-disable-next-line react/forbid-prop-types
    ChannelPartnerImageData: PropTypes.array.isRequired,
};
export default connect(mapStateToProps, null)(withDeviceUtil(withRouter(withStyles(styles)(XmpMultipleClaimContainer))));
