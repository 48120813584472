import React from 'react';

const HelpIcon = ({iconProps = {}}) => {
    return (<svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                fill="none"
                className='svg-default'
                {...iconProps}
            >
                <path
                d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
                stroke="#E8EAED"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
                />
                <path
                d="M11.25 11.25H12v5.25h.75"
                stroke="#E8EAED"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
                />
            <path
            d="M11.813 8.813a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875Z"
            fill="#E8EAED"
            />
        </svg>
    )
}

export default HelpIcon;