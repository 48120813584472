export const SubscriptionAction = {
    COMBINED_PLANS_INIT: 'COMBINED_PLANS_INIT',
    PURCHASE_SUBSCRIPTION_INIT: 'PURCHASE_SUBSCRIPTION_INIT',
    PURCHASE_SUBSCRIPTION_ERROR: 'PURCHASE_SUBSCRIPTION_ERROR',
    COMBINED_PLANS_SUCCESS: 'COMBINED_PLANS_SUCCESS',
    COMBINED_PLANS_FAILURE: 'COMBINED_PLANS_FAILURE',
    SUBSCRIPTION_CLEAR_ALL: 'SUBSCRIPTION_CLEAR_ALL',
    SUBSCRIPTION_PLAN_VALUE: 'SUBSCRIPTION_PLAN_VALUE',
    SUBSCRIPTION_ORIGIN: 'SUBSCRIPTION_ORIGIN',
    SUBSCRIPTION_CONTENT_URL: 'SUBSCRIPTION_CONTENT_URL',
    DYNAMIC_PURCHASE_SUBSCRIPTION_INIT: 'DYNAMIC_PURCHASE_SUBSCRIPTION_INIT',
    STOP_AUTO_RENEW_STATUS: 'STOP_AUTO_RENEW_STATUS',
    STOP_AUTO_RENEW_INIT: 'STOP_AUTO_RENEW_INIT',
    CHECK_RECHARGE_PAYMENT_STATUS: 'CHECK_RECHARGE_PAYMENT_STATUS',
    SET_RECHARGE_PAYMENT_STATUS: 'SET_RECHARGE_PAYMENT_STATUS',
    GET_RECHARGE_INFO: 'GET_RECHARGE_INFO',
    GET_RECHARGE_STATUS_INIT: 'GET_RECHARGE_STATUS_INIT',
};

export const getCombinedPlansInit = () => ({
    type: SubscriptionAction.COMBINED_PLANS_INIT,
});

export const subscriptionClearAll = () => ({
    type: SubscriptionAction.SUBSCRIPTION_CLEAR_ALL,
});

export const purchaseSubscriptionInit = payload => ({
    type: SubscriptionAction.PURCHASE_SUBSCRIPTION_INIT,
    ...payload,
});

export const getSubscriptionOrigin = payload => ({
    type: SubscriptionAction.SUBSCRIPTION_ORIGIN,
    ...payload,
});

export const getSubscriptionPlanValue = payload => ({
    type: SubscriptionAction.SUBSCRIPTION_PLAN_VALUE,
    ...payload,
});

export const getContentUrl = payload => ({
    type: SubscriptionAction.SUBSCRIPTION_CONTENT_URL,
    ...payload,
});

export const purchaseSubscriptionError = payload => ({
    type: SubscriptionAction.PURCHASE_SUBSCRIPTION_ERROR,
    ...payload,
});

export const combinedPlansSuccess = payload => ({
    type: SubscriptionAction.COMBINED_PLANS_SUCCESS,
    ...payload,
});

export const combinedPlanFailure = payload => ({
    type: SubscriptionAction.COMBINED_PLANS_FAILURE,
    ...payload,
});

export const stopAutoRenewInit = payload => ({
    type: SubscriptionAction.STOP_AUTO_RENEW_INIT,
    ...payload,
});

export const stopAutoRenewStatus = payload => ({
    type: SubscriptionAction.STOP_AUTO_RENEW_STATUS,
    ...payload,
});

export const checkRechargePaymentStatus = payload => ({
    type: SubscriptionAction.CHECK_RECHARGE_PAYMENT_STATUS,
    ...payload,
});

export const setRechargePaymentStatus = payload =>({
    type: SubscriptionAction.SET_RECHARGE_PAYMENT_STATUS,
    ...payload,
});

export const getRechargeInfo = payload => ({
    type: SubscriptionAction.GET_RECHARGE_INFO,
    ...payload,
});

export const getRechargeStatus = payload => ({
    type: SubscriptionAction.GET_RECHARGE_STATUS_INIT,
    payload: { ...payload },
});
