import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from '../container/SubscriptionContainer.scss';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import RoutingUtil from '../../../utilities/RoutingUtil';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { hideModalComponentAction } from '../../modal-popup/ModalPopupActions';
import { LocationUtil } from '@airtel-tv/utils';
import AuthenticationUtil from '@airtel-tv/utils/AuthenticationUtil';
import { getCurrentUrl } from '../../../utilities/WindowUtil';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import {
    doLaterClickEvent, pageLoadEvent, popUpVisibleEvent, subscribeMoreClickEvent,
} from '@airtel-tv/analytics/FunctionalEvents';
import { updateUserConfig } from '../../user/UserAction';
import { planConfigInit } from '../../plan/PlanActions';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { characterToUpperCase, filterChannelAndRecharge, filterSubscribedPlans, findSelectedCP, getBranchDetail } from '../../../utilities/CommonUtil';
import { EVENT_NAME } from '../../../constants/SubscriptionConst';
import SubscriptionUtil  from '@airtel-tv/utils/SubscriptionUtil';
import env from '../../../config';

class MultipleClaimSuccessPopUp extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isEventFired:false
        };
        this.ISMOBILITY = browserStore.get(LOCAL_STORE_KEYS.MOBILITYOS);
    }

    componentDidMount() {
        const { updateUserConfig, planInitDispatch } = this.props;
        updateUserConfig();
        planInitDispatch();
        this.pageinitEvent();        
    }

    sendHandleAppState(){
        const {subscribedPlans,location} = this.props;
        const subscribedCpId = lodashGet(location, 'state.subscribedCpId', '');
        const productId = lodashGet(location, 'state.productId', '');
        // let filteredPlans = filterSubscribedPlans(subscribedPlans);
        // let plans = filterChannelAndRecharge(filteredPlans).true;
        // let subscribedPlan = findSelectedCP(plans,subscribedCpId);
        // if(subscribedPlan){
        //     let branchEvent = getBranchDetail(subscribedPlan,EVENT_NAME.CLAIM_SUCCESS);
            
        // }
        if(subscribedPlans && subscribedPlans.length>0  && !this.state.isEventFired){
            let isEventFired =SubscriptionUtil.sendHandleAppState(subscribedPlans,subscribedCpId,'success',productId,true,EVENT_NAME.CLAIM_SUCCESS)
            this.setState({
                isEventFired
            })
        }
    }

    pageinitEvent() {
        const { circle, sid,userConfig,uid } = this.props;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            customer_type:userConfig.customerType,
            active_tab: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            source: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            ingressIntent: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            pageName: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            service: ANALYTICS_ASSETS.AIRTELTV,
            uid
        };
        pageLoadEvent(pageLoadAnalyticsMeta, true);
        popUpVisibleEvent(pageLoadAnalyticsMeta, true);
    }

    closePopUp = () => {
        const {
            history, cpDetailsById, location,userConfig,uid, Hoichoi_Channel_Native_Link,
        } = this.props;
        const { circle, sid } = this.props;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            customer_type:userConfig.customerType,
            active_tab: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            source: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            ingressIntent: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            pageName: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            service: ANALYTICS_ASSETS.AIRTELTV,
            uid
        };
        subscribeMoreClickEvent(pageLoadAnalyticsMeta, true);
        const appId = LocationUtil.getKeyFromURL(window.location.search,'appId') || AuthenticationUtil.getAppId() || env.appId;
        const cpName = lodashGet(location, 'state.cpName', '');
        const originalCpId = lodashGet(location, 'state.originalCpId', '');
        const subscribedCpId = lodashGet(location, 'state.subscribedCpId', '');
        const contentUrl = lodashGet(location, 'state.contentUrl', '');
        if (appId === 'MOBILITY' || this.ISMOBILITY) {
            const cpName = lodashGet(location, 'state.cpName', '');
            if(cpName === 'HOICHOI'){
                LocationUtil.handleOpenIntent(Hoichoi_Channel_Native_Link)
            }else{
                LocationUtil.handleCloseAppstate();
            }
        }
        else {
            

            const title = lodashGet(cpDetailsById[cpName], 'title', '');
            const pageLink = lodashGet(cpDetailsById[cpName], 'pageLink', '');
            if (originalCpId !== subscribedCpId) {
                history.push({
                    pathname: RoutingUtil.getChannelPartnerPageUrl({
                        channelPartnerName: title,
                        pageLink,
                    }),
                    search: lodashGet(history,'location.search',''),
                });
                return;
            }
            if (contentUrl) {
                history.push({
                    pathname: contentUrl,
                    search: lodashGet(history,'location.search',''),
                });
            }
            else {
                history.push({
                    pathname: RoutingUtil.getChannelPartnerPageUrl({
                        channelPartnerName: title,
                        pageLink,
                    }),
                    search: lodashGet(history,'location.search',''),
                });
            }
        }
    }

    redirectToHome = () => {
        const {
            history
        } = this.props;
        const appId =  LocationUtil.getKeyFromURL(window.location.search,'appId') || AuthenticationUtil.getAppId() || env.appId;

        if (appId === 'MOBILITY' || this.ISMOBILITY) {
            
            LocationUtil.handleCloseAppstate();
        }
        else {
            

            history.push({
                pathname: RoutingUtil.getHomePage(),
                search: lodashGet(history,'location.search',''),
            });
        }
    }

    redirectToContentDetailPage = () => {
        let {
            history, location, contentUrl,
        } = this.props;
        const appId =  LocationUtil.getKeyFromURL(window.location.search,'appId') ||AuthenticationUtil.getAppId() || env.appId;

        if (appId === 'MOBILITY') {
            
            LocationUtil.handleCloseAppstate();
        }
        else {
            
            const channelPartnerLocation = lodashGet(location, 'state.contentUrl', '');
            contentUrl = contentUrl || channelPartnerLocation;
            const cpName = lodashGet(location, 'state.cpName', '');
            if (contentUrl) {
                history.push({
                    pathname: contentUrl,
                    search: lodashGet(history,'location.search',''),
                });
            }
            else {
                const { cpDetailsById } = this.props;
                if (cpName === 'XSTREAMPREMIUM') {
                    history.push({
                        pathname: RoutingUtil.getHomePage(),
                        search: lodashGet(history,'location.search',''),
                    });
                    return;
                }
                const title = lodashGet(cpDetailsById[cpName], 'title', '');
                const pageLink = lodashGet(cpDetailsById[cpName], 'pageLink', '');
                history.push({
                    pathname: RoutingUtil.getChannelPartnerPageUrl({
                        channelPartnerName: title,
                        pageLink,
                    }),
                    search: lodashGet(history,'location.search',''),
                });
            }
        }
    }

    redirectToClaim = () => {
        const {
            history, circle, sid, location,userConfig,uid
        } = this.props;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            customer_type:userConfig.customerType,
            active_tab: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            source: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            ingressIntent: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            pageName: ANALYTICS_ASSETS.XMP_CLAIM_SUCCESS,
            service: ANALYTICS_ASSETS.AIRTELTV,
            uid
        };
       

        doLaterClickEvent(pageLoadAnalyticsMeta, true);

        const state = lodashGet(location, 'state', '');
        history.push({
            pathname: RoutingUtil.getXmpClaim(),
            search: lodashGet(history,'location.search',''),
            state: {
                ...state,
            },
        });
        // window.location.reload();
    }

    render() {
        const { location, cpDetailsById, subscriptionPopUp } = this.props;
        // const cpName = lodashGet(location, 'state.cpName', '');
        const cpName = lodashGet(location, 'state.cpName', '');
        const title = lodashGet(cpDetailsById[cpName], 'title', '');
        const multipleClaimSuccessTitle = lodashGet(subscriptionPopUp, 'multipleClaimSuccessTitle', '');
        const multipleClaimSuccessBtntext1 = lodashGet(subscriptionPopUp, 'multipleClaimSuccessBtntext1', '');
        const multipleClaimSuccessBtntext2 = lodashGet(subscriptionPopUp, 'multipleClaimSuccessBtntext2', '');

        const cpLogo = lodashGet(location, 'state.cpLogo', '');
        const isMobility = browserStore.get(LOCAL_STORE_KEYS.APP_ID);
        this.sendHandleAppState()
        return (
            <div className="popup-layer">
                <div className="single-success-popup multi-channel">
                    <button
                        className="single-success-cross"
                        onClick={() => {
                            this.closePopUp();
                        }}
                    >
                        <ThumborImage
                            src="/static/close.svg"
                        />
                    </button>

                        <div className="logo-star-master-wrapper">
                        <div className="logo-star-wrapper">
                        <div className="logo-container-single no-bg-star">
                            <ThumborImage
                                className="single-popup-logo"
                                src={cpLogo}
                            />
                            <span className="popup-checkmark">
                                <div className="checkmark_stem" />
                                <div className="checkmark_kick" />
                            </span>
                        </div>
                    </div>


                    <p className="activation-status">
                        <span className="title">{characterToUpperCase(title)}</span>

                        <span className="status">{multipleClaimSuccessTitle}</span>
                    </p>
                        </div>
                    
                    <div className={isMobility === 'MOBILITY' ? 'action-area action-area-mobility' : 'action-area'}>
                        <button
                            className="dolater-multi-btn"
                            onClick={() => {
                                this.redirectToClaim();
                            }}
                        >
                            {multipleClaimSuccessBtntext1}
                        </button>
                        <button
                            className="single-success-btn"
                            onClick={() => {
                                this.closePopUp();
                            }}
                        >
                            {' '}
                            {multipleClaimSuccessBtntext2}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state, props) => {
    const {
        allPlans,
        authConfig: { isAuthenticated },
        appConfig,
        userConfig,
        popupConfig,
        notify,
        planConfig,
    } = state;
    const browsePlans = lodashGet(allPlans, 'browsePlans', []);
    const subscribedPlans = lodashGet(planConfig, 'subscribedPlans', []);
    const contentUrl = lodashGet(allPlans, 'contentUrl', '');
    const subscriptionOrigin = lodashGet(allPlans, 'subscriptionOrigin', '');
    const isFetchingDone = lodashGet(notify, 'isFetchingDone', null);
    const ChannelPartnerImageData = lodashGet(appConfig, 'xstreamPremiumData.partnersList', {});
    const preferredPartnerPlans = lodashGet(userConfig, 'preferredPartnerPlans', []);
    const popups = lodashGet(popupConfig, 'allPopUps', null);
    const cpDetailsById = lodashGet(appConfig, 'cpDetailsById', {});
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const subscriptionPopUp = lodashGet(appConfig, 'subscriptionPopUp', {});
    const uid = lodashGet(userConfig, 'userInfo.uid', '');
    const Hoichoi_Channel_Native_Link = lodashGet(appConfig,'Hoichoi_Campaign.Hoichoi_Channel_Native_Link', '');
    return {
        contentUrl,
        subscriptionOrigin,
        browsePlans,
        isAuthenticated,
        ChannelPartnerImageData,
        preferredPartnerPlans,
        popups,
        isFetchingDone,
        cpDetailsById,
        sid,
        circle,
        subscriptionPopUp,
        userConfig,
        uid,
        subscribedPlans,
        Hoichoi_Channel_Native_Link,
    };
};
MultipleClaimSuccessPopUp.propTypes = {
    channelpartner: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    triggerLoginDispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    browsePlans: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types,
    // eslint-disable-next-line react/forbid-prop-types
    ChannelPartnerImageData: PropTypes.array.isRequired,
};
export default connect(mapStateToProps, {
    hideModalComponentActionDispatch: hideModalComponentAction,
    updateUserConfig,
    planInitDispatch: planConfigInit,
})(withDeviceUtil(withRouter(withStyles(styles)(MultipleClaimSuccessPopUp))));
