import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import SubscriptionUtil, { airtelRechargeRedirection } from '@airtel-tv/utils/SubscriptionUtil';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { continueToPayError, renewSelectedClick } from '@airtel-tv/analytics/FunctionalEvents';
import { AnalyticsButtonComponent, AnalyticsLinkComponent } from '@airtel-tv/analytics';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { ARIA_LABEL, LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { getXstreamPartner } from '@airtel-tv/utils/GlobalUtil';
import AirtelXstreamSVGComponent from '@airtel-tv/ui-lib/atoms/icons/components/XstreamFMFIcon';
import ArrowSVGComponent from '@airtel-tv/ui-lib/svg/ArrowSVGComponent';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import {
    getDateWithTitle, formatType, characterToUpperCase, getSid,
} from '../../../utilities/CommonUtil';
import { getCurrentUrl } from '../../../utilities/WindowUtil';
import RoutingUtil from '../../../utilities/RoutingUtil';
import { purchaseSubscriptionInit } from '../action/SubscriptionAction';
import { IMAGE_PATHS, MODAL_POPUP } from '../../../constants/AppConst';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { showModalComponentAction } from '../../modal-popup/ModalPopupActions';
import LanguageProvider from '../../../providers/LanguageProvider';
import { TELCO_PAGE_TEXT_FALLBACK, XPP_LOGO_FALLBACK } from '../../../constants/FallbackContants';

const LANGUAGE = LanguageProvider();
const TELCO = LANGUAGE.XPP_LITE_GROUP || 'wynk_xstream_premium_lite';
class ActiveXstreamPlanComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isCursorOnRail: false,
            indexOfRail: 0,
        };

        this.carouselElementRef = React.createRef();
    }

    componentDidMount() {
        if (this.carouselElementRef && this.carouselElementRef.current) {
            const { xStreamPremium } = this.props;
            this.carouselElementRef.current.addEventListener('scroll', (e) => {
                let { indexOfRail } = this.state;
                indexOfRail = Math.ceil(this.carouselElementRef.current.scrollLeft / 98);
                this.setState({ indexOfRail });
            });
        }
    }

    next = (topLength) => {
        if (indexOfRail >= (topLength - 5)) {
            return;
        }
        let { indexOfRail } = this.state;
        if (this.carouselElementRef.current) {
            const images = this.carouselElementRef.current.children;
            if (images && images.length > 0) {
                this.carouselElementRef.current.scrollLeft += images[0].children[0].offsetWidth;
                this.setState({ indexOfRail: ++indexOfRail });
            }
        }
    };

    continueToPayEvent({sid, redirectPageUrl, isError, response, isRechargePlan}) {
        const {
            circle, customer_type, uid, planId,
        } = this;
        const object = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid: getSid(sid, response),
            customer_type,
            active_tab: ANALYTICS_ASSETS.MY_PLANS_PAGE,
            source: ANALYTICS_ASSETS.MY_PLANS_PAGE,
            pageName: ANALYTICS_ASSETS.MY_PLANS_PAGE,
            ingressIntent: ANALYTICS_ASSETS.MY_PLANS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            planId,
            uid,
            is_recharge_plan: isRechargePlan,
        };

        if (isError) {
            continueToPayError({
                ...object,
                response: {
                    response,
                    object,
                },
                pageUrl: redirectPageUrl,
            });
        }
        else {
            renewSelectedClick({
                ...object,
                response: {
                    response,
                    object,
                },
            });
        }
    }

    previous = () => {
        let { indexOfRail } = this.state;
        if (indexOfRail === 0) {
            return;
        }
        if (this.carouselElementRef.current) {
            const images = this.carouselElementRef.current.children;
            this.carouselElementRef.current.scrollLeft -= images[0].children[0].offsetWidth;
            this.setState({ indexOfRail: --indexOfRail });
        }
    };

    openModal = () => {
        const { showModalComponentActionDispatch, xStreamPremium } = this.props;
        showModalComponentActionDispatch({
            showModalValue: true,
            componentNameValue: MODAL_POPUP.CANCEL_AUTO_RENEW_PLAN_POPUP,
            overrideCrossButtonValue: false,
            payload: {
                plan: xStreamPremium,
            },

        });
    };

    // subscribe=(plans) => {
    //     const { purchaseSubscriptionInitDispatch,circle ,userConfig,uid} = this.props;
    //     const subscriptionWindowLocation = getWindowLocation();

    //     const appId = LocationUtil.getKeyFromURL(window.location.search,'appId') || browserStore.get(LOCAL_STORE_KEYS.APP_ID) || env.appId;
    //     let successUrl = `${subscriptionWindowLocation.origin + RoutingUtil.getSubscriptionSuccessPage()}?planID=${plans.planId}&cpId=${plans.cpId}&contentUrl=&appId=${appId}`;
    //     let failureUrl = `${subscriptionWindowLocation.origin + RoutingUtil.getSubscriptionFailurePage()}?planID=${plans.planId}&cpId=${plans.cpId}&contentUrl=&skuId=''&journeyStart=${subscriptionWindowLocation.pathname}&appId=${appId}`;

    //     if (appId === 'MOBILITY') {
    //         successUrl += '&viewType=webview';
    //         failureUrl += '&viewType=webview';
    //     }
    //     purchaseSubscriptionInitDispatch({
    //         successUrl,
    //         failureUrl,
    //         pendingUrl: failureUrl,
    //         unknownUrl: failureUrl,
    //         planId: plans.planId,
    //         skuId: '',
    //         eventMethod:this.continueToPayEvent.bind({
    //             planId:plans.planId,
    //             circle,
    //             customer_type:userConfig.customerType,
    //             uid
    //         })
    //     });
    // }

    subscribe = (plans) => {
        const { plan = {} } = plans;
        const {
            purchaseSubscriptionInitDispatch, circle, userConfig, uid,
            useNativeRecharge,
        } = this.props;
        let isRechargePlan = false;
        let disableAutoRenew = false;
        let successUrlOverride = '';

        if (plan?.meta?.payment?.mode === 'AIRTEL_PARTNER') {
            isRechargePlan = true;
            disableAutoRenew = true;
            const { RECHARGE_STATUS_PAGE = '' } = ROUTE_PATHS;
            const {
                airtelPlan = 10, title = 'Xstream Premium', dataBenefit = '', dataValidity = '',
            } = plan.meta;
            const airtelTitle = title.replace('<b>', '').replace('</b>', '');
            const statusUrl = `${RECHARGE_STATUS_PAGE}?planID=0&cpId=XSTREAMPREMIUM&amount=${airtelPlan}`;
            successUrlOverride = `${window?.location?.origin}${statusUrl}&successMobileBenefits=${`20+ OTTs • ${dataBenefit} • ${dataValidity}`}&useNativeRecharge=true`;

            if (!useNativeRecharge) {
                const selectedPlan = {
                    total: airtelPlan,
                    title: airtelTitle,
                };
                airtelRechargeRedirection({
                    selectedPlan,
                    statusUrl,
                    msisdnNo: this?.props?.msisdnNo?.replace('+91', '') || '',
                });
                return;
            }
        }

        let { renewPlanId, planId, cpId } = plans;
        planId = renewPlanId || planId;

        let { successUrl, failureUrl } = SubscriptionUtil.getSubscriptionUrls(
            {
                plan: {
                    planId,
                    cpId,
                    autoPayEnabled: !disableAutoRenew,
                },
                successPage: RoutingUtil.getSubscriptionSuccessPage(),
                failurePage: RoutingUtil.getSubscriptionFailurePage(),
            },
        );
        if (successUrlOverride) {
            successUrl = successUrlOverride;
        }
        purchaseSubscriptionInitDispatch({
            successUrl,
            failureUrl,
            pendingUrl: failureUrl,
            unknownUrl: failureUrl,
            planId,
            skuId: '',
            isRechargePlan,
            disableAutoRenew,
            eventMethod: this.continueToPayEvent.bind({
                planId,
                circle,
                customer_type: userConfig.customerType,
                uid,
                isRechargePlan,
            }),
        });
    };

    render() {
        const {
            xStreamPremium, myPlanPageText, deviceUtil, xstreamPremiumLiteURL, telcoPlanPageText,
            cpDetailsById, showSeeOtherPlan = false, history = {},
        } = this.props;
        const search = history?.location?.search || '';
        const platform = browserStore.get(LOCAL_STORE_KEYS.OS) || 'WEB';
        // const platform = 'IOS';
        const { indexOfRail } = this.state;
        let date = '';
        const plans = xStreamPremium;
        if (xStreamPremium && xStreamPremium.validTill) {
            date = getDateWithTitle(xStreamPremium.validTill, formatType.LONG);
        }
        if (!xStreamPremium) {
            return null;
        }
        const partnersLogo = getXstreamPartner(xStreamPremium.partners, cpDetailsById);
        const { SUBSCRIPTION_RENEW_TXT = 'Renew Now', SEE_OTHER_PLANS = 'See Other Plans' } = LANGUAGE;
        const validTill = xStreamPremium?.plan?.validityPlaceholder || 'Valid till '.concat(date);
        const isTelcoUser = xStreamPremium?.packGroup?.indexOf(TELCO) > -1;
        const imageUrl = isTelcoUser ? xstreamPremiumLiteURL : '/static/xstream-premium.svg';
        const xppSubtext = isTelcoUser ? lodashGet(telcoPlanPageText, 'xppSubtext', '') : lodashGet(myPlanPageText, 'xppSubtext', '');
        const validityDuration = plans?.plan?.title;
        const { SCROLL_RAIL_LEFT = 'Scroll Rail Left', SCROLL_RAIL_RIGHT = 'Scroll Rail Right' } = ARIA_LABEL;
        const pricePlaceholder = xStreamPremium?.plan?.pricePlaceholder || `Subscribed @ \u20B9${xStreamPremium.amount} ${validityDuration}`;
        const mobileBenefits = xStreamPremium?.plan?.meta?.mobileBenefits || null;
        return (
            <section className="active-xstream-plan">
                {xStreamPremium.autoRenew && (
                    <AnalyticsButtonComponent
                        className="auto-renew-cancel-option"
                        meta={{
                            action: 'three_dots',
                            source_name: 'more',
                            asset_name: 'planAndOffers',
                        }}
                        onClick={this.openModal}
                        aria-label="Open Modal"
                    >
                        <ThumborImage
                            src={IMAGE_PATHS.THREE_DOTS_VERTICAL}
                        />
                    </AnalyticsButtonComponent>
                )}
                <AirtelXstreamSVGComponent
                    className="xstream-page-img"
                />
                <div
                    className="active-heading text-24 mobile-text-16 mobile-line-height-24 line-height-32  kkk"
                    dangerouslySetInnerHTML={{ __html: pricePlaceholder }}
                />
                <p className="active-title mobile-text-12 text-14 mobile-line-height-18">
                    {validTill}
                </p>
                {mobileBenefits ? (
                    <div className="plan-detail-container plan-benefit-container">
                        <p className="heading">Plan Benefits</p>
                        <ul className="active-xstream-wrapper plan-benifit-inline">
                            {mobileBenefits.map(benefit => <li>{benefit.replace('Per', '/').replace('per', '/')}</li>)}
                            <li>
                                <span className="free-tag">FREE</span>
                                Xstream Play Subscription
                            </li>
                        </ul>
                    </div>
                ) : ''
                }
                <div className="plan-detail-container">
                    <p className="heading">Plan details</p>
                    <p className="title">{xppSubtext}</p>
                    <div className="active-xstream-wrapper">
                        <AnalyticsButtonComponent
                            className="slide-btn left"
                            onClick={this.previous}
                            aria-label={SCROLL_RAIL_LEFT}
                        >
                            { indexOfRail > 0 && (
                                <div className="chev-background">
                                    <ThumborImage
                                        src="/static/left-chev.svg"
                                    />
                                </div>
                            ) }
                        </AnalyticsButtonComponent>
                        <div
                            className="plan-detail-rail-wrapper"
                            ref={this.carouselElementRef}
                            onMouseOver={() => this.setState({ isCursorOnRail: true })}
                            onMouseLeave={() => this.setState({ isCursorOnRail: false })}
                        >
                            <div className="top-rail">
                                {partnersLogo.top}
                            </div>
                            <div className="bottom-rail">
                                {partnersLogo.bottom}
                            </div>
                        </div>
                        <AnalyticsButtonComponent
                            className="slide-btn right"
                            onClick={() => this.next(partnersLogo.top.length)}
                            aria-label={SCROLL_RAIL_RIGHT}
                        >
                            { indexOfRail < (partnersLogo.top.length - 5) && (
                                <div className="chev-background">
                                    <ThumborImage
                                        src="/static/left-chev.svg"
                                    />
                                </div>
                            ) }
                        </AnalyticsButtonComponent>
                    </div>

                </div>
                {platform.toLowerCase() !== SubscriptionUtil.PLATFORM.IOS && plans.planType !== SubscriptionUtil.PLAN_TYPE.FREE_TRIAL && plans.preReminder && plans.renewPlanId && plans.renewPlanId !== 0 && plans.state !== SubscriptionUtil.PLAN_STATE.DEPRECATED && (
                    <div className="renew-xpp-container">
                        <AnalyticsButtonComponent
                            onClick={e => this.subscribe(plans)}
                            className="renew-btn-xpp"
                            aria-label={SUBSCRIPTION_RENEW_TXT}
                        >
                            {SUBSCRIPTION_RENEW_TXT}
                        </AnalyticsButtonComponent>

                    </div>

                // <CustomLink text="Renew" onClick={onSelectPlan} />
                )}
                {showSeeOtherPlan ? (
                    <AnalyticsLinkComponent
                        className="d-flex see-other-plans align-items-center justify-content-center"
                        to={`${ROUTE_PATHS?.SUBSCRIPTION_PAGE}`}
                        meta={{
                            asset_name: SEE_OTHER_PLANS,
                            action: 'see_other_plans',
                        }}
                    >
                        <p>{SEE_OTHER_PLANS}</p>
                        <ArrowSVGComponent
                            stroke="#E8EAED"
                            height="12"
                            width="7"
                        />
                    </AnalyticsLinkComponent>
                ) : ''}

            </section>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        appConfig: {
            cpDetailsById = {},
            myPlanPageText = {},
            telcoPlanPageText = TELCO_PAGE_TEXT_FALLBACK,
            XPP_LITE_LOGO: xstreamPremiumLiteURL = XPP_LOGO_FALLBACK,
            nativeRechargesUid = [],
        } = {},
        planConfig,
        userConfig: { msisdnNo },
        userConfig,
    } = state;
    let {
        appConfig: {
            useNativeRecharge = true,
        },
    } = state;
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const uid = lodashGet(userConfig, 'userInfo.uid', '');
    if (nativeRechargesUid.length > 0 && !nativeRechargesUid.includes(uid)) {
        useNativeRecharge = false;
    }
    return {
        uid,
        cpDetailsById,
        myPlanPageText,
        sid,
        circle,
        userConfig,
        xstreamPremiumLiteURL,
        telcoPlanPageText,
        msisdnNo,
        useNativeRecharge,
    };
};

export default connect(mapStateToProps, {
    purchaseSubscriptionInitDispatch: purchaseSubscriptionInit,
    showModalComponentActionDispatch: showModalComponentAction,
})(withRouter(ActiveXstreamPlanComponent));
