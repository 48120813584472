import {
    call, delay, getContext, put, select, takeEvery,
} from 'redux-saga/effects';
import lodashGet from 'lodash/get';
import AuthenticationUtil from '@airtel-tv/utils/AuthenticationUtil';
import { LocationUtil } from '@airtel-tv/utils';
import { redirectToPayment } from '@airtel-tv/analytics/FunctionalEvents';
import { getUserConfigFromReducer } from '@airtel-tv/redux/StoreListing';

import { ANALYTICS_ACTIONS, ANALYTICS_ASSETS, BILL_STATUS, MODAL_POPUP } from '@airtel-tv/constants';
import { ICON_MAP } from '@airtel-tv/ui-lib/atoms/icons/iconFactory';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { showRechargeNudges } from '@airtel-tv/utils/GlobalUtil';
import { CUSTOMER_TYPE } from '@airtel-tv/constants/GlobalConst.js';
import { showNonAirtelUserAction } from '../../user/UserAction';
import { getCombinedPlansConfig } from '../../../service/end-points/combinedApiService';
import {
    SubscriptionAction, combinedPlansSuccess, combinedPlanFailure, purchaseSubscriptionError, stopAutoRenewStatus,
    setRechargePaymentStatus,
    getRechargeStatus,
} from '../action/SubscriptionAction';
import ErrorHandler from '../../error-handler/ErrorHandler';
import { purchaseRedirectionAPI, checkRechargePaymentStatusAPI } from '../../../service/end-points/purchaseApiService';
import { openUrlInSameTab, redirectTo } from '../../../utilities/WindowUtil';
import { isStringEmpty } from '../../../utilities/CommonUtil';
import env from '../../../config';
import { rechargeInfo, stopAutoRenewAPI } from '../../../service/end-points/SubscriptionApiService';
import { hideModalComponentAction, showModalComponentAction } from '../../modal-popup/ModalPopupActions';
import { showToastr } from '../../../utilities/NotifyUtil';
import { planConfigInit } from '../../plan/PlanActions';
import { resetSvodAllPlans } from '@airtel-feature/plansAndSubscription/PlansAndSubscriptionAction';


const popupDefaulConfig = {
    INPROGRESS: {
        label: 'Your recharge will expire {duration}',
        description: 'Recharge now for non stop entertainment',
        ctaLabel: 'Recharge Now',
        status: 'PENDING',
        statusIcon: ICON_MAP.FMF_YELLOW_WARNING,
        cancelCta: 'Later',
        ctaLink: ROUTE_PATHS.PLANS_AND_OFFERS,
        cancelMeta: {
            asset_name: ANALYTICS_ASSETS.RECHARGE_PACK_EXPIRY_POPUP,
            action: ANALYTICS_ACTIONS.LATER,
        },
        ctaMeta: {
            asset_name: ANALYTICS_ASSETS.RECHARGE_PACK_EXPIRY_POPUP,
            action: ANALYTICS_ACTIONS.RECHARGE_NOW,
        },
    },
};


function* onCombinedPlanConfigInit() {
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const response = yield call(getCombinedPlansConfig, {
            deviceUtil,
        });
        yield put(combinedPlansSuccess(response));
    }
    catch (e) {
        console.error(e);
        const payload = lodashGet(e, 'data', e);
        ErrorHandler({
            code: payload.errorcode,
            errortitle: payload.errortitle,
        });
        yield put(combinedPlanFailure({ error: payload }));
    }
}

function* subscriptionInit(action) {
    const deviceUtil = yield getContext('deviceUtil');
    // const userConfig = yield select(getUserConfigFromReducer);
    const planId = lodashGet(action, 'planId', '');
    // const productIds = lodashGet(action, 'productIds', '');
    const userInfo = yield select(getUserConfigFromReducer);
    const { customerType } = userInfo;
    if (customerType === CUSTOMER_TYPE.NON_AIRTEL) {
        yield put(showNonAirtelUserAction());
        return;
    }
    const more = false;
    const successUrl = lodashGet(action, 'successUrl', '');
    const failureUrl = lodashGet(action, 'failureUrl', '');
    const pendingUrl = lodashGet(action, 'pendingUrl', '');
    const unknownUrl = lodashGet(action, 'unknownUrl', '');
    const skuId = lodashGet(action, 'skuId', '');
    const {
        eventMethod, ingressIntent = '', trialOpted = '',
        isRechargePlan,
        disableAutoRenew,
    } = action;
    try {
        const response = yield call(purchaseRedirectionAPI, {
            deviceUtil,
            more,
            planId,
            successUrl,
            failureUrl,
            pendingUrl,
            unknownUrl,
            isRechargePlan,
            disableAutoRenew,
            ingressIntent,
            trialOpted,
        });
        const appId = LocationUtil.getKeyFromURL(window.location.search, 'appId') || AuthenticationUtil.getAppId() || env.appId;
        const uid = LocationUtil.getKeyFromURL(window.location.search, 'uid') || AuthenticationUtil.getUid();
        const os = deviceUtil.getOsFromLocal();
        const deviceId = deviceUtil.getDeviceIdFromLocal();
        const redirectionUrl = lodashGet(response, 'data.redirectUrl', '');
        let sid = lodashGet(response, 'data.sid', '');
        if (!sid) {
            sid = response.data.sid || redirectionUrl.split('payment-option/')[1].split('/')[0];
        }
        eventMethod(sid, redirectionUrl, isStringEmpty(redirectionUrl), JSON.stringify(response), isRechargePlan);
        if (appId === 'MOBILITY' && os === 'IOS') {
            const payActions = {
                planId,
                skuId,
                deviceId,
                platform: 'ios',
                hash: null,
                sid,
                uid: AuthenticationUtil.getUid(),
            };

            window.webkit.messageHandlers.payActions.postMessage({
                payActions,
            });
            return;
        }

        openUrlInSameTab(redirectionUrl, _redirectToPayment.bind({
            response: {
                response,
                appId,
                os,
                deviceId,
                uid,
            },
            sid,
        }));
    }
    catch (e) {
        const error = lodashGet(e, 'data', error);
        console.error(e);
        const payload = {
            ...e.data,
        };
        ErrorHandler({
            code: error.errorcode,
            errortitle: error.errortitle,
            showToastOnly: true,
        });
        eventMethod('', '', true, JSON.stringify(e));
        yield put(purchaseSubscriptionError({ payload }));
    }
}

function _redirectToPayment(url) {
    const { response, sid } = this;
    response.response.url = url;
    redirectToPayment({
        ...response,
        sid,
    });
}

function* stopAutoRenewInit(payload) {
    const { planId, successText } = payload;
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const response = yield call(stopAutoRenewAPI, {
            deviceUtil,
            planId,
        });
        yield put(stopAutoRenewStatus(response));
        yield delay(1000);
        yield put(planConfigInit());
        yield put(resetSvodAllPlans());
        yield put(hideModalComponentAction({
            showModalValue: false,
            componentNameValue: null,
            overrideCrossButtonValue: null,
        }));
        showToastr(successText, '');
    }
    catch (e) {
        console.error(e);
        const data = lodashGet(e, 'data', e);
        ErrorHandler({
            code: data.errorcode,
            errortitle: data.errortitle,
        });
        yield put(stopAutoRenewStatus({ error: data }));
    }
}

function* checkRechargePaymentStatus(payload) {
    const { pgId, redirectionUrl } = payload;
    try {
        yield delay(2000);
        const response = yield call(checkRechargePaymentStatusAPI, { pgId });
        const paymentStatus = response?.data?.paymentStatus;
        yield put(setRechargePaymentStatus({ status: paymentStatus }));
        if (paymentStatus !== 'PAYMENT_SUCCESS' && redirectionUrl) {
            redirectTo(redirectionUrl);
        }
    }
    catch {
        redirectTo(redirectionUrl);
    }
}

function* getRechargeInfoInit({ uid = '', hideModalComponentActionDispatch = () => {}, xEy = '' }) {
    try {
        const deviceUtil = yield getContext('deviceUtil');
        let response = yield call(rechargeInfo, {
            deviceUtil,
        });
        // const response = {
        //     validity: '2024-05-28',
        //     amount: 839,
        //     planId: 4888,
        //     xppBenefitWithPlan: true,
        // };
        const localData = localStorage.getItem('recharge_info_obj');
        if (localData) {
            try {
                response = {
                    ...response,
                    ...JSON.parse(localData),
                };
            }
            catch (e) {
                console.log(e);
            }
        }
        yield put(getRechargeStatus(response));
        const popupData = showRechargeNudges({ popupDefaulConfig, validity: response?.validity, amount: response?.amount, uid, xEy });
        if (popupData) {
            yield put(showModalComponentAction({
                showModalValue: 'WAITING',
                componentNameValue: MODAL_POPUP.STATUS_POPUP,
                overrideCrossButtonValue: true,
                overrideBackButton: true,
                payload: {
                    ...popupData[BILL_STATUS.INPROGRESS],
                    modalValue: 'INPROGRESS',
                    popupConfig: popupData,
                    popUpcustomClass: 'status-container',
                    popupVisibleMeta: {
                        asset_name: ANALYTICS_ASSETS.RECHARGE_PACK_EXPIRY_POPUP,
                    },
                },
            }));
        }
    }
    catch (e) {
        console.error(e);
    }
}


export default [
    takeEvery(SubscriptionAction.COMBINED_PLANS_INIT, onCombinedPlanConfigInit),
    takeEvery(SubscriptionAction.PURCHASE_SUBSCRIPTION_INIT, subscriptionInit),
    takeEvery(SubscriptionAction.STOP_AUTO_RENEW_INIT, stopAutoRenewInit),
    takeEvery(SubscriptionAction.CHECK_RECHARGE_PAYMENT_STATUS, checkRechargePaymentStatus),
    takeEvery(SubscriptionAction.GET_RECHARGE_INFO, getRechargeInfoInit),
];
