import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { PropTypes } from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import RoutingUtil from '../../../utilities/RoutingUtil';
import SubsciptionPageContainer from '../component/SubscriptionPageContainer';
import styles from './SubscriptionContainer.scss';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { getCurrentUrl } from '../../../utilities/WindowUtil';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { pageLoadEvent } from '@airtel-tv/analytics/FunctionalEvents';
import SubscriptionUtil  from '@airtel-tv/utils/SubscriptionUtil';

class CpPlanDetailPage extends PureComponent {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;
    }

    componentDidMount() {
        const {
            isAuthenticated, history, sid, circle, browsePlans, location,uid,
            userConfig
        } = this.props;
        if (!isAuthenticated) {
            history.push({
                pathname: RoutingUtil.getHomePage(),
                search:lodashGet(history,'location.search',''),
            });
        }

        const cpId = lodashGet(location, 'state.cpId', '');
        const origin = lodashGet(location, 'state.origin', '');
        const cpObject = browsePlans && browsePlans.filter(cp => cp.cpId.includes(cpId));
        const planId = SubscriptionUtil.getBestValuePlanId(cpObject); 
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: ANALYTICS_ASSETS.SUBSCRIBED_PACKS,
            source: origin,
            pre_selected_plan_id: planId,
            cp_name:cpId,
            pageName: ANALYTICS_ASSETS.SUBSCRIPTION_DETAILS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type:userConfig.customerType,
            uid
        };
        pageLoadEvent(pageLoadAnalyticsMeta, true);
    }

    componentDidUpdate(prevProps) {
        const { isAuthenticated, history } = this.props;
        if (prevProps.isAuthenticated !== isAuthenticated) {
            history.push({
                pathname: RoutingUtil.getHomePage(),
                search: lodashGet(history,'location.search',''),
            });
        }
    }


    getChannelPartnerId = (cpId, cpIdFromQuery) => cpId || cpIdFromQuery || ''

    render() {
        const {
            browsePlans, location,
        } = this.props;

        const cpId = lodashGet(location, 'state.cpId', '');
        const origin = lodashGet(location, 'state.origin', '');
        const contentUrl = lodashGet(location, 'state.contentUrl', '');
        const channelData = browsePlans && browsePlans.filter(cp => cp.cpId.includes(cpId));
        const channelPartner = cpId;


        return (
            <div
                className="xstream-premium-wrapper"
                key={channelPartner}
            >
                <SubsciptionPageContainer
                    origin={origin}
                    channelpartner={channelPartner}
                    channelData={channelData}
                    handleClick={this.handleClick}
                    contentUrl={contentUrl}
                />
            </div>
        );
    }
}
const mapStateToProps = (state, props) => {
    const {
        planConfig,
        authConfig: { isAuthenticated },
        appConfig,
        userConfig
    } = state;
    const browsePlans = lodashGet(planConfig, 'browsePlans', []);
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const ChannelPartnerImageData = lodashGet(appConfig, 'xstreamPremiumData.partnersList', {});
    const uid = lodashGet(userConfig, 'userInfo.uid', '');
    return {
        browsePlans,
        isAuthenticated,
        sid,
        circle,
        ChannelPartnerImageData,
        userConfig,
        uid
    };
};
CpPlanDetailPage.propTypes = {
    channelpartner: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    browsePlans: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types,
    // eslint-disable-next-line react/forbid-prop-types
    ChannelPartnerImageData: PropTypes.array.isRequired,
};
export default connect(mapStateToProps, null)(withDeviceUtil(withRouter(withStyles(styles)(CpPlanDetailPage))));
