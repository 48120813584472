import { SubscriptionAction } from '../action/SubscriptionAction';

const initialState = JSON.parse(`{
    "subscriptionError": null
}`);

const SubscriptionsReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case SubscriptionAction.COMBINED_PLANS_SUCCESS:
            changes = {
                ...action,
            };
            break;

        case SubscriptionAction.COMBINED_PLANS_FAILURE:
            changes = {
                ...action,
            };
            break;
        case SubscriptionAction.PURCHASE_SUBSCRIPTION_ERROR:
            changes = {
                subscriptionError: action.payload,
            };
            break;
        case SubscriptionAction.SUBSCRIPTION_ORIGIN:
            changes = {
                subscriptionOrigin: action.origin,
            };
            break;
        case SubscriptionAction.SUBSCRIPTION_CONTENT_URL:
            changes = {
                subscriptionContentUrl: action.contentUrl,
            };
            break;
        case SubscriptionAction.SUBSCRIPTION_PLAN_VALUE:
            changes = {
                subscriptionPlanPrice: action.price,
            };
            break;
        case SubscriptionAction.SUBSCRIPTION_CLEAR_ALL:
            return {};
        case SubscriptionAction.SET_RECHARGE_PAYMENT_STATUS:
            changes = {
                rechargePaymentStatus: action.status,
            };
            break;
        case SubscriptionAction.GET_RECHARGE_STATUS_INIT:
            changes = {
                ...action.payload,
            };
            break;
        default: return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default SubscriptionsReducer;
