import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import withStyles from 'isomorphic-style-loader/withStyles';
import RoutingUtil from '../../../utilities/RoutingUtil';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import styles from '../container/SubscriptionContainer.scss';
import { connect } from 'react-redux';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { updatePreferredPartnerSelections } from '../../user/UserAction';
import { getContentUrl, getSubscriptionOrigin } from '../action/SubscriptionAction';
import { hideModalComponentAction, showModalComponentAction } from '../../modal-popup/ModalPopupActions';
import { SUBSCRIPTION_SOURCE } from '../../../constants/EventsConst';
import { MODAL_POPUP } from '../../../constants/AppConst';
import { LocationUtil } from '@airtel-tv/utils';
import { getCurrentUrl, getWindowLocation } from '../../../utilities/WindowUtil';
import { exploreOtherChannelsClickEvent, unlockNowClickEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { characterToUpperCase } from '../../../utilities/CommonUtil';

const windowLocation = getWindowLocation();

class XmpClaimModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.deviceUtil = props.deviceUtil;
    }

    componentDidMount() {
        if (!this.deviceUtil.isMobile()) {
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
        }
        else {
            document.body.style.overflow = 'scroll';
            document.body.style.position = 'unset';
        }
    }

    componentDidUpdate(prevProps) {
        const {
            preferredPartnerPlans, cpDetailsById, isFetchingDone, contentUrl, contentDetails, showModalComponentActionDispatch,
        } = this.props;


        const cpName = lodashGet(contentDetails, 'cpId', '');
        const preferredPartnerOfferPlanId = lodashGet(preferredPartnerPlans[0], 'planId', null);
        const cpLogo = lodashGet(cpDetailsById[cpName], 'cpIconURL', '');


        if (isFetchingDone && prevProps.isFetchingDone !== isFetchingDone) {
            this.closePopUp();
            showModalComponentActionDispatch({
                showModalValue: true,
                componentNameValue: MODAL_POPUP.SINGLE_CLAIM_SUCCESS_POPUP,
                overrideCrossButtonValue: true,
                payload: {
                    planId: preferredPartnerOfferPlanId,
                    cpLogo,
                    cpName,
                    preferredPartnerPlanOpted: preferredPartnerPlans[0],
                    contentUrl,
                    origin: SUBSCRIPTION_SOURCE.CONTENT_DETAIL_PAGE_XMP_MODAL,
                },

            });
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'scroll';
        document.body.style.position = 'unset';
    }

    closePopUp = () => {
        const {
            hideModalComponentActionDispatch,
        } = this.props;
        hideModalComponentActionDispatch(
            false,
            null,
            null,
        );
    }


    onUnlockNowClick = () => {
        const {
            preferredPartnerPlans, updatePreferredPartnerSelectionDispatch, 
            contentDetails, circle, sid, userConfig, uid
        } = this.props;

        const cpName = lodashGet(contentDetails, 'cpId', '');
        const selectedOffer = preferredPartnerPlans && preferredPartnerPlans[0] && preferredPartnerPlans[0].offers.filter(offer => offer.cp === cpName);
        const selectedProductId = selectedOffer && selectedOffer.length && selectedOffer[0].productId;
        const referenceId = lodashGet(preferredPartnerPlans[0], 'refId', null);
        const preferredPartnerOfferPlanId = lodashGet(preferredPartnerPlans[0], 'planId', null);
        const preferredPartners = {
            planId: preferredPartnerOfferPlanId,
            products: [
                selectedProductId,
            ],
            referenceId,
        };
        updatePreferredPartnerSelectionDispatch({ preferredPartners });
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
            source: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
            ingressIntent: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
            pageName: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type:userConfig.customerType,
            uid
        };
        unlockNowClickEvent(pageLoadAnalyticsMeta, true);
    }

    goToChannelPage = () => {
        const {
             hideModalComponentActionDispatch, contentDetails, circle, sid,history
        } = this.props;
        const cpName = lodashGet(contentDetails, 'cpId', '');
        hideModalComponentActionDispatch(
            false,
            null,
            null,
        );
        const contentUrl = windowLocation.pathname;

        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
            source: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
            pageName: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
            ingressIntent: SUBSCRIPTION_SOURCE.SINGLE_CLAIM_MODAL_SCREEN,
            service: ANALYTICS_ASSETS.AIRTELTV,
        };
        exploreOtherChannelsClickEvent(pageLoadAnalyticsMeta, true);
        history.push({
            pathname: RoutingUtil.getXmpClaim(),
            search: lodashGet(history,'location.search',''),
            state: {
                contentUrl,
                originalCpId: cpName,
            },

        });
    }

    render() {
        const { contentDetails, preferredPartnerPlans, cpDetailsById } = this.props;
        const duration = lodashGet(preferredPartnerPlans[0], 'duration', '');
        const cpName = lodashGet(contentDetails, 'cpId', '');
        const title = lodashGet(cpDetailsById[cpName], 'title', '');
        const tagLine = lodashGet(cpDetailsById[cpName], 'tagLine', '');
        const image = lodashGet(contentDetails, 'images.LANDSCAPE_169', '');
        return (
            <div className="xmp-claim-modal-wrapper">
                <div className="scroll-assist" />
                <button
                    className="xmp-claim-modal-close"
                    onClick={this.closePopUp}
                    type="button"
                >
                    <ThumborImage
                        src="/static/close.svg"
                    />
                </button>
                <div className="xmp-claim-content-wrapper">
                    <div className="xmp-claim-image-wrapper">
                        <div className="xmp-claim-content-gredient" />
                        <ThumborImage
                            className="xmp-content-claim-image"
                            src={image}
                            key={image}
                            alt=""
                            loading="lazy"
                        />
                    </div>
                    <div className="xmp-claim-text-wrapper">
                        {/* <ThumborImage
                            className="xmp-claim-logo"
                            src="/static/Jugaadistan-15-copy-a 1.svg"
                        /> */}
                        <p className="xmp-title">
                            Watch with free access to
                            {' '}
                            {characterToUpperCase(title)}
                        </p>
                        <p className="xmp-desc-text">{tagLine}</p>
                        <ul className="xmp-claim-text-tags">
                            <li className="tags">Mobile</li>
                            <li className="tags">Web</li>
                            <li className="tags">{`Valid for ${duration} Days`}</li>
                        </ul>
                    </div>
                    <div className="xmp-claim-button-wrapper">
                        <button
                            className="explore-other-channel"
                            onClick={this.goToChannelPage}
                        >
                            Explore other channels
                        </button>
                        <button
                            className="unlock-btn-xmp"
                            onClick={this.onUnlockNowClick}
                        >
                            Unlock Now
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const {
        appConfig,
        userConfig,
        planConfig,
        notify,
        popupConfig,
    } = state;
    const redirectUrl = lodashGet(planConfig, 'redirectUrl', '');
    const isFetchingDone = lodashGet(notify, 'isFetchingDone', null);
    const cpDetailsById = lodashGet(appConfig, 'cpDetailsById', {});
    const preferredPartnerPlans = lodashGet(userConfig, 'preferredPartnerPlans', []);
    const planStartingPrice = lodashGet(appConfig, 'subscriptionData.planStartingPrice', {});
    const popups = lodashGet(popupConfig, 'allPopUps', null);
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const uid = lodashGet(userConfig, 'userInfo.uid', '');
    return {
        planStartingPrice,
        userConfig,
        isFetchingDone,
        redirectUrl,
        popups,
        preferredPartnerPlans,
        cpDetailsById,
        circle,
        sid,
        uid
    };
}
export default connect(mapStateToProps, {
    updatePreferredPartnerSelectionDispatch: updatePreferredPartnerSelections,
    hideModalComponentActionDispatch: hideModalComponentAction,
    showModalComponentActionDispatch: showModalComponentAction,
    getSubscriptionOrigin,
    getContentUrl,
})(withDeviceUtil(withRouter(withStyles(styles)(XmpClaimModal))));
