export const SUBSCRIPTION_CONSTANTS = {
    BANNER: 'BANNER',
    CONTENT_DETAIL_PAGE: 'CONTENT_DETAIL_PAGE',
    CHANNEL_PARTNER_PAGE: 'CHANNEL_PARTNER_PAGE',
    EXPIRY_WARNING: 'EXPIRY_WARNING',
    EXPIRED: 'EXPIRED',
};
export const PREFFERED_PARTNER_LIST = [
    'SONYLIV_VOD',
    'HOICHOI',
    'MANORAMAMAX',
    'EROSNOW',
    'LIONSGATEPLAY',
    'SONYLIV',
    'CHAUPAL',
];

export const NOT_AVAILABLE_FOR_AIRTEL = [
    'SONYLIV_VOD',
    'LIONSGATEPLAY'
]

export default {
    SUBSCRIPTION_CONSTANTS,
};
