import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import RoutingUtil from '../../../utilities/RoutingUtil';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { showRedeemXMPAction } from '../../user/UserAction';
import { getDateWithTitle, formatType } from '../../../utilities/CommonUtil';
import { getCurrentUrl, getWindowLocation } from '../../../utilities/WindowUtil';
import { claimNowClickEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import LanguageProvider from '../../../providers/LanguageProvider';

const windowLocation = getWindowLocation();
class XMPClaimsComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.LANGUAGE = LanguageProvider();
    }

   
    getDays(preferredPartnerPlans) {
        const lowestPlan = preferredPartnerPlans.reduce((prev, curr) => (prev.duration < curr.duration ? prev : curr));
        const date = new Date();
        date.setDate(date.getDate() + lowestPlan.duration);
        return getDateWithTitle(date, formatType.SHORT);
    }

    openClaimComponent = (_) => {
        const { sid, circle,userConfig,uid,history } = this.props;
        const contentUrl = windowLocation.pathname;

        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: ANALYTICS_ASSETS.MY_PLANS_PAGE,
            source: ANALYTICS_ASSETS.MY_PLANS_PAGE,
            ingressIntent: ANALYTICS_ASSETS.MY_PLANS_PAGE,
            pageName: ANALYTICS_ASSETS.MY_PLANS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type:userConfig.customerType,
            uid
        };
        claimNowClickEvent(pageLoadAnalyticsMeta, true);
        history.push({
            pathname: RoutingUtil.getXmpClaim(),
            search: lodashGet(history,'location.search',''),
            state: {
                contentUrl,
            },

        });
    }

    

    render() {
        const { preferredPartnerPlans } = this.props;
        const time = this.getDays(preferredPartnerPlans);
        const {XMPCLAIM : {BANNER_CLAIM_BUTTON_LABEL = '', BANNER_CLAIM_BUTTON_TEXT = '', BANNER_CLAIM_NOW_TITILE = '', BANNER_DESCRIPTION = '', BANNER_TITLE = ''}, XMPCLAIM} = this.LANGUAGE;
        return (
            <div className="claim-now-wrapper">
                <p className="claim-now-title">{BANNER_CLAIM_NOW_TITILE}</p>
                <div className="claim-now-card">
                    <div className="d-flex">
                        <div className="left">
                            <p className="title">
                                {this.props.preferredPartnerPlans.length}
                                {' '}
                                {BANNER_TITLE}
                            </p>
                            <p className="description">{BANNER_DESCRIPTION}</p>
                        </div>
                        <div className="right">
                            <div className="star">
                                <ThumborImage
                                    className="gift-box"
                                    src="/static/modalpopup_asset/gift-box-close.svg"
                                />
                            </div>

                        </div>
                    </div>

                    <div className="bottom">
                        <button
                            className="claim-btn"
                            onClick={this.openClaimComponent}
                        >
                            {BANNER_CLAIM_BUTTON_LABEL}
                            {/* <ThumborImage
                                src="/static/chev-pink.svg"
                            /> */}
                        </button>
                        <p className="claim-btn-text-side">
                            {BANNER_CLAIM_BUTTON_TEXT}
                            {' '}
                            {time}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        userConfig,
        planConfig,
    } = state;
    const preferredPartnerPlans = lodashGet(userConfig, 'preferredPartnerPlans', {});
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const uid = lodashGet(userConfig, 'userInfo.uid', '');
    return {
        preferredPartnerPlans,
        sid,
        circle,
        userConfig,
        uid
    };
};

export default connect(mapStateToProps, {
    showRedeemXMPAction,
})(XMPClaimsComponent);
