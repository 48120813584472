import React, { PureComponent } from 'react';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Lottie from 'lottie-react';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import withStyles from 'isomorphic-style-loader/withStyles';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { pageLoadEvent } from '@airtel-tv/analytics/FunctionalEvents';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { LOCAL_STORE_KEYS, ARIA_LABEL, MODAL_POPUP } from '@airtel-tv/constants/GlobalConst';
import { AnalyticsButtonComponent, AnalyticsLinkComponent } from '@airtel-tv/analytics';
import { LOGIN_SOURCE } from '@airtel-tv/constants';
import { getLottieFiles } from '@airtel-tv/utils/GlobalUtil';
import styles from './SubscriptionContainer.scss';
import XStreamPremiumComponent from '../component/XStreamPremiumComponent';
// import WatchWithAirtelNumber from '../component/WatchWithAirtelNumber';
import AlaCartePartnerListing from '../component/AlaCartePartnerListing';
import XMPClaimsComponent from '../component/XMPClaimsComponent';
import RoutingUtil from '../../../utilities/RoutingUtil';
import NoPlansAndOffers from '../component/NoPlansAndOffers';
import { getCurrentUrl } from '../../../utilities/WindowUtil';
import { ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { removeChannelPartnerId } from '../../channel-partner/ChannelPartnerActions';
import { planConfigInit } from '../../plan/PlanActions';
import SubscriptionLoaderComponent from '../../shared/components/loader/SubscriptionLoaderComponent';
import LazyIconFatory, { ICON_MAP } from '../component/iconBuilder/iconFactory';
import RechargePlansWrapper from '../component/RechargePlans/RechargePlansWrapper';
import { triggerLogin } from '../../auth/AuthAction';
import { showModalComponentAction } from '../../modal-popup/ModalPopupActions';
import { ACTION_TO_NOTIFYID, NOTIFY_ID_MODAL_PAYLOAD } from '../../error-handler/ErrorCodes';

class PlansAndOffersContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            animationData: null,
        };
    }

    getXStreamDetail = (_) => {
        const browsePlans = this.props.allPlans && this.props.allPlans.browsePlans ? this.props.allPlans.browsePlans : [];
        return browsePlans.find(plans => plans.meta.offerType === 'PREMIUM');
    };

    pageinitEvent() {
        const {
            circle, sid, userConfig, uid,
        } = this.props;
        const pageLoadAnalyticsMeta = {
            pageUrl: getCurrentUrl(),
            appName: ANALYTICS_ASSETS.PARTNER_CHANNEL,
            circle,
            sid,
            active_tab: ANALYTICS_ASSETS.EXPLORE_PLANS_PAGE,
            source: ANALYTICS_ASSETS.EXPLORE_PLANS_PAGE,
            ingressIntent: ANALYTICS_ASSETS.EXPLORE_PLANS_PAGE,
            pageName: ANALYTICS_ASSETS.EXPLORE_PLANS_PAGE,
            service: ANALYTICS_ASSETS.AIRTELTV,
            customer_type: userConfig.customerType,
            uid,
        };
        pageLoadEvent(pageLoadAnalyticsMeta, true);
    }

    componentDidMount() {
        const {
            isAuthenticated, history, removeChannelPartnerId, planInitDispatch, triggerLoginDispatch, xppClaimLottiePath,
        } = this.props;
        if (xppClaimLottiePath) {
            const lottieData = getLottieFiles(xppClaimLottiePath);
            lottieData.then((res) => {
                this.setState({
                    animationData: res,
                });
            });
        }
        const appId = browserStore.get(LOCAL_STORE_KEYS.APP_ID);
        if (!isAuthenticated) {
            // history.push({
            //     pathname: RoutingUtil.getHomePage(),
            //     search: lodashGet(history, 'location.search', ''),
            // });
            triggerLoginDispatch({
                loginTriggered: true,
                sourceOfLoginTrigger: LOGIN_SOURCE.PLANS_AND_OFFER_PAGE,
            });
        }
        else {
            if (appId !== 'MOBILITY') {
                planInitDispatch();
            }
            removeChannelPartnerId();
            this.pageinitEvent();
        }
    }

    componentDidUpdate(prevProps) {
        const { isAuthenticated, triggerLoginDispatch, history } = this.props;
        if (prevProps.isAuthenticated !== isAuthenticated && !isAuthenticated) {
            history.replace({
                pathname: RoutingUtil.getHomePage(),
                search: lodashGet(history, 'location.search', ''),
            });
        }
    }

    goBack = () => {
        const { history } = this.props;
        history.go(-1);
    };

    getXPPComponent = (xStreamPremuimPlan, xstreamPremiumData) => (
        xStreamPremuimPlan
            && (
                <XStreamPremiumComponent
                    xStreamPremuimPlan={xStreamPremuimPlan}
                    xstreamPremiumData={xstreamPremiumData}
                />
            ));

    // watchWithAirtel = (allPlans, userConfig) => (
    //     allPlans && allPlans.browsePlans && allPlans.browsePlans.length > 0
    //         && userConfig.customerType !== 'POSTPAID'
    //         && (
    //             <WatchWithAirtelNumber
    //                 browsePlans={allPlans.browsePlans}
    //                 redirectUrl={userConfig.xclusivePlanURL}
    //             />
    //         ))

    alaCarteListing = (allPlans) => {
        const browsePlans = allPlans && allPlans.browsePlans;
        return (browsePlans && browsePlans.length > 0 && browsePlans.find(browsePlan => browsePlan.meta.offerType === 'ALACARTE')
            && <AlaCartePartnerListing />);
    };


    getAirtelUserComponent = (xStreamPremuimPlan, xstreamPremiumData, allPlans, userConfig) => (
        <>
            {
                this.getXPPComponent(xStreamPremuimPlan, xstreamPremiumData)
            }

            <RechargePlansWrapper type="PLANS_LISTING" />

            {
                this.alaCarteListing(allPlans)
            }
        </>
    );

    getNonAirtelUserComponent = (xStreamPremuimPlan, xstreamPremiumData, allPlans, userConfig) => (
        <>
            {
                this.alaCarteListing(allPlans)
            }
            {
                this.getXPPComponent(xStreamPremuimPlan, xstreamPremiumData)
            }

            {
                // this.watchWithAirtel(allPlans, userConfig)
            }
        </>
    );

    showXppClaimPopup = (e) => {
        e?.preventDefault();
        const { uid, showModalComponentActionDispatch, userConfig: { xppClaimDto = {} } } = this.props;
        const { notifyId = '' } = xppClaimDto;
        const payload = NOTIFY_ID_MODAL_PAYLOAD[notifyId] || '';
        if (payload) {
            showModalComponentActionDispatch({
                showModalValue: true,
                componentNameValue: ACTION_TO_NOTIFYID[notifyId],
                overrideCrossButtonValue: false,
                payload: {
                    ...payload,
                    popupVisibleMeta: {
                        asset_name: 'xpp_claim',
                        popup_id: notifyId,
                    },
                    closeClickedMeta: {
                        asset_name: 'Cross Button',
                        popup_id: notifyId,
                    },
                    backGroundCustomClass: '',
                    // popUpcustomClass: 'xpp-claim-modal',
                    uid,
                    showLottie: false,
                    closeBtnClickedHandler: () => {},
                },

            });
        }
    };

    render() {
        const {
            userConfig, allPlans, xstreamPremiumData, history, location, isAuthenticated, xppClaimLottiePath,
        } = this.props;
        const { animationData } = this.state;

        if (!isAuthenticated) {
            return <></>;
        }

        if (!allPlans || allPlans && Object.keys(allPlans).length < 1) {
            return <SubscriptionLoaderComponent />;
        }

        const xStreamPremuimPlan = this.getXStreamDetail();


        if (userConfig.preferredPartnerPlans && userConfig.preferredPartnerPlans.length === 0
           && !xStreamPremuimPlan && allPlans && allPlans.browsePlans && allPlans.browsePlans.length === 0) {
            if (location.pathname === ROUTE_PATHS.PLANS_AND_OFFERS) {
                return <NoPlansAndOffers />;
            }
            return null;
        }
        const { GO_TO_PREV_PAGE = 'Go to previous Page' } = ARIA_LABEL;
        return (
            <section className="plans-and-offer-wrapper">
                <AnalyticsButtonComponent
                    disabled={location.pathname === ROUTE_PATHS.MY_PLANS}
                    className={location.pathname !== ROUTE_PATHS.MY_PLANS ? 'no-subscribe-back' : 'no-subscribe-back-button'}
                    onClick={this.goBack}
                    aria-label={GO_TO_PREV_PAGE}
                >
                    {
                        location.pathname !== ROUTE_PATHS.MY_PLANS
                    && (
                        <LazyIconFatory iconName={ICON_MAP.CARET_DOWN} />
                    )
                    }
                </AnalyticsButtonComponent>
                <h1 className="page-title">Plans &amp; Offers</h1>
                {userConfig?.xppClaimOnThanks && animationData
                && (
                    <div className="xpp-claim">
                        <AnalyticsLinkComponent
                            className="claim-cta"
                            to="#"
                            onClick={this.showXppClaimPopup}
                            meta={{
                                action: 'my-plans-xpp-claim-ingress',
                                source_name: 'plans_and_offers_page',

                            }}
                        >
                            <Lottie
                                animationData={animationData}
                                play
                                loop
                            />
                        </AnalyticsLinkComponent>
                    </div>
                )
                }
                {
                    userConfig.preferredPartnerPlans && userConfig.preferredPartnerPlans.length > 0
                    && (
                        <XMPClaimsComponent
                            history={history}
                            preferredPartnerPlans={userConfig.preferredPartnerPlans}
                        />
                    )
                }
                {
                    userConfig.customerType !== 'NON_AIRTEL'
                    && this.getAirtelUserComponent(xStreamPremuimPlan, xstreamPremiumData, allPlans, userConfig)
                }

                {
                    userConfig.customerType === 'NON_AIRTEL'
                    && this.getNonAirtelUserComponent(xStreamPremuimPlan, xstreamPremiumData, allPlans, userConfig)
                }


            </section>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { match } = props;
    const {
        planConfig,
        userConfig,
        authConfig: { isAuthenticated },
        appConfig: { xppClaimLottiePath = 'https://image.airtel.tv/grandslam/content/2024_2_8/xpp_claim_latest.json' },
    } = state;
    const combinedPlansData = lodashGet(planConfig, 'data', {});
    const xstreamPremiumData = lodashGet(planConfig, 'subscriptionData.xstreamPremium', {});
    const sid = lodashGet(planConfig, 'sid', '');
    const circle = lodashGet(planConfig, 'userDetails.circle', '');
    const uid = lodashGet(userConfig, 'userInfo.uid', '');

    return {
        combinedPlansData,
        userConfig,
        allPlans: planConfig,
        isAuthenticated,
        xstreamPremiumData,
        sid,
        circle,
        uid,
        xppClaimLottiePath,
    };
};
PlansAndOffersContainer.propTypes = {
    channelpartner: PropTypes.string,
    combinedPlansData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-type
    xstreamPremiumData: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, {
    removeChannelPartnerId,
    triggerLoginDispatch: triggerLogin,
    planInitDispatch: planConfigInit,
    showModalComponentActionDispatch: showModalComponentAction,
})(withDeviceUtil(withRouter(withStyles(styles)(PlansAndOffersContainer))));